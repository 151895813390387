import { addDays } from 'date-fns';
import { BaseService } from './BaseService';
import { ConfigApi } from '../config/Constantes';
import api from './api';

class PedidoService extends BaseService {
  constructor() {
    super(`${ConfigApi.pedidoURL}`);
    this.filter = new Filter();
  }

  getFilter() {
    return this.filter;
  }

  setFilter(filter) {
    this.filter = filter;
  }

  // override
  async findById(id, resumido = false) {
    const rotaResumido = resumido ? '/resumido' : '';
    this.setLoading(true);
    try {
      const response = await api.get(`${this.url}/${id}${rotaResumido}`);
      return response.data;
    } finally {
      this.setLoading(false);
    }
  }

  async findByUuId(uuId) {
    this.setLoading(true);
    try {
      const urlSend = `${this.url}/uuid/${uuId}`;
      const response = await api.get(urlSend, this.getHeaderAuth(urlSend));
      return response.data;
    } finally {
      this.setLoading(false);
    }
  }

  async updateSituacao(id, situacao) {
    const pedidoSituacao = { situacao };
    try {
      const response = await api.put(`${this.url}/alteraSituacao/${id}`, pedidoSituacao);
      return response.data;
    } finally {
      this.setLoading(false);
    }
  }
}

class Filter {
  constructor() {
    this.nomeCliente = '';
    this.id = null;
    this.idFormaPagamento = null;
    this.dataInicio = addDays(new Date(), -1);
    this.dataFim = new Date();
    // A-Aguardando, P - Preparando, E-Entregando, F-Finalizado, C-Cancelado
    this.listaSituacao = ['A', 'P', 'E', 'F', 'C'];
    this.sincronizado = null; // S-Sim, N-Não
    this.limit = 9;
    this.page = 0;
  }
}
export default new PedidoService();
