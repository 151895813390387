/**
 * Componente customizado baseado no primereact/multiselect
 * @module MultiSelectSp
 * @category Componentes
 */

import React from 'react';

import PropTypes from 'prop-types';
import { MultiSelect } from 'primereact/multiselect';

/**
 * @typedef MultiSelectSpProps
 * @property {boolean} required Indica se o campo é obrigatório
 * @property {string} className Classe CSS
 * @property {boolean} disabled Ativa ou desativa o campo
 */

/**
 * Componente MultiSelectSp
 * @func MultiSelectSp
 * @param {MultiSelectSpProps} props Propriedades
 */
export default function MultiSelectSp(props) {
  MultiSelectSp.propTypes = {
    required: PropTypes.bool,
    className: PropTypes.string,
    disabled: PropTypes.bool,
  };

  MultiSelectSp.defaultProps = {
    required: false,
    className: '',
    disabled: false,
  };

  const { required, className, disabled, ...rest } = props;

  const classNames = required && !disabled ? `p-error ${className || ''}` : className || '';
  return <MultiSelect {...rest} disabled={disabled} className={classNames} />;
}
