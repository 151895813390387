/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable no-script-url */
/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Nav, MenuSideBar, MenuBar, Container } from './styles';
import { capitalizeStr, goToMarcador } from '../../../../util/functions';

function NavBar(props) {
  NavBar.propTypes = {
    menu: PropTypes.arrayOf(PropTypes.object),
    logo: PropTypes.string,
    handleSearch: PropTypes.func,
    title: PropTypes.string,
    handleMeusPedidosClick: PropTypes.func,
  };

  NavBar.defaultProps = {
    menu: [],
  };

  const [showMenu, setShowMenu] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [filterTimeOut, setFilterTimeOut] = useState();

  const { menu, logo, handleSearch, handleMeusPedidos, title, ...rest } = props;

  function textSearchChange(e) {
    const val = e.target.value;
    setSearchText(val);
    if (handleSearch) {
      if (filterTimeOut) {
        clearTimeout(filterTimeOut);
      }
      const func = setTimeout(async () => {
        handleSearch(val);
      }, 800);
      setFilterTimeOut(func);
    }
  }

  return (
    <>
      <Container {...rest}>
        <Nav className="paddign-global">
          <div>
            <a className="logo" href="http://www.speedysistemas.com.br" target="_blank">
              {logo ? <img src={logo} alt="logo" /> : null}
            </a>
          </div>
          <div className="titulo">
            {title ? <span style={{ margin: 'auto' }}>{title}</span> : null}
          </div>

          <div className="nav-bar-rigth">
            <div className="div-input-busca">
              {handleSearch && (
                <>
                  <input
                    placeholder="Buscar.."
                    type="search"
                    value={searchText}
                    onChange={e => {
                      textSearchChange(e);
                    }}
                  />

                  <button
                    title="Buscar"
                    type="button"
                    onClick={() => {
                      if (handleSearch) {
                        handleSearch(searchText);
                      }
                    }}
                  >
                    <i className="pi pi-search" />
                  </button>
                </>
              )}
              {handleMeusPedidos && (
                <button
                  className="botao-pedidos"
                  title="Meus Pedidos"
                  type="button"
                  onClick={() => {
                    if (handleMeusPedidos) {
                      handleMeusPedidos();
                    }
                  }}
                >
                  <i className="pi pi-clone" />
                </button>
              )}
            </div>

            {menu ? (
              <a onClick={() => setShowMenu(!showMenu)}>
                <i className="pi pi-bars botao-right" />
              </a>
            ) : null}
          </div>
        </Nav>
      </Container>

      <MenuBar className="paddign-global">
        <ul className="menu">{renderItem()}</ul>
      </MenuBar>

      <MenuSideBar
        visible={showMenu}
        showCloseIcon={false}
        blockScroll
        onHide={() => setShowMenu(!showMenu)}
        baseZIndex={1000}
        style={{
          padding: 0,
          overflowX: 'hidden',
          overflowY: 'auto',
          background: '#f0f0f5',
        }}
      >
        {handleMeusPedidos && (
          <div className="menu-item-pedidos">
            <a
              title="Meus Pedidos"
              onClick={() => {
                if (handleMeusPedidos) {
                  handleMeusPedidos();
                }
                setShowMenu(false);
              }}
            >
              <span>Meus Pedidos</span>
            </a>
          </div>
        )}

        <span className="titulo-menu" onClick={() => setShowMenu(false)}>
          Categorias
        </span>
        <ul className="menu">
          {renderItem(() => {
            setShowMenu(false);
          })}
        </ul>

        {/* <PanelMenu
        className="panelMenu"
        model={menuItems}
        multiple={false}
        style={{ width: '15em', padding: 2, paddingTop: 45, zIndex: 10000 }}
      /> */}
      </MenuSideBar>
    </>
  );

  function renderItem(handleClick = null) {
    if (menu) {
      const menuLocal = [];
      menu.forEach(e => {
        menuLocal.push(
          <li
            className="menu-item"
            key={e.id}
            onClick={() => {
              if (handleClick) {
                handleClick();
              }
            }}
          >
            <a title={capitalizeStr(e.nomeCompleto)} onClick={() => goToMarcador(e.marcador)}>
              <span>{capitalizeStr(e.nome)}</span>
            </a>
          </li>
        );
      });
      return menuLocal;
    }
    return null;
  }

  // function goToMarcador(marcador) {
  //   const elmnt = document.getElementById(marcador);
  //   if (elmnt) {
  //     elmnt.scrollIntoView();
  //   }
  // }
}

export default NavBar;
