/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import PropTypes from 'prop-types';

import { toast } from 'react-toastify';
import { useTheme } from 'styled-components';
import { Container } from './styles';
import DialogCardapio from '../../../../components/DialogCardapio';
import iconAvancar from '../../../../assets/cardapio/icons/categoria-fechada.png';

import iconFinalizar from '../../../../assets/cardapio/icons/sacola-finalizar-pedido.png';
import Button from '../Button';
import Input from '../Input';

import Label from '../Label';

import {
  calcDistanciaCoord,
  cloneObj,
  formatCelular,
  formatFloat,
  isMobile,
  isNumber,
  setFocusElement,
} from '../../../../util/functions';
import InputMask from '../InputMask';
import Dropdown from '../DropDown';
import InputTextArea from '../InputTextArea';
import { PedidoEntregaModel } from '../../../../util/Models';
import MapsService from '../../../../services/MapsService';
import Mapa from '../../../../components/Mapa';
import EnderecoService from '../../../../services/EnderecoService';
import { showMessage } from '../../../../components/MessageDialog';
import MunicipioService from '../../../../services/MunicipioService';
import { ListaTiposServicos, listaUfs } from '../../../../config/Constantes';

import DropDownLazy from '../DropDownLazy';
import InputCurrency from '../InputCurrency';

function Checkout(props) {
  Checkout.propTypes = {
    visible: PropTypes.bool,
    setVisible: PropTypes.func,
    pedido: PropTypes.object,
    setPedido: PropTypes.func,
    enviarPedido: PropTypes.func,
    empresaLogada: PropTypes.object,
    atualizaValoresPedido: PropTypes.func,
    listaFormasPagamento: PropTypes.array,
    tipoCardapio: PropTypes.string,
    numeroMesa: PropTypes.number,
    informarMesa: PropTypes.bool,
  };

  const {
    visible,
    setVisible,
    pedido,
    setPedido,
    empresaLogada,
    listaFormasPagamento,
    enviarPedido,
    numeroMesa,
    atualizaValoresPedido,
    tipoCardapio,
    informarMesa,
  } = props;

  const theme = useTheme();

  const [checkoutLevel, setCheckoutLevel] = useState(0); // 0-Entrega, 1-Pagamento, 2-Confirmacao
  const [numeroMesaLocal, setNumeroMesaLocal] = useState(numeroMesa);

  const [showConfirmeEndereco, setShowConfirmeEndereco] = useState(false);
  const [pedidoEntregaSelecionado, setPedidoEntregaSelecionado] = useState(
    new PedidoEntregaModel()
  );

  const [encontrouNoMapa, setEncontrouNoMapa] = useState(true);
  const [alturaMapa, setAlturaMapa] = useState(290);
  const [markerSelecionada, setMarkerSelecionada] = useState({
    latitude: 0,
    longitude: 0,
  });

  const [markerDefault, setMarkerDefault] = useState({
    latitude: 0,
    longitude: 0,
  });
  const [mapChangeTimeout, setMapChangeTimeout] = useState();
  const [avisoUtilizacaoMaiorTaxa, setAvisoUtilizacaoMaiorTaxa] = useState(false);
  const [municipioSelecionado, setMunicipioSelecionado] = useState();

  const nomeFormaPagamento = useMemo(() => {
    const { idFormaPagamento } = pedido;
    if (idFormaPagamento) {
      const forma = listaFormasPagamento.find(f => f.value === idFormaPagamento);
      if (forma) {
        return forma.label;
      }
      return '';
    }
  }, [listaFormasPagamento, pedido]);

  let listaTiposServicos = ListaTiposServicos;

  try {
    if (empresaLogada.tiposServico && Array.isArray(JSON.parse(empresaLogada.tiposServico))) {
      listaTiposServicos = JSON.parse(empresaLogada.tiposServico);
    }

    // permite apenas consumo no local
    if (Array.isArray(listaTiposServicos)) {
      const idxRetirada = listaTiposServicos.findIndex(e => e.value === 1);
      if (tipoCardapio === '1' || numeroMesaLocal) {
        if (idxRetirada >= 0) {
          listaTiposServicos.splice(idxRetirada, 1);
        }

        const idDelivery = listaTiposServicos.findIndex(e => e.value === 0);
        if (idDelivery >= 0) {
          listaTiposServicos.splice(idDelivery, 1);
        }
      } else if (tipoCardapio === '2') {
        const idConsumoLocal = listaTiposServicos.findIndex(e => e.value === 2);
        if (idConsumoLocal >= 0) {
          listaTiposServicos.splice(idConsumoLocal, 1);
        }
      }
    }
  } catch (error) {
    //
  }

  // functions
  const calculaTaxaEntrega = useCallback(
    pedidoEntrega => {
      let taxa = empresaLogada.valorTaxaEntrega || 0;

      if (
        empresaLogada.latitude !== 0 &&
        empresaLogada.longitude !== 0 &&
        empresaLogada.listaTaxas &&
        empresaLogada.listaTaxas.length > 0 &&
        pedidoEntrega &&
        pedidoEntrega.latitude !== 0 &&
        pedidoEntrega.longitude !== 0
      ) {
        const dist =
          calcDistanciaCoord(
            empresaLogada.latitude,
            empresaLogada.longitude,
            pedidoEntrega.latitude,
            pedidoEntrega.longitude
          ) * 1.2; // sistema adicionar 20% para compensar
        const { listaTaxas } = empresaLogada;
        // olha na ordem inversa
        for (let i = listaTaxas.length - 1; i >= 0; i--) {
          if (dist > listaTaxas[i].acimaDeKm) {
            if (listaTaxas[i].valorTaxa > 0) {
              taxa = listaTaxas[i].valorTaxa;
              break;
            }
          }
        }
        setAvisoUtilizacaoMaiorTaxa(false);
      } else if (
        // se tiver configuração de varias taxas e nao identificou o endereço corretamente sistema pegara a maior taxa.
        empresaLogada.latitude !== 0 &&
        empresaLogada.longitude !== 0 &&
        empresaLogada.listaTaxas &&
        empresaLogada.listaTaxas.length > 0
      ) {
        const { listaTaxas } = empresaLogada;
        for (let i = listaTaxas.length - 1; i >= 0; i--) {
          if (isNumber(listaTaxas[i].valorTaxa) && listaTaxas[i].valorTaxa > taxa) {
            taxa = listaTaxas[i].valorTaxa;
          }
        }
        setAvisoUtilizacaoMaiorTaxa(true);
      }

      return taxa;
    },
    [empresaLogada]
  );

  function salvaDadosEntregaStorage(pedidoEntrega) {
    if (pedidoEntrega) {
      try {
        localStorage.setItem('pedidoEntrega', JSON.stringify(pedidoEntrega));
      } catch (err) {
        //
      }
    }
  }

  const confirmaEndereco = () => {
    if (
      pedidoEntregaSelecionado.logradouro &&
      pedidoEntregaSelecionado.codigoIbge &&
      pedidoEntregaSelecionado.bairro &&
      pedidoEntregaSelecionado.numero &&
      pedidoEntregaSelecionado.cep &&
      pedidoEntregaSelecionado.complemento
    ) {
      if (encontrouNoMapa) {
        pedidoEntregaSelecionado.latitude = markerSelecionada.latitude;
        pedidoEntregaSelecionado.longitude = markerSelecionada.longitude;
      } else {
        pedidoEntregaSelecionado.latitude = 0;
        pedidoEntregaSelecionado.longitude = 0;
      }
      pedido.pedidoEntrega = pedidoEntregaSelecionado;
      const taxaEntrega = calculaTaxaEntrega(pedido.pedidoEntrega);
      if (taxaEntrega !== pedido.valorEntrega) {
        pedido.valorEntrega = taxaEntrega;
        atualizaValoresPedido(pedido);
        toast.warn('Taxa de Entrega foi atualizada', { autoClose: 3000 });
      }

      setPedido(cloneObj(pedido));

      salvaDadosEntregaStorage(pedido.pedidoEntrega);
      setShowConfirmeEndereco(false);
    } else {
      toast.warn('Endereço incompleto.', { autoClose: 2000 });
    }
  };

  // funcoes específicas da tela de inserir item no carrinho
  async function buscarCep() {
    if (pedidoEntregaSelecionado.cep) {
      try {
        const endereco = await EnderecoService.findByCep(pedidoEntregaSelecionado.cep);

        if (endereco) {
          pedidoEntregaSelecionado.logradouro = endereco.logradouro;
          pedidoEntregaSelecionado.bairro = endereco.bairro;
          pedidoEntregaSelecionado.complemento = endereco.complemento;
          pedidoEntregaSelecionado.nomeMunicipio = endereco.municipio.nome;
          pedidoEntregaSelecionado.uf = endereco.municipio.uf;
          pedidoEntregaSelecionado.codigoIbge = endereco.municipio.codigoIbge;

          pedidoEntregaSelecionado.bairro = endereco.bairro;
          setMunicipioSelecionado({
            value: endereco.municipio.codigoIbge,
            label: endereco.municipio.nome,
            uf: endereco.municipio.uf,
          });
          setPedidoEntregaSelecionado(cloneObj(pedidoEntregaSelecionado));
          textFilterChange(pedidoEntregaSelecionado);
        }
      } catch (err) {
        // errorHandle(err);
      }
    }
  }

  const textFilterChange = pedidoEntrega => {
    if (mapChangeTimeout) {
      clearTimeout(mapChangeTimeout);
    }
    const func = setTimeout(async () => {
      const pedEntrega = cloneObj(pedidoEntrega);
      await carregaLatitudeLongitude(pedEntrega);
    }, 800);
    setMapChangeTimeout(func);
  };

  async function carregaLatitudeLongitude(pedidoEntrega) {
    const { logradouro, numero, bairro, nomeMunicipio, uf } = pedidoEntrega;
    const { latitude, longitude } = await MapsService.findLocation(
      logradouro,
      numero,
      bairro,
      nomeMunicipio,
      uf
    );

    setMarkerDefault({ latitude: latitude || 0, longitude: longitude || 0 });
    setMarkerSelecionada({ latitude: latitude || 0, longitude: longitude || 0 });
  }

  function buscaDadosEntregaStorage() {
    try {
      const pedidoEntrega = localStorage.getItem('pedidoEntrega');

      const ped = JSON.parse(pedidoEntrega);
      // se não existir o campo latitude sistema vai ignorar o pedido
      if (ped.latitude === undefined || ped.longitude === undefined) {
        return null;
      }

      if (ped.retiradaDelivery === undefined) {
        // compatibilidade
        if (ped.retirarNoBalcao) {
          ped.retiradaDelivery = 1;
        } else {
          ped.retiradaDelivery = 0;
        }
      }
      return ped;
    } catch (err) {
      return null;
    }
  }

  const doConfirmaEndereco = () => {
    setMarkerSelecionada({
      latitude: pedido.pedidoEntrega.latitude || 0,
      longitude: pedido.pedidoEntrega.longitude || 0,
    });
    if (
      pedido.pedidoEntrega.latitude !== undefined &&
      pedido.pedidoEntrega.longitude !== undefined
    ) {
      setMarkerDefault({
        latitude: pedido.pedidoEntrega.latitude || 0,
        longitude: pedido.pedidoEntrega.longitude,
      });
    } else {
      carregaLatitudeLongitude(pedido.pedidoEntrega);
    }
    setPedidoEntregaSelecionado(cloneObj(pedido.pedidoEntrega));
    setShowConfirmeEndereco(true);
  };

  const handleContinuarFinalizar = async checkoutLv => {
    if (checkoutLv === 0) {
      if (pedido.pedidoEntrega.nome === '') {
        toast.warn('Informe seu nome', { autoClose: 2000 });
        setFocusElement('txtNome');
        return;
      }
      const telefone = pedido.pedidoEntrega.telefone
        ? pedido.pedidoEntrega.telefone.replace(/-|\(|\)| /gi, '')
        : '';

      if (telefone === '') {
        toast.warn('Informe seu telefone', { autoClose: 2000 });
        setFocusElement('txtTelefone');
        return;
      }

      if (pedido.pedidoEntrega.retiradaDelivery === 0) {
        const cep = pedido.pedidoEntrega.cep
          ? pedido.pedidoEntrega.cep.replace(/-|\(|\)| /gi, '')
          : '';

        if (
          !(
            pedido.pedidoEntrega.logradouro &&
            pedido.pedidoEntrega.codigoIbge &&
            pedido.pedidoEntrega.bairro &&
            pedido.pedidoEntrega.numero &&
            cep
          )
        ) {
          toast.warn('Endereço incompleto.', { autoClose: 2000 });
          return;
        }

        // if (pedido.pedidoEntrega.codigoIbge !== empresaLogada.codigoIbge) {
        //   showMessage(
        //     'Aviso',
        //     `Fazemos entregas somente no município de ${empresaLogada.nomeMunicipio}-${empresaLogada.uf}`,
        //     null,
        //     false
        //   );
        //   return;
        // }
      }

      if (
        pedido.pedidoEntrega.retiradaDelivery === 2 &&
        !numeroMesaLocal &&
        empresaLogada.consumoLocalApenasNaMesa
      ) {
        toast.warn('Para consumir no local é necessário estar em uma mesa.', {
          autoClose: 2000,
        });
        return;
      }

      setCheckoutLevel(1);
    } else if (checkoutLv === 1) {
      if (!pedido.idFormaPagamento) {
        toast.warn('Informe a forma de pagamento', { autoClose: 2000 });
        // goToMarcador('cmbFormaPagamento');
        return;
      }

      setCheckoutLevel(2);
    } else {
      enviarPedido(avisoUtilizacaoMaiorTaxa, numeroMesaLocal);
    }
  };

  // useCalbacks
  const carregaMunicipio = useCallback(async (_nome, _uf) => {
    if (_nome !== '') {
      const r = await MunicipioService.findAll({ nome: _nome, uf: _uf, limit: 100 });
      const retorno = r.items.map(e => {
        return {
          label: e.nome,
          value: e.codigoIbge,
          uf: e.uf,
        };
      });
      return retorno;
    }
    return [];
  }, []);

  useEffect(() => {
    if (isMobile()) {
      setAlturaMapa(250);
    } else {
      setAlturaMapa(330);
    }
  }, []);

  useEffect(() => {
    const { latitude, longitude } = markerDefault;
    setEncontrouNoMapa(latitude !== 0 && longitude !== 0);
  }, [markerDefault]);

  useEffect(() => {
    setTimeout(() => {
      const pedidoEntrega = buscaDadosEntregaStorage();

      if (pedidoEntrega) {
        pedido.pedidoEntrega = pedidoEntrega;
      }
      if (pedidoEntrega) {
        const lat = pedidoEntrega.latitude || 0;
        const long = pedidoEntrega.longitude || 0;

        if (lat === 0 || long === 0) {
          carregaLatitudeLongitude(pedido.pedidoEntrega);
        }
      }

      // Carregando municipio no pedido
      if (pedido && !pedido.pedidoEntrega.codigoIbge) {
        pedido.pedidoEntrega.codigoIbge = empresaLogada.codigoIbge;
        pedido.pedidoEntrega.nomeMunicipio = empresaLogada.nomeMunicipio;
        pedido.pedidoEntrega.uf = empresaLogada.uf;
      }

      if (
        pedido &&
        pedido.pedidoEntrega.codigoIbge &&
        pedido.pedidoEntrega.nomeMunicipio &&
        pedido.pedidoEntrega.uf
      ) {
        setMunicipioSelecionado({
          value: pedido.pedidoEntrega.codigoIbge,
          uf: pedido.pedidoEntrega.uf,
          label: pedido.pedidoEntrega.nomeMunicipio,
        });
      }

      pedido.valorEntrega = calculaTaxaEntrega(pedido.pedidoEntrega);
      atualizaValoresPedido(pedido);

      try {
        if (pedido && pedido.pedidoEntrega && Array.isArray(listaTiposServicos)) {
          const tpServicosEmpresa = listaTiposServicos;

          const itemServico = tpServicosEmpresa.find(
            e => e.value === pedido.pedidoEntrega.retiradaDelivery
          );

          if (!itemServico && tpServicosEmpresa.length > 0) {
            pedido.pedidoEntrega.retiradaDelivery = tpServicosEmpresa[0].value;
          }
        }
        if (!pedido.idFormaPagamento && numeroMesa) {
          pedido.idFormaPagamento = listaFormasPagamento[0].value || 0;
        }
      } catch (err) {
        //
      }

      setPedido(cloneObj(pedido));
    }, 200);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // render confirma endereco
  function renderConfirmaEndereco() {
    function renderFooter() {
      return (
        <div
          style={{
            height: 50,
            paddingTop: 5,
            flexDirection: 'row',
            justifyContent: 'flex-end',
            alignItems: 'center',
            display: 'flex',
          }}
        >
          <Button
            text="Voltar"
            style={{
              minWidth: 150,
              background: theme.colors.backgroundCardapio,
              color: theme.colors.primary,
              borderColor: theme.colors.primary,
              borderWidth: 1,
            }}
            onClick={() => setShowConfirmeEndereco(false)}
          />
          <Button
            text="Confirmar"
            style={{ minWidth: 150 }}
            onClick={() => {
              if (
                !encontrouNoMapa &&
                Array.isArray(empresaLogada.listaTaxas) &&
                empresaLogada.listaTaxas.length > 0 &&
                pedidoEntregaSelecionado.logradouro &&
                pedidoEntregaSelecionado.codigoIbge &&
                pedidoEntregaSelecionado.bairro &&
                pedidoEntregaSelecionado.numero &&
                pedidoEntregaSelecionado.cep
              ) {
                showMessage(
                  'Confirmação',
                  'Não encontramos seu endereço no mapa, assim ' +
                    'será usada a maior taxa de entrega. ' +
                    'Deseja continuar assim mesmo?',
                  idx => {
                    if (idx === 1) {
                      confirmaEndereco(true);
                      setTimeout(() => setAvisoUtilizacaoMaiorTaxa(false), 200); // já avisou, então não avisa novamente)
                    }
                  }
                );
              } else {
                confirmaEndereco();
              }
            }}
          />
        </div>
      );
    }
    return (
      <DialogCardapio
        closable
        title="Confirme seu Endereço"
        footer={renderFooter()}
        visible={showConfirmeEndereco}
        setVisible={setShowConfirmeEndereco}
        position="bottom"
        style={{
          minWidth: '350px',
          maxWidth: '600px',
          width: '95%',
        }}
        contentStyle={{ maxHeight: '95vh', overflowY: 'auto', overflowX: 'hidden' }}
        modal
      >
        <div style={{ margin: 10 }}>
          <div className="p-grid">
            <div className="p-col-8 p-sm-3 p-lg-3 p-fluid">
              <Label htmlFor="txtCep">CEP</Label>
              <InputMask
                id="txtCep"
                mask="99999-999"
                type="tel"
                value={pedidoEntregaSelecionado.cep}
                required
                onChange={e => {
                  pedidoEntregaSelecionado.cep = e.target.value;
                  setPedidoEntregaSelecionado(cloneObj(pedidoEntregaSelecionado));
                }}
                onComplete={() => {
                  buscarCep();
                }}
              />
            </div>

            <div className="p-col-4 p-sm-3 p-lg-3 p-fluid">
              <Label htmlFor="cmbUF">UF</Label>
              <Dropdown
                id="cmbUF"
                value={pedidoEntregaSelecionado.uf}
                options={listaUfs}
                filter
                showClear
                onChange={e => {
                  pedidoEntregaSelecionado.uf = e.target.value;
                  pedidoEntregaSelecionado.codigoIbge = null;
                  setMunicipioSelecionado(null);
                  setPedidoEntregaSelecionado(cloneObj(pedidoEntregaSelecionado));
                  textFilterChange(pedidoEntregaSelecionado);
                }}
              />
            </div>

            <div className="p-col-12 p-sm-6 p-lg-6 p-fluid">
              <Label htmlFor="cmbMunicipio">Município</Label>
              <DropDownLazy
                id="cmbMunicipio"
                required
                placeholder="Selecione"
                onChange={e => {
                  pedidoEntregaSelecionado.codigoIbge = e?.value;
                  setPedidoEntregaSelecionado(cloneObj(pedidoEntregaSelecionado));
                  textFilterChange(pedidoEntregaSelecionado);
                }}
                value={municipioSelecionado}
                onFilter={async txtFilter => {
                  const retorno = await carregaMunicipio(
                    txtFilter,
                    pedidoEntregaSelecionado.uf
                  );
                  return retorno;
                }}
              />
            </div>
            <div className="p-col-9 p-sm-9 p-lg-9 p-fluid">
              <Label htmlFor="txtLogradouro">Endereço</Label>
              <Input
                id="txtLogradouro"
                value={pedidoEntregaSelecionado.logradouro}
                required
                onChange={e => {
                  pedidoEntregaSelecionado.logradouro = e.target.value;
                  setPedidoEntregaSelecionado(cloneObj(pedidoEntregaSelecionado));
                  textFilterChange(pedidoEntregaSelecionado);
                }}
              />
            </div>
            <div className="p-col-3 p-sm-3 p-lg-3 p-fluid">
              <Label htmlFor="txtNumero">Número</Label>
              <Input
                id="txtNumero"
                value={pedidoEntregaSelecionado.numero}
                required
                onChange={e => {
                  pedidoEntregaSelecionado.numero = e.target.value;
                  textFilterChange(pedidoEntregaSelecionado);
                  setPedidoEntregaSelecionado(cloneObj(pedidoEntregaSelecionado));
                }}
              />
            </div>
            <div className="p-col-6 p-sm-6 p-lg-6 p-fluid">
              <Label htmlFor="txtBairro">Bairro</Label>
              <Input
                id="txtBairro"
                value={pedidoEntregaSelecionado.bairro}
                required
                onChange={e => {
                  pedidoEntregaSelecionado.bairro = e.target.value;
                  setPedidoEntregaSelecionado(cloneObj(pedidoEntregaSelecionado));
                  textFilterChange(pedidoEntregaSelecionado);
                }}
              />
            </div>

            <div className="p-col-6 p-sm-6 p-lg-6 p-fluid">
              <Label htmlFor="txtComplemento">Complemento/Referência</Label>
              <Input
                id="txtComplemento"
                value={pedidoEntregaSelecionado.complemento}
                required
                onChange={e => {
                  pedidoEntregaSelecionado.complemento = e.target.value;
                  setPedidoEntregaSelecionado(cloneObj(pedidoEntregaSelecionado));
                }}
              />
            </div>
          </div>
          <div style={{ height: alturaMapa, padding: 0, margin: -5, marginTop: 5 }}>
            {encontrouNoMapa ? (
              <Mapa
                viewport={{
                  latitude: markerSelecionada.latitude,
                  longitude: markerSelecionada.longitude,
                }}
                draggable={false}
                markerDefault={markerDefault}
                onChangeMarker={m => {
                  setMarkerSelecionada(m);
                }}
              />
            ) : (
              <div
                style={{
                  height: alturaMapa,
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <h2 style={{ textAlign: 'center', alignSelf: 'center' }}>
                  Aguardando dados para carregar o mapa.
                </h2>
              </div>
            )}
          </div>
        </div>
      </DialogCardapio>
    );
  }

  const renderEntrega = () => {
    let enderecoText = '';
    if (
      pedido.pedidoEntrega.logradouro &&
      pedido.pedidoEntrega.numero &&
      pedido.pedidoEntrega.codigoIbge &&
      pedido.pedidoEntrega.bairro &&
      pedido.pedidoEntrega.uf
    ) {
      enderecoText += `${pedido.pedidoEntrega.logradouro}, ${pedido.pedidoEntrega.numero} - ${pedido.pedidoEntrega.bairro}, ${pedido.pedidoEntrega.complemento}`;
    }

    return (
      <div className="body">
        <div className="p-grid">
          <div className="p-col-12 p-fluid">
            <Label htmlFor="txtNome">Nome*</Label>
            <Input
              id="txtNome"
              required
              value={pedido.pedidoEntrega.nome}
              onChange={e => {
                pedido.pedidoEntrega.nome = e.target.value;
                setPedido(cloneObj(pedido));
              }}
            />
          </div>
          <div className="p-col-6 p-fluid">
            <Label htmlFor="txtNome">Telefone*</Label>
            <InputMask
              id="txtTelefone"
              required
              autoComplete="random"
              mask="(99) 99999-9999"
              value={pedido.pedidoEntrega.telefone}
              onChange={e => {
                pedido.pedidoEntrega.telefone = e.target.value;
                setPedido(cloneObj(pedido));
              }}
            />
          </div>

          <div className="p-col-6 p-fluid">
            <Label htmlFor="cmbRetirada">Tipo de serviço*</Label>
            <Dropdown
              id="cmbRetirada"
              value={pedido.pedidoEntrega.retiradaDelivery}
              options={listaTiposServicos}
              appendTo={document.body}
              required
              onChange={e => {
                pedido.pedidoEntrega.retiradaDelivery = e.target.value;
                if (e.target.value !== 0) {
                  pedido.valorTrocoPara = 0;
                }
                setPedido(cloneObj(pedido));
              }}
            />
          </div>

          {pedido.pedidoEntrega.retiradaDelivery === 0 && (
            <div className="p-col-12 p-fluid">
              <Label>Endereço</Label>
              <InputTextArea
                maxLength={300}
                resize={false}
                value={enderecoText}
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  doConfirmaEndereco();
                }}
                readOnly
              />
            </div>
          )}

          {pedido.pedidoEntrega.retiradaDelivery === 2 && (
            <div className="p-col-12 p-sm-6 p-fluid">
              <Label>Número da Mesa</Label>
              <InputCurrency
                value={numeroMesa}
                disabled={!informarMesa}
                digits={0}
                onChange={(e, v) => setNumeroMesaLocal(v)}
                maxLength={3}
              />
            </div>
          )}

          <div className="p-col-12 p-fluid">
            <Label>Observação</Label>
            <InputTextArea
              maxLength={300}
              resize={false}
              value={pedido.observacao}
              onChange={e => setPedido({ ...pedido, observacao: e.target.value })}
            />
          </div>
        </div>
      </div>
    );
  };

  const renderPagamento = () => {
    return (
      <div className="body">
        {numeroMesa && pedido.idFormaPagamento !== 0 ? null : (
          <div className="p-grid">
            <div className="p-col-8 p-sm-8 p-lg-8 p-fluid">
              <Label htmlFor="cmbFormaPagamento">Forma de Pagamento</Label>
              <Dropdown
                id="cmbFormaPagamento"
                value={pedido.idFormaPagamento}
                options={listaFormasPagamento}
                appendTo={document.body}
                required
                onChange={e => {
                  pedido.idFormaPagamento = e.target.value;
                  if (e.target.value !== 1) {
                    pedido.valorTrocoPara = 0;
                  }
                  setPedido(cloneObj(pedido));
                }}
              />
            </div>

            <div className="p-col-4 p-sm-4 p-lg-4 p-fluid">
              <Label>Vr. Troco para</Label>
              <InputCurrency
                disabled={
                  pedido.idFormaPagamento !== 1 || pedido.pedidoEntrega.retiradaDelivery > 0
                }
                onlyPositive
                value={pedido.valorTrocoPara}
                onChange={(e, v) => {
                  setPedido({ ...pedido, valorTrocoPara: v });
                }}
              />
            </div>
            {pedido.pedidoEntrega.retiradaDelivery === 0 && (
              <div className="p-col-12 p-fluid">
                <h4>*Pagamento será efetuado na entrega</h4>
              </div>
            )}
          </div>
        )}

        {renderFooterConta()}
      </div>
    );
  };

  const valorEntrega =
    pedido.pedidoEntrega.retiradaDelivery === 0 ? pedido.valorEntrega || 0 : 0;

  const renderFooterConta = () => {
    return (
      <div className="footer-conta">
        <hr className="linha" />
        <div className="div-valores">
          <span className="valor">Subtotal</span>
          <span className="valor">R$ {formatFloat(pedido.valorSubtotal, 2)}</span>
        </div>
        {!numeroMesa && (
          <div className="div-valores">
            <span className="valor"> Taxa de Entrega</span>
            {valorEntrega ? (
              <span className="valor">R$ {formatFloat(valorEntrega, 2)}</span>
            ) : (
              <span className="valor">A definir</span>
            )}
          </div>
        )}
        <div className="div-valores">
          <span className="valor-total">Total</span>
          <span className="valor-total">R$ {formatFloat(pedido.valor + valorEntrega, 2)}</span>
        </div>
        <hr className="linha" />
      </div>
    );
  };

  const renderConfirmacao = () => {
    const titulo =
      pedido.pedidoEntrega.retiradaDelivery === 0
        ? 'Informações de Entrega'
        : 'Informações do cliente';

    return (
      <div className="body">
        <div className="entrega-conta">
          <span className="titulo">{titulo}</span>
          <div className="div-nome">
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <span className="pi pi-user" />
              <span className="nome">{pedido.pedidoEntrega.nome}</span>
            </div>
            <span className="telefone">{formatCelular(pedido.pedidoEntrega.telefone)}</span>
          </div>

          {pedido.pedidoEntrega.retiradaDelivery === 0 ? (
            <div className="div-endereco">
              <div>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <span className="pi pi-map-marker" />
                  <span className="endereco">
                    {pedido.pedidoEntrega.logradouro}, {pedido.pedidoEntrega.numero}
                  </span>
                </div>
                <span className="endereco-bairro-municipio">
                  {`${pedido.pedidoEntrega.bairro}, ${pedido.pedidoEntrega.nomeMunicipio}-${pedido.pedidoEntrega.uf}`}
                </span>
              </div>
              <span
                className="botao-editar pi pi-pencil"
                title="Alterar endereço"
                onClick={() => doConfirmaEndereco()}
              />
            </div>
          ) : null}

          <hr className="linha" style={{ marginTop: 10, marginBottom: 10 }} />
          <div className="itens">{renderItemsPedido()}</div>

          {renderFooterConta()}
          {!numeroMesa && (
            <div className="div-forma-pagamento">
              <div className="forma-pagamento">
                <span className="titulo">Forma de Pagamento</span>
                <span className="nome">{nomeFormaPagamento}</span>
              </div>
              <span
                className="botao-editar pi pi-pencil"
                title="Alterar forma de pagamento"
                onClick={() => setCheckoutLevel(1)}
              />
            </div>
          )}
        </div>
      </div>
    );
  };

  const renderBody = () => {
    switch (checkoutLevel) {
      case 0:
        return renderEntrega();

      case 1:
        return renderPagamento();

      case 2:
        return renderConfirmacao();

      default:
        return renderEntrega();
    }
  };

  function renderItemsPedido() {
    const produtos = pedido.pedidoDetalhe;

    function renderDetalheProduto(rowData) {
      const listaAdicionais = rowData.pedidoDetalhe;
      const respostasProd = [];
      const respostasObs = [];

      if (Array.isArray(rowData.perguntas)) {
        rowData.perguntas.forEach(p => {
          if (Array.isArray(p.respostas)) {
            p.respostas.forEach(r => {
              if (r.marcado && r.produto) {
                r.key = `${rowData.numeroItem}-${rowData.idProduto}-perg-${p.id}resp-${r.id}`;
                respostasProd.push(r);
              }
            });
          }
        });
      }

      if (Array.isArray(rowData.perguntas)) {
        rowData.perguntas.forEach(p => {
          if (Array.isArray(p.respostas)) {
            p.respostas.forEach(r => {
              if (r.marcado && r.observacao) {
                r.key = `${rowData.numeroItem}-${rowData.idProduto}-perg-${p.id}resp-${r.id}`;
                respostasObs.push(r);
              }
            });
          }
        });
      }

      return (
        <div style={{ fontSize: '12px' }}>
          {listaAdicionais
            ? listaAdicionais.map(a => {
                return (
                  <div
                    key={`add-${rowData.numeroItem}-${a.idProduto}`}
                    style={{ marginLeft: 25 }}
                  >{`+${a.quantidade}${a.unidade} x ${a.descricao}`}</div>
                );
              })
            : null}

          {respostasProd
            ? respostasProd.map(r => {
                let quantidadeDescricao = '';
                if (r.produto) {
                  quantidadeDescricao = `+${r.quantidadeResposta}${r.produto.produtoUnidade.sigla} x ${r.produto.descricao}`;
                } else {
                  quantidadeDescricao = `*${r.observacao.descricao}`;
                }

                return (
                  <div key={`${r.key}`} style={{ marginLeft: 25 }}>
                    {quantidadeDescricao}
                  </div>
                );
              })
            : null}

          {rowData.pedidoDetalheObs.map(o => {
            return (
              <div
                key={`obs-${rowData.numeroItem}-${o.idObservacaoProduto}`}
                style={{ marginLeft: 25 }}
              >{`*${o.observacaoProduto.descricao}`}</div>
            );
          })}

          {respostasObs
            ? respostasObs.map(r => {
                let quantidadeDescricao = '';
                if (r.produto) {
                  quantidadeDescricao = `+${r.quantidadeResposta}${r.produto.produtoUnidade.sigla} x ${r.produto.descricao}`;
                } else {
                  quantidadeDescricao = `*${r.observacao.descricao}`;
                }

                return (
                  <div key={`${r.key}`} style={{ marginLeft: 25 }}>
                    {quantidadeDescricao}
                  </div>
                );
              })
            : null}

          {rowData.observacao ? (
            <div style={{ marginLeft: 25 }}>{`*${rowData.observacao}`}</div>
          ) : null}
        </div>
      );
    }

    return produtos.map((prod, idx) => {
      let qtdCasasDecimais = 0;
      if (prod.quantidade === Math.round(prod.quantidade)) {
        qtdCasasDecimais = 0;
      } else {
        qtdCasasDecimais = 2;
      }

      const descricao = prod.descricao.substring(0, 30);
      return (
        <div className="div-produtos" key={idx}>
          <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
            <span className="quantidade">
              {formatFloat(prod.quantidade, qtdCasasDecimais)}x
            </span>
            <span className="descricao">{descricao}</span>
            <span className="valor">{`R$ ${formatFloat(prod.valorTotal, 2)}`}</span>
          </div>

          <div className="detalhes">{renderDetalheProduto(prod)}</div>
        </div>
      );
    });
  }

  return (
    <Container>
      {showConfirmeEndereco ? renderConfirmaEndereco() : null}
      <DialogCardapio
        closable
        setVisible={setVisible}
        visible={visible}
        title="Checkout"
        style={{
          minWidth: '350px',
          maxWidth: '600px',
          width: '95%',
        }}
        styleBody={{ paddingLeft: 0, paddingRight: 0, paddingBottom: 0 }}
      >
        <>
          <div className="head">
            <div className="div-andamento">
              <div className="item-andamento">
                <div style={{ width: '100%' }}>
                  <hr style={{ marginLeft: '50%', height: 1 }} />
                </div>
                <span className="marcacao item-marcado" style={{ marginTop: -11 }}>
                  1
                </span>
                <span>Entrega</span>
              </div>

              <div className="item-andamento">
                <div style={{ width: '100%' }}>
                  <hr style={{ height: 1 }} />
                </div>
                <span
                  className={`marcacao${checkoutLevel >= 1 ? ' item-marcado' : ''}`}
                  style={{ marginTop: -11 }}
                >
                  2
                </span>
                <span>Pagamento</span>
              </div>

              <div className="item-andamento">
                <div style={{ width: '100%' }}>
                  <hr style={{ marginRight: '50%', height: 1 }} />
                </div>
                <span
                  className={`marcacao${checkoutLevel >= 2 ? ' item-marcado' : ''}`}
                  style={{ marginTop: -11 }}
                >
                  3
                </span>
                <span>Confirmação</span>
              </div>
            </div>
          </div>
          {renderBody()}
          <div className="footer">
            <Button
              text="VOLTAR"
              iconPosition="left"
              style={{
                background: theme.colors.backgroundCardapio,
                color: theme.colors.primary,
                borderColor: theme.colors.primary,
                borderWidth: 1,
                flex: 1,
              }}
              onClick={() => {
                if (checkoutLevel === 0) {
                  setTimeout(setVisible(false), [200]);
                } else if (checkoutLevel === 1) {
                  setCheckoutLevel(0);
                } else if (checkoutLevel === 2) {
                  setCheckoutLevel(1);
                }
              }}
            />

            <Button
              text={checkoutLevel !== 2 ? 'CONTINUAR' : 'FINALIZAR'}
              style={{ flex: 1 }}
              iconPosition="rigth"
              icon={checkoutLevel !== 2 ? iconAvancar : iconFinalizar}
              onClick={() => {
                handleContinuarFinalizar(checkoutLevel);
              }}
            />
          </div>
        </>
      </DialogCardapio>
    </Container>
  );
}

export default Checkout;
