import { lighten } from 'polished';
import styled from 'styled-components';

export const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 15px;
  border-width: 1px;
  border-style: solid;
  border-color: ${({ theme }) => lighten(0.2, theme.colors.backgroundRodape)};

  padding: 2px;

  .mapouter {
    position: relative;
    text-align: right;
  }

  .gmap_canvas {
    overflow: hidden;
    background: none !important;
  }
`;
