import styled, { createGlobalStyle } from 'styled-components';
import 'react-toastify/dist/ReactToastify.css';

export default createGlobalStyle`
  @font-face {
    font-family: Poppins;
    src: url('/fonts/FontePoppins/Poppins-Regular.ttf')
  }

  @font-face {
    font-family: PoppinsBold;
    src: url('/fonts/FontePoppins/Poppins-Bold.ttf')
  }

  @font-face {
    font-family: PoppinsSemiBold;
    src: url('/fonts/FontePoppins/Poppins-SemiBold.ttf')
  }


  @import url('https://fonts.googleapis.com/css?family=Roboto:400,700&display=swap');
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  *:focus {
    outline: 0;
  }

  html, body, #root {
    height: 100%;
    scroll-behavior: smooth;
  }

  form {
    width: 100%;
  }

  body {
    --webkit-font-smoothing: antialiased;
  }

  body, input, button, label {
    font-family: 'Poppins', 'Open Sans', 'Helvetica Neue', sans-serif;
  }

  a {
    text-decoration: none;
  }

  ul {
    list-style: none;
  }

  button {
    cursor: pointer;
  }

  th {
    padding-left: 4px !important;
    padding-right: 4px !important;
  }

  td {
    padding-left: 4px !important;
    padding-right: 4px !important;
    padding-top: 3px !important;
    padding-bottom: 3px !important;

  }

  .desktop-visible {
    display: normal !important;
  }

  .mobile-visible {
    display: normal !important;
  }

  @media screen and (max-width: 640px) {
    .desktop-visible {
      display: none !important;
    }
  }

  @media screen and (min-width: 641px) {
    .mobile-visible {
      display: none !important;
    }
  }

  .container-page {
    padding: 10px !important;
    margin-left: -5px !important;
    margin-right: -5px !important;
    padding-bottom: 0px !important;
    border-style: solid;
    border: 1px solid #969595;
    border-radius: 4px;
  }

  .blink-animated {
    animation: blink-animation 2s steps(5, start) infinite;
    -webkit-animation: blink-animation 2s steps(5, start) infinite;
  }
  @keyframes blink-animation {
    to {
      visibility: hidden;
    }
  }
  @-webkit-keyframes blink-animation {
    to {
      visibility: hidden;
    }
  }

  .grid-col-nowrap {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .text-nowrap {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .botao-pequeno {
    width: 26px !important;
    height: 26px !important;
  }

  .botao-pequeno-largo {
    width: 40px !important;
    height: 26px !important;
  }

  .label-button {
    display: block;
  }

  .paddign-global {
    padding-left: 5em;
    padding-right: 5em;

    @media screen and (max-width: 50em) {
      padding-left: 5px;
      padding-right: 5px;

    }
  }

  /* Show priority 3 at 640px (40em x 16px) */
  @media (max-width: 40em) {
    .label-button {
      visibility: hidden;
      height: 0px;
    }
  }

  /* formatacao para colunas da grid */
  .grid-col-situacao {
    width: 150px;
    text-align: left;
    font-size: 13px;
  }
  .grid-col-id {
    width: 68px;
    text-align: left;
    font-size: 13px;
  }

  .grid-col-data-hora {
    width: 120px;
    text-align: left;
    font-size: 13px;
  }

  .grid-col-id-g {
    width: 90px;
    text-align: left;
    font-size: 13px;

  }

  .unresize {
    resize: none;
  }

  .grid-col-curr {
    width: 120px;
    text-align: end;
    font-size: 13px;
  }

  .grid-col-qtd-int {
    width: 55px;
    text-align: center;
    font-size: 13px;
  }

  .grid-col-val {
    width: 90px;
    text-align: end;
    font-size: 13px;
  }

  .grid-col-val-p {
    width: 60px;
    text-align: end;
    font-size: 13px;
  }

  .grid-col-status {
    width: 30px;
    text-align: end;
    font-size: 13px;
  }

  .grid-col-data {
    width: 90px;
    text-align: start;
    font-size: 13px;
  }

  .grid-col-hora {
    width: 50px;
    text-align: start;
    font-size: 13px;
  }

  .grid-col-tel {
    width: 120px;
    font-size: 13px;
  }

  .grid-col {
    font-size: 13px;
  }

  .gid-col-acoes-35 {
    width: 35px;
    text-align: left;
    font-size: 13px;
  }

  /*configurações do block-ui*/

  .loading-indicator {
    text-align: center;
  }
  .block-ui-overlay {
  animation: 1s fadein 0.5s both;
  /* wait 1s, fadein over .5s, apply the animation styles both before and after. */
  }

  @keyframes fadein {
    from {
      opacity: 0.1;
    }
    to {
      opacity: 0.5;
    }
  }
  /*fim das configurações do block-ui*/

`;

export const Label = styled.label``;

export const ContainerBase = styled.div`
  margin: 0 !important;
  padding: 0 !important;
`;
