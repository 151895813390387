/* eslint-disable import/no-dynamic-require */
/* eslint-disable global-require */
import React from 'react';
import { Container } from './styles';
import AuthService from '../../services/AuthService';
// import logoGrande from '../../assets/images/logo-speedy.png';

export default function Home() {
  const paddingTop = (window.innerHeight - 300) / 2 - 20;
  const logoSpeedy = require('../../assets/images/logo-speedy.png');
  const idContabilidade = AuthService.getUsuario().contabilidade
    ? AuthService.getUsuario().contabilidade.id
    : null;
  let logoContabilidade;
  if (idContabilidade) {
    try {
      logoContabilidade = require(`../../assets/images/logo-contabilidade${idContabilidade}.png`);
    } catch (err) {
      // fs.exists não funciona no react
    }
  }

  return (
    <Container>
      <div className="p-grid" style={{ margin: 0, padding: 0, paddingTop }}>
        <div className="p-col-12">
          <div
            style={{
              textAlign: 'center',
              justifyContent: 'center',
            }}
          >
            <img
              alt="Logo"
              src={logoContabilidade || logoSpeedy}
              style={{ maxWidth: '100%', maxHeight: '100%' }}
            />
          </div>
        </div>
      </div>
    </Container>
  );
}
