import React from 'react';
import { Button } from 'primereact/button';
import PropTypes from 'prop-types';

export default function PainelBotesCadastro(props) {
  PainelBotesCadastro.propTypes = {
    handleInserir: PropTypes.func,
    buttonBuscarDisabled: PropTypes.bool,
    buttonInserirDisabled: PropTypes.bool,
  };

  const { handleInserir, buttonBuscarDisabled, buttonInserirDisabled } = props;

  return (
    <div className="p-col-12 p-lg-12" style={{ textAlign: 'end' }}>
      <Button
        disabled={buttonBuscarDisabled}
        className="p-button-secondary buttons"
        icon="pi pi-search"
        title="Buscar"
        type="submit"
        label="Buscar"
      />
      {handleInserir ? (
        <Button
          label="Inserir"
          title="Inserir"
          icon="pi pi-file"
          type="button"
          disabled={buttonInserirDisabled}
          onClick={() => {
            if (handleInserir) {
              handleInserir();
            }
          }}
        />
      ) : null}
    </div>
  );
}
