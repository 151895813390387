import styled from 'styled-components';

export const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  z-index: 101;
  min-height: 268px;

  .div-cabecalho {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    border-width: 0;
    border-bottom-width: 1px;
    border-style: solid;
    border-color: ${({ theme }) => theme.colors.backgroundRodape};

    .botao-tab {
      font-family: ${({ theme }) => theme.fonts.regular};
      font-size: 18px;
      /* flex: 1; */

      cursor: pointer;
    }

    .border-button-tab-select {
      border-width: 0;
      border-bottom-width: 1px;
      border-style: solid;
      border-color: ${({ theme }) => theme.colors.primary};
    }
  }

  .body {
    display: flex;
    margin-top: 5px;
    font-family: ${({ theme }) => theme.fonts.regular};

    .div-logo-empresa {
      display: flex;
      flex-direction: row;
      align-items: center;

      .logo {
        /* border-radius: ${({ theme }) => theme.borderRadiusCirculo}; */
        margin-right: 10px;
        height: 80px;
        width: 80px;
        object-fit: cover;
      }

      .div-empresa{
        display: flex;
        flex-direction: column;
      }
    }


    .div-sobre {
      display: flex;
      flex-direction: column;

      .titulo {
        font-size: 16px;
        font-family: ${({ theme }) => theme.fonts.fontSubttitle};
      }

      .endereco {
        font-size: 13px;
      }

      .cnpj {
        font-size: 13px;
        font-family: ${({ theme }) => theme.fonts.fontSubttitle};
      }

      .div-contatos {
        display: flex;
        margin-top: 3px;

        button {
          font-size: 11px;
          font-family: ${({ theme }) => theme.fonts.fontSubttitle};
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: ${({ theme }) => theme.colors.backgroundCardapio};
          margin: 0;
          margin-right: 5px;
          padding: 2px 10px 2px 10px;
          border-width: 1px;
          border-radius: ${({ theme }) => theme.borderRadiusSmall};
          border-color: ${({ theme }) => theme.colors.primary};

          .icon-button {
            width: 18px;
            height: 18px;
            margin: 0;
            margin-right: 2px;
          }
        }
      }
    }
  }

  .div-horarios{
    font-size: 15px;
    font-family: ${({ theme }) => theme.fonts.regular};
    margin-top: 10px;

    .dia-semana {
      width: 100px;
    }
    .div-dia {
      display: flex;
    }
  }
`;
