import { getApiInfo, checkVersaoApi as apiCheckVersaoApi } from './api';

class ApiInfoService {
  // async apiInfo() {
  //   try {
  //     const response = await api.get(`${ConfigApi.apiInfoURL}`);
  //     return response.data;
  //   } catch (err) {
  //     return null;
  //   }
  // }
  async apiInfo() {
    return getApiInfo();
  }

  async checkVersaoApi() {
    return apiCheckVersaoApi();
  }
}

export default new ApiInfoService();
