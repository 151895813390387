export function showDialog(title, body, handleClose, yesNoButton) {
  return {
    type: '@dialog/SHOW',
    config: { visible: true, title, body, handleClose, yesNoButton },
  };
}

export function hiddenDialog() {
  return {
    type: '@dialog/HIDDEN',
    config: { visible: false, title: '', body: '', handleClose: null },
  };
}

export function showDialogLogin() {
  return {
    type: '@dialog/SHOW_LOGIN',
    config: { loginVisible: true },
  };
}

export function hiddenDialogLogin() {
  return {
    type: '@dialog/HIDDEN_LOGIN',
    config: { loginVisible: false },
  };
}

export function showDialogClienteLogin(email, senha, arrayClientes) {
  return {
    type: '@dialog/SHOW_LOGIN_CLIENTE',
    config: { loginClienteVisible: true, email, senha, arrayClientes },
  };
}

export function hiddenDialogClienteLogin() {
  return {
    type: '@dialog/HIDDEN_LOGIN_CLIENTE',
    config: { loginClienteVisible: false, email: '', senha: '', arrayClientes: [] },
  };
}
