import React from 'react';
import { Button } from 'primereact/button';
import PropTypes from 'prop-types';
import { showMessage } from '../MessageDialog';

export default function ButtonSp(props) {
  ButtonSp.propTypes = {
    onClick: PropTypes.func,
    messageConfirmation: PropTypes.string,
    showConfirmation: PropTypes.bool,
  };

  ButtonSp.defaultProps = {
    messageConfirmation: 'Confirma os dados',
    showConfirmation: false,
  };

  const { messageConfirmation, showConfirmation, onClick, ...rest } = props;

  function handleClick() {
    if (showConfirmation) {
      showMessage('Confirmação', messageConfirmation || 'Confirma os dados?', idx => {
        if (idx === 1) {
          onClick();
        }
      });
    } else {
      onClick();
    }
  }

  return (
    <Button
      {...rest}
      onClick={() => {
        if (onClick) {
          handleClick();
        }
      }}
    />
  );
}
