import { lighten } from 'polished';
import styled from 'styled-components';

export const Container = styled.div`
  padding: 2px 10px 2px 15px;

  @media screen and (max-width: 800px) {
    padding: 2px 2px 2px 5px;
  }
`;

export const ContainerTitulo = styled.div`
  background: ${({ theme }) => theme.colors.primary};
  margin: 2px 2px 2px 2px;
  padding: 2px 10px 2px 15px;
  border-radius: ${({ theme }) => theme.borderRadius};
  color: ${({ theme }) => theme.colors.backgroundCard};

  display: flex;
  justify-content: space-between;
  align-items: center;

  :hover {
    cursor: pointer;
    background: ${props => lighten(0.05, props.theme.colors.primary)};
  }

  :active {
    background: linear-gradient(
      ${props => props.theme.colors.primary},
      ${props => lighten(0.3, props.theme.colors.primary)}
    );
  }

  h1 {
    font-size: 18px;
    font-family: 'PoppinsBold';
  }
  i {
    font-size: 25px;
  }
`;

export const ContainerLista = styled.div`
  -webkit-transition: max-height 0.6s;
  -moz-transition: max-height 0.6s;
  -ms-transition: max-height 0.6s;
  -o-transition: max-height 0.6s;
  transition: max-height 0.6s;
  overflow: hidden;
`;

export const ContainerItem = styled.div`
  display: flex;
  justify-content: space-between;
  flex: 1;

  border-radius: ${({ theme }) => theme.borderRadius};
  border-width: 1px;
  border-color: ${({ theme }) => lighten(0.2, theme.colors.backgroundRodape)};
  border-style: solid;

  background: ${({ theme }) => theme.colors.backgroundCard};

  min-height: ${({ theme }) => `${theme.minHeightCardItem}px`};
  padding: 10px 10px 10px 10px;
  margin: 15px 0px 0px 0px;

  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  :hover {
    background: ${props => lighten(0.02, props.theme.colors.backgroundCardapio)};
    cursor: pointer;
  }

  :active {
    background: ${props => props.theme.colors.backgroundCardapio};
  }

  h1 {
    font-size: 16px;
    font-family: 'PoppinsBold';
    color: ${({ theme }) => theme.colors.subtitle};
  }

  h2 {
    font-size: 18px;
    font-family: 'PoppinsBold';
    color: ${({ theme }) => theme.colors.primary};
  }

  span {
    flex: 1;
    /* line-height: 1.35; */
    font-size: 12px;
    overflow: hidden;
    color: ${({ theme }) => theme.colors.description};
  }

  .div-descricao {
    display: flex;
    flex-direction: column;
  }

  .image-item {
    margin-left: 8px;
    align-self: center;
    height: ${({ theme }) => `${theme.minHeightCardItem - 10}px`};
    width: ${({ theme }) => `${theme.minHeightCardItem - 10}px`};
    object-fit: cover;
    border-radius: ${({ theme }) => theme.borderRadius};
  }

  .marcador {
    margin-top: 48px;
  }
`;
