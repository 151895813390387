import React, { useState, useCallback, useEffect, useMemo } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

import { toast } from 'react-toastify';
import { Container } from './styles';
import BarraTitulo from '../../components/BarraTitulo';
import PainelFiltro from '../../components/PainelFiltro';
import ProdutoGrupoService from '../../services/ProdutoGrupoService';
import history from '../../services/history';
import { StatusTela } from '../../util/Tipos';
import { buscaPageParams, errorHandle, validateFields, padLeft } from '../../util/functions';
import LabelSp from '../../components/LabelSp';
import { ProdutoGrupoModel } from '../../util/Models';

import { showMessage } from '../../components/MessageDialog';
import InputTextSp from '../../components/InputTextSp';

import DropdownSp from '../../components/DropdownSp';
import AuthService from '../../services/AuthService';
import PainelBotesCadastro from '../../components/PainelBotesCadastro';
import BotaoMenuGrid from '../../components/BotaoMenuGrid';
import ButtonSp from '../../components/ButtonSp';

export default function ProdutoGrupo(props) {
  // useMemo
  const pageParams = useMemo(
    () => buscaPageParams(props.match.params, props.location.search),
    [props.location.search, props.match.params]
  );
  const filterService = useMemo(() => ProdutoGrupoService.getFilter(), []);

  // useStates
  const backTo = pageParams.backTo || '/painel/produtoGrupos';
  const [filter, setFilter] = useState(filterService);
  const [produtoGrupos, setProdutoGrupos] = useState([]);
  const [pageLimit, setPageLimit] = useState(filterService.limit);
  const [first, setFirst] = useState(0);
  const [totalRecords, setTotalRecords] = useState(0);
  const [produtoGrupo, setProdutoGrupo] = useState(new ProdutoGrupoModel());

  // funcoes
  const handleBuscar = useCallback(async (_filter, _page, resetPage = true) => {
    _filter.page = _page;
    try {
      const result = await ProdutoGrupoService.findAll(_filter);
      setProdutoGrupos(result.items);
      setPageLimit(result.limit);
      setTotalRecords(result.totalRecords);
      if (resetPage) {
        setFirst(0);
      }
    } catch (err) {
      errorHandle(err);
    }
  }, []);

  const carregaRegistro = useCallback(async _id => {
    try {
      const retorno = await ProdutoGrupoService.findById(_id);
      setProdutoGrupo(retorno);
    } catch (err) {
      setProdutoGrupo(new ProdutoGrupoModel());
      errorHandle(err);
    }
  }, []);

  function onPage(event) {
    const pagina = event.first / event.rows;
    setFirst(event.first);
    handleBuscar(filter, pagina, false);
  }

  // useCallbacks
  const excluirRegistro = useCallback(
    async _id => {
      try {
        await ProdutoGrupoService.delete(_id);
        toast.success('Registro excluído com sucesso.');
        handleBuscar(filterService);
      } catch (err) {
        errorHandle(err);
      }
    },
    [filterService, handleBuscar]
  );

  const confirmaExclusao = useCallback(
    idSelecionado => {
      showMessage('Confirmação', 'Confirma a exclusão do registro?', idx => {
        if (idx === 1) {
          excluirRegistro(idSelecionado);
        }
      });
    },
    [excluirRegistro]
  );

  // functions
  function handleVoltar() {
    if (pageParams.statusTela === StatusTela.stVisualizar) {
      history.push(backTo);
      handleBuscar(filter);
    } else {
      showMessage('Confirmação', 'Abandonar mudanças?', idx => {
        if (idx === 1) {
          history.push(backTo);
          handleBuscar(filter);
        }
      });
    }
  }

  function handleSalvar() {
    if (pageParams.statusTela === StatusTela.stVisualizar) {
      history.push(backTo);
    } else {
      salvarRegistro();
    }
  }

  function modoConsulta() {
    return pageParams.statusTela === StatusTela.stVisualizar;
  }

  async function salvarRegistro() {
    try {
      if (pageParams.statusTela === StatusTela.stInserir) {
        await ProdutoGrupoService.insert(produtoGrupo);
      } else {
        await ProdutoGrupoService.update(produtoGrupo);
      }
      toast.success('Registro salvo com sucesso.');
      history.push(backTo);
      handleBuscar(filter);
    } catch (err) {
      errorHandle(err);
    }
  }
  // useEffects
  useEffect(() => {
    if (pageParams.statusTela === StatusTela.stPesquisar) {
      handleBuscar(filter, 0, 800);
    }
    // desativado para evitar que a cada vez que o usuario digitasse o sistema buscasse
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      pageParams.statusTela === StatusTela.stAlterar ||
      pageParams.statusTela === StatusTela.stVisualizar
    ) {
      carregaRegistro(pageParams.idSelecionado);
    } else if (pageParams.statusTela === StatusTela.stInserir) {
      const novo = new ProdutoGrupoModel();
      setProdutoGrupo(novo);
    }
  }, [
    carregaRegistro,
    filterService,
    handleBuscar,
    pageParams.idSelecionado,
    pageParams.statusTela,
  ]);

  // exemplo de componentWillUnmount
  // useEffect(() => {
  //   return function cleanup() {
  //
  //   };
  // }, [filter]);

  // renders
  return (
    <Container className="container-page">
      <div className="p-grid">
        <BarraTitulo title="Grupo de Produtos" />
        {pageParams.statusTela === StatusTela.stPesquisar
          ? renderPesquisa()
          : renderCadastro()}
      </div>
    </Container>
  );

  function renderPesquisa() {
    return (
      <>
        <form
          onSubmit={e => {
            e.preventDefault();
            handleBuscar(filter);
          }}
        >
          <PainelFiltro className="p-grid p-col-12" visible="true">
            <div className="p-col-12 p-sm-6 p-lg-6 p-fluid">
              <LabelSp>Nome</LabelSp>
              <InputTextSp
                value={filter.nome}
                onChange={e => {
                  setFilter({ ...filter, nome: e.target.value });
                }}
              />
            </div>

            <div className="p-col-12 p-sm-6 p-lg-6 p-fluid">
              <LabelSp>Descrição</LabelSp>
              <InputTextSp
                value={filter.descricao}
                onChange={e => {
                  setFilter({ ...filter, descricao: e.target.value });
                }}
              />
            </div>
          </PainelFiltro>
          <PainelBotesCadastro
            handleInserir={() => {
              history.push('/painel/produtoGrupos/inserir');
            }}
            buttonInserirDisabled={!AuthService.checkRoles('PRODUTO_GRUPO_INSERIR')}
          />
        </form>
        <div className="p-col-12 p-fluid">
          <DataTable
            value={produtoGrupos}
            style={{ marginBottom: '2px' }}
            paginator
            rows={pageLimit}
            lazy
            responsive
            totalRecords={totalRecords}
            first={first}
            onPage={onPage}
          >
            <Column
              field="id"
              body={rowData => padLeft(rowData.id, 6)}
              header="Id"
              className="grid-col-id"
            />
            <Column field="nome" className="grid-col" header="Nome" />
            <Column field="descricao" className="grid-col" header="Descrição" />
            <Column
              field="mostrarCardapio"
              className="grid-col-data"
              header="Cardápio"
              bodyClassName="alinha-center-desktop"
              body={rowData => (rowData.mostrarCardapio ? 'Exibir' : 'Não Exibir')}
            />
            <Column
              className="gid-col-acoes-35"
              bodyStyle={{ textAlign: 'end' }}
              body={renderButtonOp}
            />
          </DataTable>
        </div>
      </>
    );
  }

  function renderButtonOp(rowData) {
    return (
      <BotaoMenuGrid
        handles={[
          () => history.push(`/painel/produtoGrupos/${rowData.id}?visualizar`),
          () => history.push(`/painel/produtoGrupos/${rowData.id}`),
          () => confirmaExclusao(rowData.id),
        ]}
        disableds={[
          false,
          !AuthService.checkRoles('PRODUTO_GRUPO_ALTERAR'),
          !AuthService.checkRoles('PRODUTO_GRUPO_EXCLUIR'),
        ]}
      />
    );
  }

  function renderCadastro() {
    return (
      <>
        <div className="p-col-12 p-sm-4 p-lg-4 p-fluid">
          <LabelSp>Nome</LabelSp>
          <InputTextSp
            value={produtoGrupo.nome}
            maxLength={100}
            required
            disabled={modoConsulta()}
            onChange={e => {
              setProdutoGrupo({ ...produtoGrupo, nome: e.target.value });
            }}
          />
        </div>

        <div className="p-col-12 p-sm-5 p-lg-5 p-fluid">
          <LabelSp>Descrição</LabelSp>
          <InputTextSp
            value={produtoGrupo.descricao}
            maxLength={100}
            required
            disabled={modoConsulta()}
            onChange={e => {
              setProdutoGrupo({ ...produtoGrupo, descricao: e.target.value });
            }}
          />
        </div>

        <div className="p-col-12 p-sm-3 p-lg-3 p-fluid">
          <LabelSp>Exibir no Cardápio</LabelSp>
          <DropdownSp
            value={produtoGrupo.mostrarCardapio}
            options={[
              { value: true, label: 'Sim' },
              { value: false, label: 'Não' },
            ]}
            disabled={modoConsulta()}
            filterInputAutoFocus={false}
            onChange={e =>
              setProdutoGrupo({ ...produtoGrupo, mostrarCardapio: e.target.value })
            }
          />
        </div>

        <div className="p-col-12 p-lg-12" style={{ textAlign: 'end' }}>
          {!modoConsulta() ? (
            <ButtonSp
              className="p-button-success"
              icon="pi pi-save"
              label="Salvar"
              disabled={!validateFields(produtoGrupo, ['nome', 'descricao'])}
              showConfirmation
              onClick={handleSalvar}
            />
          ) : null}
          <ButtonSp
            className="p-button-secondary"
            label="Voltar"
            icon="pi pi-chevron-circle-left"
            onClick={handleVoltar}
          />
        </div>
      </>
    );
  }
}
