import React from 'react';
import { InputText } from 'primereact/inputtext';
import PropTypes from 'prop-types';
// import { Container } from './styles';

export default function InputTextSp(props) {
  InputTextSp.propTypes = {
    required: PropTypes.bool,
    className: PropTypes.string,
    disabled: PropTypes.bool,
  };

  InputTextSp.defaultProps = {
    required: false,
  };

  const { value, required, disabled, className, ...rest } = props;
  const classNames = required && !disabled ? `p-error ${className || ''}` : className || '';
  return (
    <InputText
      autoComplete="none"
      {...rest}
      disabled={disabled}
      value={value || ''}
      className={classNames}
    />
  );
}
