import md5 from 'md5';
import { store } from '../store';
import api from './api';
import { loading, loadingSuccess } from '../store/modules/global/actions';
import { geraFiltroHttp } from '../util/functions';

export class BaseService {
  url = '';

  numeroContrato = undefined;

  constructor(urlService) {
    this.url = urlService;
    this.api = api;
  }

  setLoading(value) {
    if (value) {
      store.dispatch(loading());
    } else {
      setTimeout(() => store.dispatch(loadingSuccess()), 100);
      // store.dispatch(loadingSuccess());
    }
  }

  async findAll(filter) {
    this.setLoading(true);
    try {
      const filterGet = geraFiltroHttp(filter);
      const urlSend = `${this.url}${filterGet}`;
      const response = await api.get(urlSend, this.getHeaderAuth(urlSend));
      return response.data;
    } finally {
      this.setLoading(false);
    }
  }

  async findById(id) {
    this.setLoading(true);
    try {
      const urlSend = `${this.url}/${id}`;
      const response = await api.get(urlSend, this.getHeaderAuth(urlSend));
      return response.data;
    } finally {
      this.setLoading(false);
    }
  }

  async insert(body) {
    delete body.id;
    this.setLoading(true);
    try {
      const response = await api.post(this.url, body, this.getHeaderAuth(this.url));
      return response.data;
    } finally {
      this.setLoading(false);
    }
  }

  async update(body) {
    try {
      const urlSend = `${this.url}/${body.id}`;
      const response = await api.put(urlSend, body, this.getHeaderAuth(urlSend));
      return response.data;
    } finally {
      this.setLoading(false);
    }
  }

  async delete(id) {
    try {
      const urlSend = `${this.url}/${id}`;
      await api.delete(urlSend, this.getHeaderAuth(urlSend));
    } finally {
      this.setLoading(false);
    }
  }

  // necessario apenas para rotas abertas, sem token

  getHeaderAuth(path) {
    path = encodeURI(path);
    if (path) {
      return {
        headers: { hashsdata: md5(`/${path}speedyhash`), contrato: this.numeroContrato },
      };
    }
    return { headers: { hashsdata: md5('speedyhash'), contrato: this.numeroContrato } };
  }
}
