import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button } from 'primereact/button';
import PropTypes from 'prop-types';
import { Container } from './styles';
import { showFilter, hideFilter } from '../../store/modules/global/actions';

export default function BarraTitulo(props) {
  const dispatch = useDispatch();
  const filterVisible = useSelector(state => state.global.filterVisible);
  function handleAtualizar() {
    if (filterVisible) {
      dispatch(hideFilter());
    } else {
      dispatch(showFilter());
    }
  }

  return (
    <Container className="p-col-12">
      <span className="title">{props.title}</span>
      {props.handleBtnRefresh && (
        <Button
          className="p-button-secondary buttons"
          title="Atualizar"
          onClick={() => {
            if (props.handleBtnRefresh) {
              props.handleBtnRefresh();
            }
          }}
          label="Atualizar"
        />
      )}

      {props.showBtnFilter && (
        <Button
          className="p-button-success buttons"
          title="Configurar Filtros"
          icon="pi pi-cog"
          onClick={handleAtualizar}
        />
      )}
      {props.showBtnBack && (
        <Button
          className="p-button-secondary buttons"
          title="Voltar"
          icon="pi pi-chevron-circle-left"
          onClick={props.handleBack}
        />
      )}
    </Container>
  );
}

BarraTitulo.propTypes = {
  handleBtnRefresh: PropTypes.func,
  showBtnFilter: PropTypes.bool,
};
