import { BaseService } from './BaseService';
import { ConfigApi } from '../config/Constantes';
import api from './api';

class MapsService extends BaseService {
  constructor() {
    super(ConfigApi.mapsURL);
    this.filter = new Filter();
  }

  getFilter() {
    return this.filter;
  }

  setFilter(filter) {
    this.filter = filter;
  }

  async findLocation(logradouro, numero, bairro, nomeMunicipio, uf) {
    this.setLoading(true);
    try {
      const urlSend = `${ConfigApi.mapsURL}?logradouro=${logradouro}&numero=${numero}&bairro=${bairro}&nomeMunicipio=${nomeMunicipio}&uf=${uf}`;
      const response = await api.get(urlSend, this.getHeaderAuth(urlSend));
      return response.data;
    } finally {
      this.setLoading(false);
    }
  }
}

class Filter {}
export default new MapsService();
