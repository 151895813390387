/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { useTheme } from 'styled-components';
import { Container, ContainerItem, ContainerLista, ContainerTitulo } from './styles';

import { ConfigApi } from '../../../../config/Constantes';
import { formatFloat } from '../../../../util/functions';

function CategoriaProdutos(props) {
  CategoriaProdutos.propTypes = {
    grupoProdutos: PropTypes.object,

    onClick: PropTypes.func,
    numeroContrato: PropTypes.number,
  };

  CategoriaProdutos.defaultProps = {
    numeroContrato: '',
  };

  const { grupoProdutos, onClick, numeroContrato, setExpand } = props;

  // const [expand, setExpand] = useState(expandido);
  // const [listaAtiva, setListaAtiva] = useState(true);
  const [innerWidth, setInnerWidth] = useState(window.innerWidth);

  const theme = useTheme();

  useEffect(() => {
    function handleResize() {
      setInnerWidth(window.innerWidth);
    }

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  });

  if (!grupoProdutos) {
    return null;
  }

  // render principal
  return (
    <Container>
      <a className="marcador" id={`${grupoProdutos.marcador}`} />
      <ContainerTitulo
        onClick={() => {
          // setListaAtiva(!listaAtiva);
          // if (!listaAtiva) {
          setExpand(grupoProdutos.id, !grupoProdutos.expandido);
          // } else {
          //   setTimeout(() => setExpand(grupoProdutos.id, !grupoProdutos.expandido), 1000);
          // }
        }}
      >
        <h1>{grupoProdutos.nome}</h1>
        <i
          className={`${
            grupoProdutos.expandido
              ? 'pi pi-chevron-circle-right'
              : 'pi pi-chevron-circle-down'
          }`}
        />
      </ContainerTitulo>
      <ContainerLista
        className="p-grid"
        style={{
          margin: 0,
          padding: 0,
          maxHeight: grupoProdutos.expandido
            ? (theme.minHeightCardItem + 150) * grupoProdutos.produtos.length
            : 0,
        }}
      >
        {renderProdutos()}
      </ContainerLista>
    </Container>
  );

  function renderProdutos() {
    // if (!grupoProdutos.expandido) {
    //   return null;
    // }

    return grupoProdutos.produtos.map((prod, idx) => {
      const marginRight = (innerWidth >= 1200 && idx % 2 !== 0) || innerWidth < 1200 ? 0 : 15;
      const observacaoProduto = prod.observacao || '';
      const textoComplemento =
        observacaoProduto.substring(0, 255).length < observacaoProduto.length ? '...' : '';

      // let descricao = prod.descricaoResumida ? prod.descricaoResumida : prod.descricao;
      let { descricao } = prod;
      descricao = descricao.substring(0, 60);

      return (
        <div
          className="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-6 p-fluid"
          style={{ margin: 0, padding: 0 }}
          key={`id_produto_${prod.id}`}
        >
          <ContainerItem
            onClick={() => {
              if (onClick) {
                onClick(prod);
              }
            }}
            style={{ marginRight }}
          >
            <div className="div-descricao">
              <h1>{descricao}</h1>
              <span>{observacaoProduto.substring(0, 255) + textoComplemento}</span>
              <h2>{`R$ ${formatFloat(prod.valorVenda, 2)}`}</h2>
            </div>

            {prod.exibirImagem ? (
              <div>
                <img
                  className="image-item"
                  src={`${ConfigApi.baseURL}/files/${numeroContrato}/p${prod.id}.jpg`}
                  onError={event => {
                    event.target.style = 'display: none';
                  }}
                  alt="produto"
                />
              </div>
            ) : null}
          </ContainerItem>
        </div>
      );
    });
  }
}

export default CategoriaProdutos;
