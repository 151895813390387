import React from 'react';
import { Switch } from 'react-router-dom';

import Home from '../pages/Home';
import Route from './Route';
import Login from '../pages/Login';
import Configuracoes from '../pages/Configuracoes';
import Usuario from '../pages/Usuario';
import AcessDenied from '../pages/AcessDenied';
import NotFound from '../pages/NotFound';
import Principal from '../pages/public/Principal';
import Cardapio from '../pages/public/Cardapio';
import Empresa from '../pages/Empresas';
import ProdutoGrupo from '../pages/ProdutoGrupo';
import Produto from '../pages/Produto';
import Pedido from '../pages/Pedido';

export default function Routes() {
  return (
    <Switch>
      <Route path="/" exact isPublic component={Principal} />
      <Route path="/:identificadorHtml" exact isPublic component={Cardapio} />
      <Route path="/painel/login" exact component={Login} />
      <Route path="/painel/denied" exact component={AcessDenied} isPrivate />
      <Route path="/painel/home" component={Home} isPrivate />
      <Route
        path="/painel/configuracoes"
        roles={['CONFIGURACAO_ACESSAR']}
        component={Configuracoes}
      />

      {/* empresa */}
      <Route path="/painel/empresas/:opcao" component={Empresa} roles={['EMPRESA_ACESSAR']} />
      <Route
        path="/painel/empresas"
        component={Empresa}
        roles={['EMPRESA_ACESSAR', 'EMPRESA_INSERIR', 'EMPRESA_ALTERAR']}
      />

      {/* usuarios  */}
      <Route path="/painel/usuarios/:opcao" component={Usuario} roles={['USUARIO_ACESSAR']} />
      <Route
        path="/painel/usuarios"
        component={Usuario}
        roles={['USUARIO_ACESSAR', 'USUARIO_INSERIR', 'USUARIO_ALTERAR']}
      />

      {/* produtoGrupos  */}
      <Route
        path="/painel/produtoGrupos/:opcao"
        component={ProdutoGrupo}
        roles={['PRODUTO_GRUPO_ACESSAR']}
      />
      <Route
        path="/painel/produtoGrupos"
        component={ProdutoGrupo}
        roles={['PRODUTO_GRUPO_ACESSAR', 'PRODUTO_GRUPO_INSERIR', 'PRODUTO_GRUPO_ALTERAR']}
      />

      {/* produtos  */}
      <Route path="/painel/produtos/:opcao" component={Produto} roles={['PRODUTO_ACESSAR']} />
      <Route
        path="/painel/produtos"
        component={Produto}
        roles={['PRODUTO_ACESSAR', 'PRODUTO_INSERIR', 'PRODUTO_ALTERAR']}
      />

      {/* produtos  */}
      <Route path="/painel/pedidos/:opcao" component={Pedido} roles={['PEDIDO_ACESSAR']} />
      <Route
        path="/painel/pedidos"
        component={Pedido}
        roles={['PEDIDO_ACESSAR', 'PEDIDO_INSERIR', 'PEDIDO_ALTERAR']}
      />

      {/* se não encontrar nenhuma rota, vá para login */}
      <Route path="/" isPublic component={NotFound} />
    </Switch>
  );
}
