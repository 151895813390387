import React from 'react';
import { Calendar } from 'primereact/calendar';
import PropTypes from 'prop-types';
// import { Container } from './styles';

export default function CalendarSp(props) {
  CalendarSp.propTypes = {
    required: PropTypes.bool,
    className: PropTypes.string,
    disabled: PropTypes.bool,
  };

  CalendarSp.defaultProps = {
    required: false,
    disabled: false,
  };

  const { value, required, disabled, className, ...rest } = props;
  const classNames = required && !disabled ? `p-error ${className || ''}` : className || '';
  return <Calendar {...rest} disabled={disabled} value={value || ''} className={classNames} />;
}
