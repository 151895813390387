import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Container } from './styles';
import { store } from '../../store';
import { showDialog, hiddenDialogLogin } from '../../store/modules/dialog/actions';

import { loginRequest } from '../../store/modules/auth/actions';
import AuthService from '../../services/AuthService';
import LabelSp from '../LabelSp';
import { validateFields } from '../../util/functions';

export default function MessageDialogLogin() {
  const usuario = AuthService.getUsuario();
  const email = usuario ? usuario.email : '';
  const [password, setPassword] = useState('');
  const { dispatch } = store;
  const { loginVisible } = useSelector(state => {
    return state.dialog.config;
  });

  function hiddenMessage() {
    setPassword('');
    dispatch(hiddenDialogLogin());
  }

  function handleSubmit() {
    setPassword('');
    dispatch(loginRequest(email, password));
  }

  function validate() {
    return validateFields({ password }, 'password');
  }

  const footer = (
    <div>
      <Button
        label="Login"
        icon="pi pi-check"
        className="p-button-success"
        type="submit"
        disabled={!validate()}
        onClick={() => {
          handleSubmit();
        }}
      />
      <Button
        label="Sair"
        icon="pi pi-times"
        onClick={() => {
          hiddenMessage();
        }}
        className="p-button-danger"
      />
    </div>
  );
  return (
    <Container>
      <Dialog
        closable={false}
        footer={footer}
        header="Login"
        visible={loginVisible}
        style={{ minWidth: '250px', maxWidth: '300px', width: '95%' }}
        modal
        onHide={() => {
          hiddenMessage();
        }}
      >
        <form onSubmit={handleSubmit}>
          <div className="p-grid p-fluid">
            <div className="p-col-12">
              <LabelSp>Necessário refazer login</LabelSp>
            </div>
            <div className="p-col-12">
              <InputText type="text" name="login" placeholder="email" disabled value={email} />
            </div>

            <div className="p-col-12">
              <InputText
                type="password"
                name="senha"
                placeholder="senha"
                required
                value={password}
                autoFocus
                onChange={e => setPassword(e.target.value)}
              />
            </div>
          </div>
        </form>
      </Dialog>
    </Container>
  );
}

export function showMessage(title, header, handleClose) {
  const { dispatch } = store;
  dispatch(showDialog(title, header, handleClose));
}
