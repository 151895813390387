import React from 'react';
import { Container } from './styles';

function Rodape() {
  return (
    <Container style={{ textAlign: 'center' }}>
      <span>Todos os direitos reservados à SPEEDY SISTEMAS LTDA</span>
    </Container>
  );
}

export default Rodape;
