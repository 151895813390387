/* eslint-disable no-restricted-syntax */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Dialog } from 'primereact/dialog';
import { toast } from 'react-toastify';
import { useTheme } from 'styled-components';

import {
  Container,
  DivBodyDlg,
  DivItemAdicional,
  DivItemObservacao,
  DivItemPergunta,
  DivItemPerguntaResposta,
} from './styles';
import { cloneObj, formatFloat, goToMarcador } from '../../../../util/functions';

import AuthService from '../../../../services/AuthService';
import { ConfigApi } from '../../../../config/Constantes';

import ButtonQtd from '../ButtonQtd';
import { ButtonContainer } from '../Button/styles';
import Checkbox from '../Checkbox';

// import { Container } from './styles';

function ProdutoPedido(props) {
  ProdutoPedido.propTypes = {
    produto: PropTypes.object,
    setClose: PropTypes.func,
    visible: PropTypes.bool,
    adicionarItemCarrinho: PropTypes.func,
    produtoGrupoAdicional: PropTypes.array,
    produtoPerguntas: PropTypes.array,
    observacaoProdutoGrupo: PropTypes.array,
    observacao: PropTypes.string,
    quantidadeSelecioanda: PropTypes.number,
    alterandoItem: PropTypes.bool,
  };

  ProdutoPedido.defaultProps = {
    produtoGrupoAdicional: [],
    produtoPerguntas: [],
    observacaoProdutoGrupo: [],
    observacao: '',
  };

  const {
    produto,
    adicionarItemCarrinho,
    setClose,
    visible,
    quantidadeSelecioanda,
    alterandoItem,
  } = props;
  const [produtoGrupoAdicional, setProdutoGrupoAdicional] = useState([]);
  const [produtoPerguntas, setProdutoPerguntas] = useState(props.produtoPerguntas || []);
  const [observacaoProdutoGrupo, setObservacaoProdutoGrupo] = useState([]);
  const [observacao, setObservacao] = useState(props.observacao || []);
  const [quantidade, setQuantidade] = useState(1);

  const theme = useTheme();

  useEffect(() => {
    setProdutoGrupoAdicional(props.produtoGrupoAdicional);
    setProdutoPerguntas(props.produtoPerguntas);
    setObservacaoProdutoGrupo(props.observacaoProdutoGrupo);
    setObservacao(props.observacao);
    setQuantidade(quantidadeSelecioanda || 1);
  }, [
    props.observacao,
    props.observacaoProdutoGrupo,
    props.produtoGrupoAdicional,
    props.produtoPerguntas,
    quantidadeSelecioanda,
  ]);

  const calculaTotal = useCallback(() => {
    let valorTotal = quantidade * produto.valorVenda;

    produtoGrupoAdicional.forEach(add => {
      if (add.quantidade > 0) {
        if (add.cobrarValorAdicional) {
          const valorAdicional = add.quantidade * add.produtoAdicional.valorVenda || 0;
          valorTotal += valorAdicional;
        }
      }
    });

    produtoPerguntas.forEach(p => {
      p.respostas.forEach(r => {
        if (r.cobrarValorAdicional && r.quantidadeResposta > 0 && r.marcado) {
          valorTotal += r.quantidadeResposta * r.produto.valorVenda;
        }
      });
    });

    return valorTotal;
  }, [produto.valorVenda, produtoGrupoAdicional, produtoPerguntas, quantidade]);

  function validaRespostas() {
    if (produtoPerguntas.length > 0) {
      for (const p of produtoPerguntas) {
        if (Array.isArray(p.respostas) && p.respostas.length > 0) {
          let qtdResposta = 0;
          const { respostas } = p;
          if (Array.isArray(respostas)) {
            respostas.forEach(r => {
              if (r.marcado) {
                qtdResposta += r.quantidadeResposta;
              }
            });
          }
          if (p.respostaObrigatoria) {
            if (qtdResposta < p.qtdeMinimaOpcaoResposta) {
              toast.warn(`Opção "${p.descricao}" não atingiu a quantidade mínima`, {
                autoClose: 2000,
              });
              goToMarcador(`pergunta-id-${p.id}`);
              return false;
            }
          }
          if (qtdResposta > p.qtdeMaximaOpcaoResposta) {
            toast.warn(`Opção "${p.descricao}" ultrapassou a quantidade máxima`, {
              autoClose: 2000,
            });
            goToMarcador(`pergunta-id-${p.id}`);
            return false;
          }
        }
      }
    }

    return true;
  }

  function alteraQuantidade(operacao) {
    if (operacao === '+') {
      let valor = quantidade + 1;
      if (valor > 99) {
        valor = 99;
      }
      setQuantidade(valor);
    } else {
      let valor = quantidade - 1;
      if (valor <= 0) {
        valor = 1;
      }
      setQuantidade(valor);
    }
  }

  function alteraQuantidadeAdicional(a, operacao) {
    if (!a.quantidade) {
      a.quantidade = 0;
    }

    let valor = 0;
    if (operacao === '+') {
      valor = a.quantidade + 1;
      if (valor > 99) {
        valor = 99;
      }
    } else {
      valor = a.quantidade - 1;
      if (valor < 0) {
        valor = 0;
      }
    }
    a.quantidade = valor;
    const ret = cloneObj(produtoGrupoAdicional);
    setProdutoGrupoAdicional(ret);
  }

  function alteraQuantidadeResposta(a, operacao) {
    if (!a.quantidadeResposta) {
      a.quantidadeResposta = a.qtdeMinimaResposta;
    }

    let valor = 0;
    if (operacao === '+') {
      valor = a.quantidadeResposta + 1;
      if (valor > a.qtdeMaximaResposta) {
        valor = a.qtdeMaximaResposta;
      }
    } else {
      valor = a.quantidadeResposta - 1;
      if (valor < a.qtdeMinimaResposta) {
        valor = a.qtdeMinimaResposta;
      }
    }
    a.quantidadeResposta = valor;
    const ret = cloneObj(produtoPerguntas);
    setProdutoPerguntas(ret);
  }

  function marcaDesmarcaObs(a) {
    if (a.marcado === undefined) {
      a.marcado = false;
    }

    a.marcado = !a.marcado;
    const ret = cloneObj(observacaoProdutoGrupo);
    setObservacaoProdutoGrupo(ret);
  }

  function marcaDesmarcaResposta(a) {
    if (a.marcado === undefined) {
      a.marcado = false;
    }

    a.marcado = !a.marcado;
    const ret = cloneObj(produtoPerguntas);
    setProdutoPerguntas(ret);
  }

  if (!visible) {
    return null;
  }

  function renderObservacoes(observacoes) {
    return observacoes.map(a => {
      // const descricao = a.observacaoProduto.descricao.substring(0, 20);
      const descricao = a.observacaoProduto.descricao.substring(
        0,
        window.innerWidth >= 1024 ? 50 : 30
      );

      return (
        <DivItemObservacao key={a.id} onClick={() => marcaDesmarcaObs(a)}>
          <span className="titulo"> {descricao}</span>
          <Checkbox checked={!!a.marcado} />
        </DivItemObservacao>
      );
    });
  }

  function renderAdicionais(adicionais) {
    if (!Array.isArray(adicionais)) {
      return null;
    }
    return adicionais.map(a => {
      let { descricao } = a.produtoAdicional;
      // descricao = descricao.substring(0, 17);

      descricao = descricao.substring(0, window.innerWidth >= 1024 ? 50 : 25);

      return (
        <DivItemAdicional key={a.produtoAdicional.id}>
          <div className="div-descricao-preco">
            <span className="titulo">{descricao}</span>
            <span>+ {formatFloat(a.produtoAdicional.valorVenda || 0, 2)}</span>
          </div>
          <div style={{ backgroundColor: '#FFF', borderColor: '#d8d8d8', height: 40 }}>
            <ButtonQtd
              style={{ fontSize: 14, maxWidth: 100, minWidth: 100 }}
              value={a.quantidade || 0}
              handleClickAdd={() => alteraQuantidadeAdicional(a, '+')}
              handleClickRemove={() => alteraQuantidadeAdicional(a, '-')}
            />
          </div>
        </DivItemAdicional>
      );
    });
  }

  function renderRespostas(pergunta) {
    if (!pergunta || !Array.isArray(pergunta.respostas)) {
      return null;
    }
    const { respostas } = pergunta;

    return respostas.map(a => {
      const produtoOuObs = a.produto ? a.produto : a.observacao;
      const respostaProduto = !!a.produto;

      let { descricao } = produtoOuObs;
      descricao = descricao.substring(0, window.innerWidth >= 1024 ? 50 : 21);
      const valorAdiconalResposta = a.cobrarValorAdicional ? produtoOuObs.valorVenda : 0;
      return (
        <DivItemPerguntaResposta key={`resposta${a.id}`}>
          <div className="div-obs-item" onClick={() => marcaDesmarcaResposta(a)}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'start',
                alignContent: 'center',
              }}
            >
              <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'start' }}>
                <Checkbox checked={!!a.marcado} />

                <div className="div-descricao-preco">
                  <span style={{ marginLeft: 2, fontSize: '1.0em' }}>{descricao}</span>
                </div>
              </div>
              {respostaProduto && (
                <div>
                  <span style={{ display: 'inline-block' }}>
                    + {formatFloat(valorAdiconalResposta || 0)}
                  </span>
                </div>
              )}
            </div>
          </div>

          {a.qtdeMinimaResposta !== a.qtdeMaximaResposta && a.qtdeMaximaResposta > 1 ? (
            <div style={{ backgroundColor: '#FFF', borderColor: '#d8d8d8', height: 40 }}>
              <ButtonQtd
                style={{ fontSize: 14, maxWidth: 100, minWidth: 100 }}
                value={a.quantidadeResposta || 0}
                handleClickAdd={() => alteraQuantidadeResposta(a, '+')}
                handleClickRemove={() => alteraQuantidadeResposta(a, '-')}
              />
            </div>
          ) : null}
        </DivItemPerguntaResposta>
      );
    });
  }

  function renderPerguntas(perguntas) {
    if (!Array.isArray(perguntas)) {
      return null;
    }
    return perguntas.map(a => {
      let { descricao } = a;
      if (Array.isArray(a.respostas) && a.respostas.length) {
        let obrigatorio = a.respostaObrigatoria
          ? `Escolha de ${a.qtdeMinimaOpcaoResposta} até ${a.qtdeMaximaOpcaoResposta} opções`
          : `Escolha até ${a.qtdeMaximaOpcaoResposta} opções`;

        if (a.qtdeMinimaOpcaoResposta === a.qtdeMaximaOpcaoResposta) {
          obrigatorio = `Escolha ${a.qtdeMinimaOpcaoResposta} opções`;
        }

        descricao = descricao.substring(0, window.innerWidth >= 1024 ? 40 : 21);
        return (
          <DivItemPergunta key={`pergunta${a.id}`} id={`pergunta-id-${a.id}`}>
            <div className="div-descricao-resposta">
              <span className="span-descricao titulo">{descricao}</span>
              {obrigatorio && (
                <span className="span-descricao-adicional titulo">{obrigatorio}</span>
              )}
            </div>
            {renderRespostas(a)}
          </DivItemPergunta>
        );
      }
      return null;
    });
  }

  if (!produto) {
    return null;
  }

  const observacaoProduto = produto.observacao;
  // retorno do render add produto
  return (
    <Container>
      <Dialog
        closable
        // header={produto.descricao}
        // footer={renderFooter()}
        footer={null}
        visible={visible}
        showHeader={false}
        className="dialog"
        contentStyle={{
          // maxHeight: '80vh',
          overflowY: 'hidden',
          overflowX: 'hidden',

          borderRadius: theme.borderRadius,
          borderStyle: 'solid',
          borderWith: 0,
          backgroundColor: theme.colors.backgroundCardapio,
          margin: 0,
          padding: 0,
        }}
        modal
        onHide={() => {
          setClose();
        }}
      >
        <DivBodyDlg>
          <div className="div-botao-fechar">
            <button className="pi pi-times-circle button-close" onClick={() => setClose()} />
          </div>
          <div className="div-container">
            <div className="div-imagem-obs">
              {produto.exibirImagem ? (
                <img
                  className="image-item"
                  src={`${ConfigApi.baseURL}/files/${AuthService.numeroContrato}/p${produto.id}.jpg`}
                  onError={event => {
                    event.target.style = 'display: none';
                  }}
                  alt="produto"
                />
              ) : null}

              <div className="div-observacao-image">
                <span className="titulo">Adicionar Observação</span>
                <textarea
                  className="texto-obs unresize"
                  maxLength={300}
                  rows={10}
                  value={observacao}
                  style={{ width: '100%' }}
                  onChange={e => setObservacao(e.target.value)}
                />
              </div>
            </div>

            <div className="div-produto">
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <span className="titulo titulo-produto">Detalhes do produto</span>
              </div>
              <div className="card-produto">
                <div className="detalhes-produto">
                  <span className="descricao">{produto.descricao.substring(0, 60)}</span>
                  <span className="observacao">{observacaoProduto}</span>
                  <h2>R$ {formatFloat(produto.valorVenda, 2)}</h2>
                </div>
                <div>
                  {produto.exibirImagem ? (
                    <img
                      className="image-produto"
                      src={`${ConfigApi.baseURL}/files/${AuthService.numeroContrato}/p${produto.id}.jpg`}
                      onError={event => {
                        event.target.style = 'display: none';
                      }}
                      alt="produto"
                    />
                  ) : null}
                </div>
              </div>

              <div className="complementos">
                <div className="respostas">
                  {produtoPerguntas && produtoPerguntas.length ? (
                    <div>
                      <span className="titulo titulo-adicionais">Selecione as opções</span>
                      {renderPerguntas(produtoPerguntas)}
                    </div>
                  ) : null}
                </div>
                <div className="adicionais-produto">
                  {produtoGrupoAdicional && produtoGrupoAdicional.length ? (
                    <div>
                      <span className="titulo titulo-adicionais">Adicionais</span>
                      {renderAdicionais(produtoGrupoAdicional)}
                    </div>
                  ) : null}
                </div>
                <div className="observacoes-produto">
                  <span className="titulo titulo-adicionais">Observações</span>
                  {renderObservacoes(observacaoProdutoGrupo)}
                  <div className="div-observacao-produto">
                    <span className="titulo titulo-adicionais">Adicionar Observação</span>
                    <textarea
                      className="texto-obs2 unresize"
                      maxLength={300}
                      rows={5}
                      value={observacao}
                      style={{ width: '100%' }}
                      onChange={e => setObservacao(e.target.value)}
                    />
                  </div>
                </div>
              </div>
              <div className="rodape">
                <ButtonQtd
                  handleClickAdd={() => alteraQuantidade('+')}
                  handleClickRemove={() => alteraQuantidade('-')}
                  podeFracionar={produto.produtoUnidade?.podeFracionar}
                  handleSetValue={setQuantidade}
                  value={quantidade}
                />
                <ButtonContainer
                  className="button-adicionar"
                  onClick={() => {
                    if (!validaRespostas()) {
                      return;
                    }
                    if (!quantidade) {
                      toast.error('Quantidade inválida');

                      return;
                    }
                    const itemSelecionado = {
                      produto,
                      produtoGrupoAdicional,
                      produtoPerguntas,
                      observacao,
                      quantidade,
                      observacaoProdutoGrupo,
                      alterandoItem,
                    };

                    adicionarItemCarrinho(itemSelecionado);
                  }}
                >
                  <span>{alterandoItem ? 'Confirmar' : 'Adicionar'}</span>
                  <span>R$ {formatFloat(calculaTotal(), 2)}</span>
                </ButtonContainer>
              </div>
            </div>
          </div>
        </DivBodyDlg>
      </Dialog>
    </Container>
  );
}

export default ProdutoPedido;
