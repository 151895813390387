import { BaseService } from './BaseService';
import { ConfigApi } from '../config/Constantes';

class ProdutoService extends BaseService {
  constructor() {
    super(`${ConfigApi.produtoURL}`);
    this.filter = new Filter();
  }

  getFilter() {
    return this.filter;
  }

  setFilter(filter) {
    this.filter = filter;
  }
}

class Filter {
  constructor() {
    this.nome = '';
    this.descricao = '';
    this.limit = 11;
    this.page = 0;
  }
}
export default new ProdutoService();
