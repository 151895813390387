import styled from 'styled-components';

export const Container = styled.div`
  margin: 0;
  padding: 0;
  /* margin-top: -10px; */
  .imagem-food {
    width: 100%;
    height: auto;
    max-width: 100%;
  }

  @media screen and (max-width: 992px) {
    .imagem-food {
      width: 200%;
    }
  }

  .botao-assinar {
    margin-left: 3px;
    margin-right: 3px;
    padding-right: 5px;
    padding-left: 5px;
    text-align: center;
    border-radius: 3px;
    transition: background 0.2s;
    color: #fff;

    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    background: #0d6b0e;
    border: #107d11 1px solid;
    &:hover,
    &:focus {
      cursor: pointer;
      background: #107d11;
    }

    i {
      font-size: 20px;
    }
  }

  .geral {
    color: #002;
    background: #d8d8d8;
    margin-top: -4px;
    padding: 0;

    .geral-item {
      margin: 10px 0 10px 0;
      padding-right: 20px;
      padding-left: 20px;
    }

    /* .geral-ultimo-item {
      border-right: none;
    } */

    .titulo-item-geral {
      color: #2c2c2c;
      margin-bottom: 10px;
      font-weight: bold;
      font-size: 22px;
      text-align: center;
      text-shadow: 0.5px 0.5px #c9cbcc;
    }

    .corpo-item-geral {
      font-weight: normal;
      font-size: 22px;
      line-height: 1.6;
      text-align: justify;
      margin: 0;
      padding: 0;

      ul {
        line-height: 1.2;
      }
      li {
        line-height: 1.2;
        font-size: 16px;
      }
    }

    @media screen and (max-width: 992px) {
      .geral-item {
        border-right: none;
        padding: 5px;
      }
      .titulo-item-geral {
        /* border-bottom: 1px #fff solid; */
        padding-bottom: 0px;
        margin-bottom: 5px;
      }
      .corpo-item-geral {
        font-size: 18px;
      }
    }
  }

  .lista-clientes {
    padding-bottom: 10px;
    padding-top: 10px;

    .titulo-lista-clientes {
      color: #2c2c2c;
      margin-top: 10px;
      margin-bottom: 0px;
      font-weight: bold;
      font-size: 22px;
      text-align: center;
      text-shadow: 0.5px 0.5px #c9cbcc;
    }

    @media screen and (max-width: 992px) {
      padding: 5px;
    }

    .items-lista-clientes {
      margin: 0;
    }

    .item-lista-clientes {
      display: flex;
      flex-direction: row;
      align-items: center;
      background: #ffffff;
      height: 120px;
      padding: 10px;
      margin: 0px;
      border: #d8d8d8 2px solid;
      border-radius: 5px;
      transition: background 0.2s;

      -webkit-touch-callout: none;
      -webkit-user-select: none;
      -khtml-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;

      &:hover,
      &:focus {
        background: #f4f8f9;
        border: #19a890 2px solid;
        cursor: pointer;
      }

      div {
        margin: 5px;
      }

      .img-cliente {
        height: 80px;
        width: 80px;
        min-height: 80px;
        min-width: 80px;
        border-radius: 50%;
        border: #d8d8d8 2px solid;
        background: #f4f8f9;
      }

      .info-cliente {
        flex: 1;
        width: calc(100% - 90px);

        span,
        strong {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }

      .endereco {
        font-size: 14px;
      }

      @media screen and (max-width: 992px) {
        .endereco {
          font-size: 12px;
        }
      }

      img {
        height: 75px;
        width: 75px;
        min-height: 75px;
        min-width: 75px;
        border-radius: 50%;
        /* border: #d8d8d8 2px solid;
        background: #f4f8f9; */
      }

      strong {
        display: block;
      }
      span {
        display: block;
      }
    }
  }
`;

export const BotaoSuporte = styled.div`
  margin-bottom: 25px;
  margin-right: 10px;
  position: fixed;
  bottom: 0;
  right: 0;
  z-index: 2;
  overflow: hidden;
  padding: 10px;
  background: #30b944;
  border-radius: 50%;
  display: flex;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  a {
    margin: 0;
    padding: 0;
    text-decoration: none;
    display: flex;
  }

  &:hover {
    cursor: pointer;
    background: #33cf4a;
  }
`;
