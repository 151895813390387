import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { formatCelularWhatsApp, nomeDiaSemana } from '../../../../util/functions';
import DialogCardapio from '../../../../components/DialogCardapio';

import { Container } from './styles';
import { ConfigApi } from '../../../../config/Constantes';

import iconWahts from '../../../../assets/cardapio/icons/iconWhatsapp.png';
import iconTelefone from '../../../../assets/cardapio/icons/iconTelefone.png';

function EmpresaInfo(props) {
  EmpresaInfo.propTypes = {
    empresa: PropTypes.object.isRequired,
    listaHorarios: PropTypes.any,
    visible: PropTypes.bool,
    setVisible: PropTypes.func,
  };

  const { empresa, listaHorarios, visible, setVisible } = props;
  const logoUri = `${ConfigApi.baseURL}/files/${empresa?.fileUri}`;

  const [page, setPage] = useState(0);

  const criaListaHorarios = () => {
    const retorno = [];
    for (let x = 0; x < 7; x++) {
      const horariosDia = listaHorarios.filter(h => h.diaSemana === x);

      const horario = {};
      horario.diaSemana = nomeDiaSemana(x);

      for (let y = 0; y < horariosDia.length; y++) {
        horario[`horaAbertura${y + 1}`] = horariosDia[y].horaAbertura.substring(0, 5);
        horario[`horaFechamento${y + 1}`] = horariosDia[y].horaFechamento.substring(0, 5);
      }
      if (horario.horaAbertura1 || horario.horaAbertura2) {
        horario.textoHorario = '';
        if (horario.horaAbertura1 && horario.horaFechamento1) {
          horario.textoHorario = `${horario.horaAbertura1} às ${horario.horaFechamento1}`;
        }

        if (horario.horaAbertura2 && horario.horaFechamento2) {
          const horario2 = `${horario.horaAbertura2} às ${horario.horaFechamento2}`;
          if (horario.textoHorario) {
            horario.textoHorario += `, ${horario2.toLocaleLowerCase()}`;
          } else {
            horario.textoHorario += horario2;
          }
        }
        retorno.push(horario);
      }
    }
    return retorno;
  };

  const horarios = criaListaHorarios();

  const renderDivSobre = () => {
    return (
      <div className="div-sobre">
        <div className="div-logo-empresa">
          <img className="logo" src={logoUri} alt="logo" />
          <div className="div-empresa">
            <span className="titulo">{empresa.fantasia}</span>
            {/* <span className="titulo">{empresa.razaoSocial}</span> */}
            <span className="titulo">CNPJ: {empresa.cpfCnpj}</span>
          </div>
        </div>
        <span className="titulo" style={{ marginTop: 10 }}>
          Endereço
        </span>
        <span className="endereco">{`${empresa.logradouro} ${empresa.numero} ${empresa.bairro}`}</span>
        <span className="endereco">{`${empresa.nomeMunicipio} ${empresa.uf}`}</span>
        <span className="endereco">{`CEP: ${empresa.cep}`}</span>
        {/* <span className="cnpj">{`CPNJ: ${empresa.cpfCnpj}`}</span> */}

        <span className="titulo" style={{ marginTop: 10 }}>
          Contato
        </span>
        <div className="div-contatos">
          {empresa.celular && (
            <button
              onClick={() =>
                window.open(
                  `https://wa.me/550${formatCelularWhatsApp(empresa.celular)}`,
                  '_blank'
                )
              }
            >
              <img className="icon-button" src={iconWahts} alt="logo" />
              {empresa.telefone}
            </button>
          )}

          {empresa.telefone && (
            <button
              onClick={() =>
                window.open(`tel:${formatCelularWhatsApp(empresa.telefone)}`, '_self')
              }
            >
              <img className="icon-button" src={iconTelefone} alt="logo" />
              {empresa.telefone}
            </button>
          )}
        </div>
      </div>
    );
  };

  const renderDivHorarios = () => {
    return (
      <div className="div-horarios">
        {horarios.map(h => {
          return (
            <div className="div-dia" key={h.diaSemana}>
              <span className="dia-semana">{h.diaSemana}</span>
              <span>{h.textoHorario}</span>
            </div>
          );
        })}
      </div>
    );
  };

  return (
    <DialogCardapio
      closable
      visible={visible}
      setVisible={setVisible}
      style={{ minWidth: '350px', maxWidth: '600px', width: '95%' }}
      title="Informações da Empresa"
    >
      <Container>
        <div className="div-cabecalho">
          <span
            className={`botao-tab${page === 0 ? ' border-button-tab-select' : ''}`}
            onClick={() => setPage(0)}
          >
            Sobre
          </span>

          <span
            className={`botao-tab${page === 1 ? ' border-button-tab-select' : ''}`}
            onClick={() => setPage(1)}
          >
            Horários
          </span>
        </div>
        <div className="body">{page === 0 ? renderDivSobre() : renderDivHorarios()}</div>
      </Container>
    </DialogCardapio>
  );
}

export default EmpresaInfo;
