import React from 'react';

import PropTypes from 'prop-types';
import { Container } from './styles';

/**
 * @typedef CheckboxProps
 * @property {checked} boolean valor que atual
 * @property {React.CSSProperties} style style do componente
 */

/**
 * @func Checkbox
 * @param {CheckboxProps} props
 */

function Checkbox(props) {
  Checkbox.propTypes = {
    style: PropTypes.any,
    checked: PropTypes.bool,
  };
  const { checked, style } = props;
  return (
    <Container style={{ ...style }}>{checked ? <div className="marcacao" /> : null}</Container>
  );
}

export default Checkbox;
