import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
// import { ProgressBar } from 'primereact/progressbar';
import { ProgressSpinner } from 'primereact/progressspinner';

import { Container } from './styles';

export default function DisplayBlock(props) {
  DisplayBlock.propTypes = {
    paddingLeftIcon: PropTypes.number,
  };

  DisplayBlock.defaultProps = {
    paddingLeftIcon: 0,
  };

  const loading = useSelector(state => state.global.loading);
  const { paddingLeftIcon } = props;

  if (loading) {
    return (
      <Container>
        {/* <ProgressBar mode="indeterminate" style={{ height: '6px' }} /> */}
        <div
          style={{
            flex: 1,
            alignItems: 'center',
            justifyItems: 'center',
            display: 'flex',
          }}
        >
          <div
            style={{
              flex: 1,
              alignItems: 'center',
              justifyItems: 'center',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <div className="div-icon" style={{ paddingLeft: paddingLeftIcon }}>
              <ProgressSpinner
                style={{ width: 50, height: 50 }}
                strokeWidth="8"
                animationDuration=".5s"
              />
            </div>
          </div>
        </div>
      </Container>
    );
  }
  return null;
}
