import React, { useState, useEffect } from 'react';
import { Button } from 'primereact/button';
import { useDispatch } from 'react-redux';

import logo from '../../assets/images/logo300.png';
import { Container } from './styles';

import { loginRequest } from '../../store/modules/auth/actions';
import { validateFields, isNumber } from '../../util/functions';
import InputTextSp from '../../components/InputTextSp';

export default function Login() {
  const [email, setEmail] = useState([]);
  const [password, setPassword] = useState([]);
  const [numeroContrato, setNumeroContrato] = useState();

  const dispatch = useDispatch();

  useEffect(() => {
    setEmail('');
    setPassword('');
  }, []);

  function handleSubmit(e) {
    e.preventDefault();
    dispatch(loginRequest(email, password, '/painel/home', numeroContrato));
  }

  return (
    <Container>
      <form onSubmit={handleSubmit}>
        <div className="p-grid p-fluid">
          <div className="p-col-12">
            <img src={logo} alt="" />
          </div>

          <div className="p-col-12">
            <InputTextSp
              autoFocus
              type="text"
              name="login"
              placeholder="seu e-mail"
              required
              value={email}
              onChange={e => setEmail(e.target.value)}
            />
          </div>

          <div className="p-col-12">
            <InputTextSp
              type="password"
              name="password"
              placeholder="senha"
              required
              value={password}
              onChange={e => setPassword(e.target.value)}
            />
          </div>
          {email === 'adm@speedysistemas.com.br' ? (
            <div className="p-col-12">
              <InputTextSp
                name="numeroContrato"
                placeholder="Nr. do Contrato"
                maxLength={6}
                value={numeroContrato}
                onChange={e => {
                  if (isNumber(e.target.value)) {
                    setNumeroContrato(e.target.value);
                  }
                }}
              />
            </div>
          ) : null}

          <div className="p-col-12">
            <Button
              type="submit"
              label="Login"
              disabled={!validateFields({ email, password }, ['email', 'password'])}
            />
          </div>
        </div>
      </form>
    </Container>
  );
}
