import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import { differenceInMinutes } from 'date-fns';
import md5 from 'md5';
import { useTheme } from 'styled-components';

import queryString from 'query-string';

import AuthService from '../../../services/AuthService';
import EmpresaService from '../../../services/EmpresaService';
import {
  arredonda,
  cloneObj,
  configuraTitleDescription,
  errorHandle,
  formatFloat,
  verificaEmpresaFuncionando,
} from '../../../util/functions';

import Cabecalho from '../components/Cabecalho';
import RodapeCardapio from '../components/RodapeCardapio';
import CategoriaProdutos from '../components/CategoriaProdutos';

import MapsEmpresa from '../components/MapsEmpresa';
import { Body, ConfirmacaoPedido, Layout } from './styles';
import GrupoService from '../../../services/GrupoService';
import {
  PedidoCabecalhoModel,
  PedidoDetalheModel,
  PedidoDetalheObsModel,
  PedidoPerguntaModel,
  PedidoPerguntaRespostaModel,
} from '../../../util/Models';
import { StatusTelaCardapio } from '../../../util/Tipos';
import FormaPagamentoService from '../../../services/FormaPagamentoService';
import MunicipioService from '../../../services/MunicipioService';
import EnderecoService from '../../../services/EnderecoService';
import PedidoService from '../../../services/PedidoService';
import Categorias from '../components/Categorias';

import iconCheckout from '../../../assets/cardapio/icons/icone-checkout-checked.png';

import ProdutoPedido from '../components/ProdutoPedido';

import Sacola from '../components/Sacola';
import { ConfigApi } from '../../../config/Constantes';
import { setThemeColor } from '../../../store/modules/global/actions';
import { store } from '../../../store';
import DialogCardapio from '../../../components/DialogCardapio';
import Checkout from '../components/Checkout';
import { showMessage } from '../../../components/MessageDialog';
import Button from '../components/Button';

function Cardapio(props) {
  const { identificadorHtml } = props.match.params;
  const [empresaLogada, setEmpresaLogada] = useState(null);
  const [empresaHorario, setEmpresaHorario] = useState(null);
  const [permitePedidoOnline, setPermitePedidoOnline] = useState(true);
  const [listaHorarios, setListaHorarios] = useState([]);
  const [produto, setProduto] = useState(null);
  const [pedido, setPedido] = useState(new PedidoCabecalhoModel());
  const [statusTela, setStatusTela] = useState(StatusTelaCardapio.stCardapio);
  const [grupos, setGrupos] = useState([]);
  const [produtoGrupos, setProdutoGrupos] = useState([]);
  const [nomeProdutoSelecionado, setNomeProdutoSelecionado] = useState('');
  const [listaFormasPagamento, setListaFormasPagamento] = useState([]);

  const [produtoGrupoAdicionalSelecionada, setProdutoGrupoAdicionalSelecionada] = useState([]);
  const [produtoPerguntasSelecionada, setProdutoPerguntasSelecionada] = useState([]);
  const [observacaoProdutoGrupoSelecionada, setObservacaoProdutoGrupoSelecionada] = useState(
    []
  );
  const [observacaoSelecionada, setObservacaoSelecionada] = useState('');
  const [quantidadeSelecionada, setQuantidadeSelecionada] = useState(1);
  const [idxVendaDetalheAlteracao, setIdxVendaDetalheAlteracao] = useState(undefined);
  const [showSacola, setShowSacola] = useState(false);
  const [showCheckout, setShowCheckout] = useState(false);
  const [showPedidoConcluido, setShowPedidoConcluido] = useState(false);

  const theme = useTheme();

  const queryParams = queryString.parse(props.location.search);

  const idMesa = useMemo(() => Number(queryParams.idMesa) || 0, [queryParams.idMesa]);
  const informarMesa = useMemo(() => queryParams.informarMesa === 'true', [
    queryParams.informarMesa,
  ]);

  function verificaPedidoExpirado(ped) {
    if (ped) {
      const dataHoraPedido = new Date(ped.hora);
      const retorno = Math.abs(differenceInMinutes(dataHoraPedido, new Date())) > 120; // 120Min.
      if (retorno) {
        // se expirou, limpa o pedido
        try {
          sessionStorage.removeItem('pedidoAtual');
        } catch (err) {
          //
        }
        setPedido(new PedidoCabecalhoModel());
        // força recarregar pagina
        window.location.reload();
      }
      return retorno;
    }
    return false;
  }

  function carregaPedidoStorage() {
    try {
      const retorno = JSON.parse(sessionStorage.getItem('pedidoAtual'));

      const { hash } = retorno;
      delete retorno.hash;
      const strPed = JSON.stringify(retorno);

      if (md5(`${strPed}speedymd5`) !== hash) {
        sessionStorage.removeItem('pedidoAtual');
        return null;
      }
      delete retorno.hash;

      return retorno;
    } catch (err) {
      return null;
    }
  }

  function salvaPedidoStorage(ped) {
    // Salva pedido no sessionStorage
    if (ped) {
      try {
        delete ped.hash;
        const hash = md5(`${JSON.stringify(ped)}speedymd5`);
        ped.hash = hash;
        sessionStorage.setItem('pedidoAtual', JSON.stringify(ped));
      } catch (err) {
        //
      }
    }
  }

  function setExpand(idGrupo, expand) {
    const prodGrup = produtoGrupos.find(e => e.id === idGrupo);
    if (prodGrup && prodGrup.expandido !== expand) {
      prodGrup.expandido = expand;
      setProdutoGrupos([...produtoGrupos]);
    }
  }

  // callbacks
  const carregaEmpresa = useCallback(
    async (identificador, force = false) => {
      if (force || !empresaLogada || identificador !== empresaLogada.identificadorHtml) {
        let empresa;
        try {
          empresa = await EmpresaService.findByIdHtml(identificador);
        } catch (err) {
          toast.error('Empresa não localizada');
          setTimeout(() => (window.location = '/'), 2000);
        }

        if (!empresa) {
          return;
        }

        if (empresa.inativo) {
          toast.error('Empresa está inativada');

          setTimeout(() => (window.location = '/'), 2000);
          return;
        }

        setEmpresaHorario(empresa);
        configuraTitleDescription(empresa ? empresa.fantasia : 'Speedy Sistemas');
        if (empresa) {
          if (
            empresa.permitePedidoOnline === undefined ||
            empresa.permitePedidoOnline === null
          ) {
            empresa.permitePedidoOnline = true;
          }

          setPermitePedidoOnline(empresa.permitePedidoOnline);
          setEmpresaLogada(empresa);
          setListaHorarios(empresa.listaHorarios);

          AuthService.setNumeroContrato(empresa.numeroContrato);
          if (!empresa) {
            window.location = '/';
          }

          store.dispatch(setThemeColor(empresa.idTema));
        }
      }
    },
    [empresaLogada]
  );

  const carregaProdutoGrupo = useCallback(
    async (descricaoProduto, tipoCardapio) => {
      if (verificaPedidoExpirado(pedido)) {
        return;
      }

      try {
        if (!empresaLogada || statusTela !== StatusTelaCardapio.stCardapio) {
          return;
        }
        if (descricaoProduto === undefined || descricaoProduto === null) {
          return;
        }

        const filtro = {};
        if (descricaoProduto) {
          filtro.descricaoProduto = descricaoProduto;
        }

        if (tipoCardapio) {
          filtro.tipoCardapio = tipoCardapio;
        }

        const retorno = await GrupoService.findAll(filtro);
        const listaGrupos = retorno.items.map(e => {
          const marcador = e.nome.replace(/ /g, '_');
          e.marcador = marcador;

          return {
            id: e.id,
            nome: e.nome.substring(0, 15),
            nomeCompleto: e.nome,
            marcador,
            exibirImagem: e.exibirImagem,
          };
        });

        const gropProd = retorno.items.map((e, idx) => {
          let expandido = true;

          if (produtoGrupos && produtoGrupos.length === 0) {
            // somente ao abrir
            if (empresaLogada.tipoGrupoExpandido === 1) {
              // primeiro expandido
              if (idx === 0) {
                expandido = true;
              } else {
                expandido = false;
              }
            } else if (empresaLogada.tipoGrupoExpandido === 2) {
              // nenhum expandido
              expandido = false;
            }
          } else if (produtoGrupos) {
            // manter a configuracao expandido que existia antes
            const grupo = produtoGrupos.find(g => g.id === e.id);
            if (grupo) {
              expandido = grupo.expandido;
            }
          }
          return { ...e, expandido };
        });

        setGrupos(listaGrupos);
        setProdutoGrupos(gropProd);
      } catch (err) {
        errorHandle(err);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [empresaLogada, statusTela]
  );

  function salvaMeusPedidos(pedidoSalvar) {
    if (pedidoSalvar) {
      const strListaMeusPedidos = localStorage.getItem(`MeusPedidos_${identificadorHtml}`);
      let listaMeusPedidos = [];

      if (strListaMeusPedidos) {
        try {
          listaMeusPedidos = JSON.parse(strListaMeusPedidos);
          if (!Array.isArray(listaMeusPedidos)) {
            listaMeusPedidos = [];
          }
        } catch (err) {
          //
        }
      }

      listaMeusPedidos.push(pedidoSalvar);
      try {
        localStorage.setItem(
          `MeusPedidos_${identificadorHtml}`,
          JSON.stringify(listaMeusPedidos)
        );
      } catch (err) {
        //
      }

      // if (!strListaMeusPedidos) {
      //   // se for a primeira vez avisa o usuário
      //   showMessage(
      //     'Aviso',
      //     'Consulte a situação do seu pedido no menu Meus Pedidos',
      //     null,
      //     false
      //   );
      // }
    }
  }

  function salvaDadosEntregaStorage(pedidoEntrega) {
    if (pedidoEntrega) {
      try {
        localStorage.setItem('pedidoEntrega', JSON.stringify(pedidoEntrega));
      } catch (err) {
        //
      }
    }
  }

  const configuraContratoService = useCallback(empresa => {
    if (empresa) {
      const contrato = empresa.numeroContrato;
      GrupoService.numeroContrato = contrato;
      FormaPagamentoService.numeroContrato = contrato;
      MunicipioService.numeroContrato = contrato;
      AuthService.numeroContrato = contrato;
      EnderecoService.numeroContrato = contrato;
      PedidoService.numeroContrato = contrato;
      EmpresaService.numeroContrato = contrato;
    }
  }, []);

  async function enviarPedido(avisoUtilizacaoMaiorTaxa, numeroMesa) {
    if (!pedido) {
      showMessage('Aviso', 'Pedido inválido', null, false);
      return;
    }
    if (pedido.pedidoEntrega.nome === '') {
      toast.warn('Informe seu nome', { autoClose: 2000 });

      return;
    }
    const telefone = pedido.pedidoEntrega.telefone
      ? pedido.pedidoEntrega.telefone.replace(/-|\(|\)| /gi, '')
      : '';

    if (telefone === '') {
      toast.warn('Informe seu telefone', { autoClose: 2000 });
      return;
    }

    if (pedido.pedidoEntrega.retiradaDelivery === 0) {
      const cep = pedido.pedidoEntrega.cep
        ? pedido.pedidoEntrega.cep.replace(/-|\(|\)| /gi, '')
        : '';

      if (
        !(
          pedido.pedidoEntrega.logradouro &&
          pedido.pedidoEntrega.codigoIbge &&
          pedido.pedidoEntrega.bairro &&
          pedido.pedidoEntrega.numero &&
          cep
        )
      ) {
        toast.warn('Endereço incompleto.', { autoClose: 2000 });
        return;
      }

      // if (pedido.pedidoEntrega.codigoIbge !== empresaLogada.codigoIbge) {
      //   showMessage(
      //     'Aviso',
      //     `Fazemos entregas somente no município de ${empresaLogada.nomeMunicipio}-${empresaLogada.uf}`,
      //     null,
      //     false
      //   );
      //   return;
      // }
    }

    if (!pedido.idFormaPagamento) {
      toast.warn('Informe a forma de pagamento', { autoClose: 2000 });
      return;
    }

    if (
      pedido.pedidoEntrega.latitude === 0 &&
      pedido.pedidoEntrega.longitude === 0 &&
      Array.isArray(empresaLogada.listaTaxas) &&
      empresaLogada.listaTaxas.length > 0
    ) {
      pedido.calculouEntrega = false;
    }

    let msgFrete = '';
    if (avisoUtilizacaoMaiorTaxa && pedido.pedidoEntrega.retiradaDelivery === 0) {
      msgFrete = `Seu endereço não foi identificado corretamente e assim a maior taxa de entrega será usada.
        `;
    }

    const valorEntrega =
      pedido.pedidoEntrega.retiradaDelivery === 0 ? pedido.valorEntrega || 0 : 0;
    showMessage(
      'Confirmação',
      `${msgFrete}Confirma o pedido no valor de R$${formatFloat(
        pedido.valor + valorEntrega,
        2
      )}?`,
      async idx => {
        if (idx === 1) {
          pedido.pedidoEntrega.retirarNoBalcao = false;
          if (pedido.pedidoEntrega.retiradaDelivery > 0) {
            pedido.pedidoEntrega.bairro = '';
            pedido.pedidoEntrega.cep = '';
            pedido.pedidoEntrega.complemento = '';
            pedido.pedidoEntrega.logradouro = '';
            pedido.pedidoEntrega.numero = '';
            if (pedido.pedidoEntrega.retiradaDelivery === 1) {
              pedido.localEntrega = 'Retirada balcão';
            } else if (pedido.pedidoEntrega.retiradaDelivery === 1) {
              pedido.localEntrega = 'Consumo no Local';
            }
            pedido.pedidoEntrega.retirarNoBalcao = true;
          }

          if (!queryParams.idCardapio || queryParams.idCardapio === '1') {
            pedido.numeroMesa = numeroMesa || 0;
          }

          const obsPed =
            pedido.numeroMesa && pedido.observacao
              ? `, ${pedido.observacao}`
              : pedido.observacao;
          const observacao = pedido.numeroMesa
            ? `Nr. Mesa: ${pedido.numeroMesa}${obsPed}`
            : pedido.observacao;

          const pedidoRetorno = await PedidoService.insert({ ...pedido, observacao });
          salvaMeusPedidos(pedidoRetorno);
          setStatusTela(StatusTelaCardapio.stCardapio);
          const novoPedido = new PedidoCabecalhoModel();

          salvaDadosEntregaStorage(pedido.pedidoEntrega);

          salvaPedidoStorage(novoPedido);
          setPedido(novoPedido);
          setShowCheckout(false);
          setShowSacola(false);

          setShowPedidoConcluido(true);
        }
      }
    );
  }

  const carregaPedido = useCallback(empresa => {
    // recuperando pedido do localstorage
    const pedidoSalvo = carregaPedidoStorage();

    if (pedidoSalvo) {
      if (verificaPedidoExpirado(pedidoSalvo)) {
        return;
      }

      if (empresa && empresa.id !== pedidoSalvo.idEmpresa) {
        // se expirou, limpa o pedido
        sessionStorage.removeItem('pedidoAtual');
        setPedido(new PedidoCabecalhoModel());
        return;
      }

      if (!empresa) {
        return;
      }

      setPedido(pedidoSalvo);
    }
  }, []);

  const carregaFormasPagamento = useCallback(async empresa => {
    if (empresa) {
      // Carregando formas pagamento
      try {
        const formasPagamento = await FormaPagamentoService.findAll();

        const formas = formasPagamento.items.map(f => {
          return {
            label: f.nome,
            value: f.id,
          };
        });
        setListaFormasPagamento(formas);
      } catch (err) {
        //
      }
    }
  }, []);

  function handleProdutoClick(idProduto) {
    if (empresaLogada === null) {
      window.location.reload(); // recarrega janela para obter a empresa
      return;
    }

    if (verificaPedidoExpirado(pedido)) {
      return;
    }

    const grupo = produtoGrupos.find(e => e.produtos.find(p => p.id === idProduto));
    const prod = grupo.produtos.find(p => p.id === idProduto);

    setProduto(prod);

    setProdutoGrupoAdicionalSelecionada(cloneObj(prod.adicionais));
    setObservacaoProdutoGrupoSelecionada(cloneObj(grupo.observacaoProdutoGrupo));

    const perguntas = (prod.perguntas || []).map(p => {
      if (p.respostas) {
        const resp = [];
        p.respostas.forEach(r => {
          if (!r.quantidadeResposta) {
            r.quantidadeResposta = r.qtdeMinimaResposta;
          }
          if (r.marcado === undefined) {
            r.marcado = false;
          }

          // remove as perguntas de produtos inativos
          if ((r.produto && !r.produto.inativo) || !r.produto) {
            resp.push(r);
          }
        });
        p.respostas = resp;
      }

      return p;
    });

    setProdutoPerguntasSelecionada(perguntas);

    setStatusTela(StatusTelaCardapio.stAddPedido);
    setObservacaoSelecionada('');
    setQuantidadeSelecionada(1);
  }

  function handleEditarProduto(idxDetalhe) {
    const detalhe = pedido.pedidoDetalhe[idxDetalhe];
    const { idProduto } = detalhe;

    if (empresaLogada === null) {
      window.location.reload(); // recarrega janela para obter a empresa
      return;
    }

    if (!detalhe) {
      return;
    }

    if (verificaPedidoExpirado(pedido)) {
      return;
    }

    const grupo = produtoGrupos.find(e => e.produtos.find(p => p.id === idProduto));
    const prod = grupo.produtos.find(p => p.id === idProduto);

    setProduto(prod);

    const pgAdicional = cloneObj(prod.adicionais);

    if (Array.isArray(detalhe.pedidoDetalhe)) {
      pgAdicional.forEach(pgAdd => {
        const adicional = detalhe.pedidoDetalhe.find(
          vd => vd.idProduto === pgAdd.idProdutoAdicional
        );
        if (adicional) {
          pgAdd.quantidade = adicional.quantidade;
        }
      });
    }

    setProdutoGrupoAdicionalSelecionada(pgAdicional);

    const obsProduto = cloneObj(grupo.observacaoProdutoGrupo);
    if (Array.isArray(detalhe.pedidoDetalheObs)) {
      obsProduto.forEach(obProd => {
        const adicional = detalhe.pedidoDetalheObs.find(
          ob => ob.idObservacaoProduto === obProd.idObservacaoProduto
        );
        if (adicional) {
          obProd.marcado = true;
        }
      });
    }
    setObservacaoProdutoGrupoSelecionada(obsProduto);

    const perguntas = (prod.perguntas || []).map(p => {
      if (p.respostas) {
        p.respostas = p.respostas.map(r => {
          if (!r.quantidadeResposta) {
            r.quantidadeResposta = r.qtdeMinimaResposta;
          }
          if (r.marcado === undefined) {
            r.marcado = false;
          }
          return r;
        });
      }

      return p;
    });

    if (Array.isArray(detalhe.perguntas)) {
      perguntas.forEach(perg => {
        const detPergunta = detalhe.perguntas.find(detPg => detPg.id === perg.id);
        if (detPergunta) {
          // procura as respostas
          if (Array.isArray(detPergunta.respostas)) {
            perg.respostas.forEach(resp => {
              const resposta = detPergunta.respostas.find(detResp => detResp.id === resp.id);

              if (resposta) {
                // procura as respostas

                resp.quantidadeResposta = resposta.quantidadeResposta || 1;

                resp.marcado = resposta.marcado;
              }
            });
          }
        }
      });
    }

    setProdutoPerguntasSelecionada(perguntas);

    setStatusTela(StatusTelaCardapio.stAlterandoPedido);
    setObservacaoSelecionada(detalhe.observacao);
    setQuantidadeSelecionada(detalhe.quantidade);
  }

  function atualizaValoresPedido(ped) {
    let valorTotal = 0;
    let qtdItens = 0;
    ped.pedidoDetalhe.forEach(d => {
      valorTotal += d.valorTotal;
      if (!d.adicional) {
        qtdItens++;
      }
    });

    ped.valorSubtotal = valorTotal;
    ped.valor = valorTotal;
    // nunca somar o valor da entrega no total. Deixar a api fazer isso conforme o flag
    // de retirada no balcao
    ped.quantidadeItens = qtdItens;
  }

  async function carregaHorarios() {
    let retornoListaHorarios = [];
    let retornoEmpresaHorarios = null;
    if (identificadorHtml) {
      let empresa;
      try {
        empresa = await EmpresaService.findByIdHtml(identificadorHtml);
      } catch (err) {
        errorHandle(err);
      }
      retornoEmpresaHorarios = empresa;
      setEmpresaHorario(empresa);
      if (empresa) {
        setListaHorarios(empresa.listaHorarios);
        retornoListaHorarios = empresa.listaHorarios;
      } else {
        retornoListaHorarios = [];
      }
    }
    return [retornoListaHorarios, retornoEmpresaHorarios];
  }

  async function validaFinalizacaoPedido() {
    if (verificaPedidoExpirado(pedido)) {
      return false;
    }

    if (pedido.quantidadeItens === 0) {
      showMessage('Aviso', 'Sua sacola está vazia', null, false);
      return false;
    }

    const [horarios, empHorario] = await carregaHorarios();

    if (!verificaEmpresaFuncionando(empHorario.estaFuncionando, horarios)) {
      showMessage(
        'Aviso',
        'Desculpe, no momento não estamos recebendo pedidos. Volte mais tarde por favor.',
        null,
        false
      );
      return false;
    }

    return true;
  }

  async function handleFinalizar() {
    if (await validaFinalizacaoPedido()) {
      setShowCheckout(true);
    }
  }

  function adicionarItemCarrinho(itemSelecionado) {
    const {
      quantidade,
      observacao,
      produtoPerguntas,
      observacaoProdutoGrupo,
      produtoGrupoAdicional,
      alterandoItem,
    } = itemSelecionado;

    let valorProdutoAdicional = 0;
    let valorPerguntaResposta = 0;

    if (empresaLogada === null) {
      window.location.reload(); // recarrega janela para obter a empresa
    }

    let pedidoAtual = new PedidoCabecalhoModel();
    if (pedido !== null) {
      pedidoAtual = cloneObj(pedido);
    }

    pedidoAtual.id = null;
    pedidoAtual.data = new Date();
    pedidoAtual.hora = new Date();
    pedidoAtual.idEmpresa = empresaLogada.id;
    pedidoAtual.localEntrega = '';
    pedidoAtual.situacao = 'A';
    pedidoAtual.valor = 0;
    pedidoAtual.valorSubtotal = 0;
    pedidoAtual.valorTrocoPara = 0;

    const numeroItem = pedidoAtual.pedidoDetalhe.reduce((prev, current) => {
      return prev.numeroItem > current.numeroItem ? prev.numeroItem : current.numeroItem;
    }, 0);

    // adicionando item
    const item = new PedidoDetalheModel();
    // let descricaoProduto = produto.descricaoResumida
    //   ? produto.descricaoResumida
    //   : produto.descricao || '';

    let descricaoProduto = produto.descricao || '';

    // descricaoProduto = descricaoProduto;

    item.adicional = false;
    item.idProduto = produto.id;
    item.descricao = descricaoProduto;
    item.unidade = produto.produtoUnidade.sigla;
    item.numeroItem = numeroItem + 1;
    item.observacao = observacao;
    item.quantidade = quantidade;
    item.valorUnitario = arredonda(produto.valorVenda, 2);
    item.valorSubtotal = arredonda(item.valorUnitario * item.quantidade, 2);
    item.valorTotal = item.valorSubtotal;
    item.urlImagem = produto.exibirImagem
      ? `${ConfigApi.baseURL}/files/${AuthService.numeroContrato}/p${produto.id}.jpg`
      : '';

    // perguntas
    item.perguntas = [];

    produtoPerguntas.forEach(p => {
      const pergunta = new PedidoPerguntaModel();
      pergunta.id = p.id;
      pergunta.descricao = p.descricao;
      pergunta.idPedidoDetalhe = p.idPedidoDetalhe;
      pergunta.ordem = p.ordem;
      pergunta.qtdeMaximaOpcaoResposta = p.qtdeMaximaOpcaoResposta;
      pergunta.qtdeMinimaOpcaoResposta = p.qtdeMinimaOpcaoResposta;
      pergunta.respostaObrigatoria = p.respostaObrigatoria;
      pergunta.tipoResposta = p.tipoResposta;

      pergunta.respostas = p.respostas.map(r => {
        const resposta = new PedidoPerguntaRespostaModel();
        resposta.id = r.id;
        resposta.idProduto = r.idProduto;
        resposta.idPedidoPergunta = r.idPedidoPergunta;
        resposta.idProdutoObservacao = r.idProdutoObservacao;
        resposta.observacao = cloneObj(r.observacao);
        resposta.produto = cloneObj(r.produto);
        resposta.qtdeMaximaResposta = r.qtdeMaximaResposta;
        resposta.qtdeMinimaResposta = r.qtdeMinimaResposta;
        resposta.valorPerguntaResposta = r.produto?.valorVenda || 0;
        resposta.cobrarValorAdicional = r.cobrarValorAdicional;
        if (r.marcado) {
          resposta.quantidadeResposta = r.quantidadeResposta;
        } else {
          resposta.quantidadeResposta = 0;
        }
        resposta.marcado = r.marcado;

        return resposta;
      });
      item.perguntas.push(pergunta);
    });

    // adicionando item
    if (
      alterandoItem &&
      idxVendaDetalheAlteracao >= 0 &&
      pedidoAtual.pedidoDetalhe[idxVendaDetalheAlteracao]
    ) {
      pedidoAtual.pedidoDetalhe[idxVendaDetalheAlteracao] = item;
    } else {
      pedidoAtual.pedidoDetalhe.push(item);
    }

    // observações
    observacaoProdutoGrupo.forEach(e => {
      if (e.marcado) {
        const itemObs = new PedidoDetalheObsModel();
        itemObs.idObservacaoProduto = e.idObservacaoProduto;
        itemObs.observacaoProduto = e.observacaoProduto;
        itemObs.idPedidoDetalhe = null;
        itemObs.id = null;

        item.pedidoDetalheObs.push(itemObs);
      }
    });

    valorProdutoAdicional = 0;

    produtoGrupoAdicional.forEach(e => {
      if (e.quantidade > 0) {
        // descricaoProduto = e.produto.descricaoResumida
        //   ? e.produto.descricaoResumida
        //   : e.produto.descricao || '';

        descricaoProduto = e.produtoAdicional.descricao;

        // descricaoProduto = descricaoProduto.substring(0, 19);
        const itemAdicional = new PedidoDetalheModel();
        itemAdicional.id = null;
        itemAdicional.idProduto = e.idProdutoAdicional;
        itemAdicional.adicional = true;
        itemAdicional.numeroItem = item.numeroItem;
        itemAdicional.quantidade = e.quantidade;
        itemAdicional.descricao = descricaoProduto;
        itemAdicional.unidade = e.produtoAdicional.produtoUnidade.sigla;
        itemAdicional.valorAdicional = e.cobrarValorAdicional
          ? e.produtoAdicional.valorVenda
          : 0;
        itemAdicional.valorAdicional = arredonda(itemAdicional.valorAdicional, 2);
        itemAdicional.valorSubtotal = 0;
        itemAdicional.valorTotal = 0;
        valorProdutoAdicional += arredonda(
          itemAdicional.valorAdicional * itemAdicional.quantidade,
          2
        );
        item.pedidoDetalhe.push(itemAdicional);
      }
    });

    valorPerguntaResposta = 0;

    produtoPerguntas.forEach(p => {
      p.respostas.forEach(r => {
        if (r.cobrarValorAdicional && r.quantidadeResposta > 0 && r.marcado) {
          valorPerguntaResposta += r.quantidadeResposta * r.produto?.valorVenda || 0;
        }
      });
    });

    // Atualizando valor do item

    item.valorAdicional = valorProdutoAdicional + valorPerguntaResposta;
    item.valorProdutoAdicional = valorProdutoAdicional;
    item.valorPerguntaResposta = valorPerguntaResposta;

    item.valorTotal = item.valorSubtotal + item.valorAdicional;

    // calculando valores do pedido
    atualizaValoresPedido(pedidoAtual);
    setPedido(pedidoAtual);

    setStatusTela(StatusTelaCardapio.stCardapio);
    salvaPedidoStorage(pedidoAtual);
  }

  // useEffects
  useEffect(() => {
    if (!identificadorHtml) {
      window.location = '/';
    } else {
      carregaEmpresa(identificadorHtml);
    }
  }, [carregaEmpresa, identificadorHtml]);

  useEffect(() => {
    carregaPedido(empresaLogada);
    configuraContratoService(empresaLogada);
    carregaFormasPagamento(empresaLogada);
  }, [carregaFormasPagamento, carregaPedido, configuraContratoService, empresaLogada]);

  useEffect(() => {
    carregaProdutoGrupo(nomeProdutoSelecionado, queryParams.tipoCardapio);
  }, [carregaProdutoGrupo, nomeProdutoSelecionado, queryParams.tipoCardapio]);

  let enderecoMaps = '';
  if (empresaHorario) {
    enderecoMaps = `${empresaHorario.logradouro}+${empresaHorario.numero}+${empresaHorario.nomeMunicipio}+${empresaHorario.uf}`;
  }

  // render principal
  function renderProdutoGrupos() {
    return produtoGrupos.map(gp => {
      return (
        <CategoriaProdutos
          key={gp.id}
          grupoProdutos={gp}
          onClick={prod => {
            if (permitePedidoOnline) {
              handleProdutoClick(prod.id);
              // console.log(_produto);
            }
          }}
          numeroContrato={empresaHorario.numeroContrato}
          expandido={gp.expandido}
          setExpand={(id, expand) => setExpand(id, expand)}
        />
      );
    });
  }

  return (
    <>
      <Cabecalho
        empresa={empresaHorario}
        onPesquisar={texto => setNomeProdutoSelecionado(texto)}
        listaHorarios={listaHorarios}
        identificadorHtml={identificadorHtml}
      />

      {[StatusTelaCardapio.stAddPedido, StatusTelaCardapio.stAlterandoPedido].includes(
        statusTela
      ) ? (
        <ProdutoPedido
          produto={produto}
          setClose={() => setStatusTela(StatusTelaCardapio.stCardapio)}
          visible={[
            StatusTelaCardapio.stAddPedido,
            StatusTelaCardapio.stAlterandoPedido,
          ].includes(statusTela)}
          adicionarItemCarrinho={itemSelecionado => {
            adicionarItemCarrinho(itemSelecionado);
          }}
          produtoGrupoAdicional={produtoGrupoAdicionalSelecionada}
          produtoPerguntas={produtoPerguntasSelecionada}
          observacaoProdutoGrupo={observacaoProdutoGrupoSelecionada}
          observacao={observacaoSelecionada}
          quantidadeSelecioanda={quantidadeSelecionada}
          alterandoItem={statusTela === StatusTelaCardapio.stAlterandoPedido}
        />
      ) : null}
      {showCheckout ? (
        <Checkout
          visible={showCheckout}
          setVisible={setShowCheckout}
          pedido={pedido}
          setPedido={setPedido}
          atualizaValoresPedido={atualizaValoresPedido}
          empresaLogada={empresaLogada}
          listaFormasPagamento={listaFormasPagamento}
          enviarPedido={enviarPedido}
          numeroMesa={idMesa}
          informarMesa={informarMesa}
          tipoCardapio={queryParams.tipoCardapio}
        />
      ) : null}
      {showSacola ? (
        <DialogCardapio
          closable
          visible={showSacola}
          setVisible={setShowSacola}
          style={{ minWidth: '350px', maxWidth: '400px', width: '95%' }}
        >
          <Sacola
            pedido={pedido}
            removeItem={idx => {
              pedido.pedidoDetalhe.splice(idx, 1);
              atualizaValoresPedido(pedido);
              setPedido({ ...pedido });
              salvaPedidoStorage(pedido);
            }}
            editaItem={idx => {
              handleEditarProduto(idx);
              setIdxVendaDetalheAlteracao(idx);
            }}
            doClose={() => setShowSacola(false)}
            handleFinalizar={() => handleFinalizar()}
          />
        </DialogCardapio>
      ) : null}

      {showPedidoConcluido ? (
        <DialogCardapio
          closable
          visible={showPedidoConcluido}
          setVisible={setShowPedidoConcluido}
          style={{ minWidth: '350px', maxWidth: '400px', width: '95%' }}
        >
          <ConfirmacaoPedido>
            <img className="img" src={iconCheckout} alt="pedido finalizado" />
            <span className="msg-sucesso">Pedido Enviado com Sucesso!</span>
            <span className="msg-info">
              Você pode acompanhar seu pedido através da página Meus Pedidos
            </span>
            <div className="div-botoes">
              <Button
                text="Acompanhar Pedido"
                onClick={() => {
                  setShowPedidoConcluido(false);
                  setTimeout(() => {
                    const op = document.getElementById('meus-pedidos-cabecalho');
                    if (op) {
                      op.click();
                    }
                  }, 300);
                }}
              />
              <Button
                text="Continuar Comprando"
                onClick={() => {
                  setShowPedidoConcluido(false);
                }}
                style={{
                  color: theme.colors.primary,
                  borderColor: theme.colors.primary,
                  borderWidth: 1,
                  background: theme.colors.backgroundCardapio,
                }}
              />
            </div>
          </ConfirmacaoPedido>
        </DialogCardapio>
      ) : null}

      <Body id="topoPagina">
        <Layout permitePedidoOnline={permitePedidoOnline}>
          <main className="o-main">
            <Categorias
              numeroContrato={AuthService.numeroContrato}
              grupos={grupos}
              onExpandGrupo={idGrupo => setExpand(idGrupo, true)}
            />
            <div style={{ margin: 0, padding: 0, paddingBottom: 50 }}>
              {renderProdutoGrupos()}
            </div>
          </main>

          <aside className="o-aside">
            <MapsEmpresa endereco={enderecoMaps} />
            <Sacola
              pedido={pedido}
              removeItem={idx => {
                pedido.pedidoDetalhe.splice(idx, 1);
                atualizaValoresPedido(pedido);
                setPedido({ ...pedido });
                salvaPedidoStorage(pedido);
              }}
              editaItem={idx => {
                handleEditarProduto(idx);
                setIdxVendaDetalheAlteracao(idx);
              }}
              handleFinalizar={() => handleFinalizar()}
            />
          </aside>
        </Layout>
      </Body>
      <RodapeCardapio
        identificadorHtml={identificadorHtml}
        qtdItens={pedido?.pedidoDetalhe?.length || 0}
        setShowSacola={() => setShowSacola(true)}
        valorPedido={pedido?.valor}
        permitePedidoOnline={permitePedidoOnline}
      />
    </>
  );
}

export default Cardapio;
