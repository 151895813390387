import produce from 'immer';

const INITIAL_STATE = {
  tiposDocumento: [],
  ativarMenuEstatico: true,
};

export default function config(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case '@config/SAVE_CONFIG': {
        draft.ativarMenuEstatico = action.config.ativarMenuEstatico;
        break;
      }
      case '@config/SAVE_CONFIG_API': {
        draft.tiposDocumento = action.config.tiposDocumento;
        break;
      }
      default:
    }
  });
}
