import React, { useState } from 'react';
import classNames from 'classnames';
import { store } from '../../../../store';
import AuthService from '../../../../services/AuthService';
import { logout } from '../../../../store/modules/auth/actions';
import iconProfile from '../../../../assets/images/profile.png';
import history from '../../../../services/history';

export function Profile(props) {
  const { hiddeMenu } = props;
  const [expanded, setExpanded] = useState(false);

  function onClick(event) {
    setExpanded(!expanded);
    event.preventDefault();
  }

  const handleLogout = () => {
    store.dispatch(logout());
  };

  const { usuario } = AuthService.acessToken();
  return (
    <div className="layout-profile">
      <div>
        <img src={iconProfile} alt="" />
      </div>
      <button className="p-link layout-profile-link" onClick={onClick}>
        <span className="username">{usuario?.nome}</span>
        <i className="pi pi-fw pi-cog" />
      </button>
      <ul
        className={classNames({
          'layout-profile-expanded': expanded,
        })}
      >
        <li>
          <button
            className="p-link"
            onClick={() => {
              if (hiddeMenu) {
                hiddeMenu();
              }
              history.push(`/usuarios/${AuthService.getUsuario()?.id}?backTo=/Home`);
            }}
          >
            <i className="pi pi-fw pi-user" />
            <span>Conta</span>
          </button>
        </li>
        <li>
          <button className="p-link" onClick={() => handleLogout()}>
            <i className="pi pi-fw pi-power-off" />
            <span>Sair</span>
          </button>
        </li>
      </ul>
    </div>
  );
}
