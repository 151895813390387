/* eslint-disable react/void-dom-elements-no-children */
/* eslint-disable jsx-a11y/alt-text */
import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { useTheme } from 'styled-components';
import {
  Container,
  ContainerEmpresa,
  ContainerImage,
  ContainerLogo,
  ContainerMenu,
  ContainerDadosPesquisa,
} from './styles';
// import imageCabecalho from '../../../../assets/cardapio/images/banner-superior-layout-1660x310px.png';
import imageDisponivel from '../../../../assets/cardapio/icons/cabecalho-online.png';
import iconePesquisa from '../../../../assets/cardapio/icons/cabecalho-pesquisa.png';
import iconeLocalizacao from '../../../../assets/cardapio/icons/cabecalho-localizacao.png';
import inicioMenu from '../../../../assets/cardapio/icons/inicio-menu-cardapio.png';
import inicioSacola from '../../../../assets/cardapio/icons/meus-pedidos_menu-cardapio.png';

import { verificaEmpresaFuncionando, capitalizeStr } from '../../../../util/functions';

import { ConfigApi } from '../../../../config/Constantes';
import MeusPedidos from '../MeusPedidos';
import EmpresaInfo from '../EmpresaInfo';

function Cabecalho(props) {
  Cabecalho.propTypes = {
    empresa: PropTypes.object,
    listaHorarios: PropTypes.arrayOf(PropTypes.object),
    onPesquisar: PropTypes.func,
    identificadorHtml: PropTypes.string,
  };

  Cabecalho.defaultProps = {
    listaHorarios: [],
    onPesquisar: undefined,
  };

  const theme = useTheme();
  const [textoPesquisa, setTextoPesquisa] = useState('');
  const [filterTimeOut, setFilterTimeOut] = useState();
  const [mostrarMeusPedidos, setMostrarMeusPedidos] = useState(false);
  const [showInformacoes, setShowInformacoes] = useState(false);

  const { empresa, listaHorarios, onPesquisar, identificadorHtml } = props;

  if (!empresa) {
    return null;
  }

  const logoUri = `${ConfigApi.baseURL}/files/${empresa?.fileUri}`;
  const bannerUri = `${ConfigApi.baseURL}/files/${empresa?.fileUriBanner}`;
  const fantasia = (empresa?.fantasia || '').substring(0, 34);
  const estaFuncionando = verificaEmpresaFuncionando(empresa?.estaFuncionando, listaHorarios);

  // functions
  function textSearchChange(value) {
    const val = value;
    setTextoPesquisa(val);
    if (onPesquisar) {
      if (filterTimeOut) {
        clearTimeout(filterTimeOut);
      }
      const func = setTimeout(async () => {
        onPesquisar(val);
      }, 800);
      setFilterTimeOut(func);
    }
  }

  // renders
  return (
    <>
      <Container>
        <MeusPedidos
          setShowDialog={setMostrarMeusPedidos}
          identificadorHtml={identificadorHtml}
          showDialog={mostrarMeusPedidos}
        />
        <EmpresaInfo
          empresa={empresa}
          visible={showInformacoes}
          setVisible={setShowInformacoes}
          listaHorarios={listaHorarios}
        />
        <ContainerMenu>
          <span onClick={() => {}}>
            <img src={inicioMenu} />
            Inicio
          </span>
          <span id="meus-pedidos-cabecalho" onClick={() => setMostrarMeusPedidos(true)}>
            <img src={inicioSacola} />
            Meus Pedidos
          </span>
        </ContainerMenu>
        <ContainerEmpresa>
          <ContainerImage>
            <img src={bannerUri} alt="loja" />
          </ContainerImage>
          <ContainerLogo>
            <img className="logo" src={logoUri} alt="logo" />
            {estaFuncionando && (
              <img className="img-disponivel" src={imageDisponivel} alt="disponivel" />
            )}
          </ContainerLogo>
          <ContainerDadosPesquisa>
            <h1 className="font-titulo">{fantasia}</h1>

            <div className="dados-empresa-pesquisa">
              <div className="dados-empresa">
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <span
                    style={{
                      color: estaFuncionando ? theme.colors.open : theme.colors.closed,
                    }}
                  >
                    {estaFuncionando ? 'Aberto' : 'Fechado'}
                  </span>
                  {/* <span className="marcador">&bull;</span> */}
                </div>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <img src={iconeLocalizacao} />
                  <span className="titulo-municipio">{`${capitalizeStr(
                    empresa.nomeMunicipio
                  )}-${empresa.uf}`}</span>
                  {/* <span className="marcador">&bull;</span> */}
                </div>
                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <input
                    className="button"
                    type="button"
                    value="+ Informações"
                    onClick={() => setShowInformacoes(true)}
                  />
                </div>
              </div>
              <div className="container-pesquisa">
                <div className="pesquisa">
                  <img className="logo" src={iconePesquisa} alt="pesquisa" />
                  <input
                    type="text"
                    value={textoPesquisa}
                    placeholder="Realizar pesquisa"
                    onChange={e => textSearchChange(e.target.value)}
                  />
                </div>
              </div>
            </div>
          </ContainerDadosPesquisa>
        </ContainerEmpresa>
      </Container>
    </>
  );
}

export default Cabecalho;
