import styled from 'styled-components';

export const Container = styled.div`
  .p-inputtext:enabled:focus {
    outline: 0 none;
    outline-offset: 0;
    -webkit-box-shadow: 0 0 0 0em #8dcdff;
    -moz-box-shadow: 0 0 0 0em #8dcdff;
    box-shadow: 0 0 0 0em #8dcdff;
    border-color: ${({ theme, required }) =>
      required === true ? theme.colors.required : theme.colors.borderColor};
  }

  .p-inputtext:enabled:hover {
    /* border-width: 1.5px; */
    border-color: ${({ theme, required }) =>
      required === true ? theme.colors.required : theme.colors.borderColor};
  }

  input {
    padding: 5px;
    border-width: 1px;
    border-radius: 4px;
    border-color: ${({ theme }) => theme.colors.borderColor};
    border-style: solid;
    width: 100%;
    min-height: 35px;

    padding: 3px 7px 3px 7px;
    font-size: 13px;
    font-family: ${({ theme }) => theme.fonts.regular};

    background: ${props => props.theme.colors.backgroundCard};
  }
  .required {
    border-color: ${({ theme }) => theme.colors.required};
  }
`;
