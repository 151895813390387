/* eslint-disable no-await-in-loop */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { useTheme } from 'styled-components';
import { DivFooterDlg, Container } from './styles';
import {
  cloneObj,
  formatDate,
  formatFloat,
  getDiferencaEmHoras,
  strToDate,
} from '../../../../util/functions';
import PedidoService from '../../../../services/PedidoService';
import Dialog from '../../../../components/DialogCardapio';

import Button from '../Button';

function MeusPedidos(props) {
  MeusPedidos.propTypes = {
    setShowDialog: PropTypes.func.isRequired,
    showDialog: PropTypes.bool.isRequired,
    identificadorHtml: PropTypes.string.isRequired,
  };
  const { showDialog, setShowDialog, identificadorHtml } = props;
  const [listaPedidos, setListaPedidos] = useState([]);
  const theme = useTheme();

  const atualizaStatus = useCallback(
    async lista => {
      // atualiza lista de pedidos
      for (let i = 0; i < lista.length; i++) {
        try {
          const pedido = await PedidoService.findByUuId(lista[i].uuId);
          lista[i].situacao = pedido.situacao;
          lista[i].tempoMinutosEntrega = pedido.tempoMinutosEntrega;
        } catch (err) {
          lista[i].situacao = '';
        }
      }

      try {
        localStorage.setItem(`MeusPedidos_${identificadorHtml}`, JSON.stringify(lista));
      } catch (err) {
        //
      }
      setListaPedidos(cloneObj(lista));
    },
    [identificadorHtml]
  );

  const carregaPedidos = useCallback(() => {
    // recuperando pedido do localstorage
    const strListaMeusPedidos = localStorage.getItem(`MeusPedidos_${identificadorHtml}`);
    let listaMeusPedidos = [];

    if (strListaMeusPedidos) {
      try {
        listaMeusPedidos = JSON.parse(strListaMeusPedidos);

        if (!Array.isArray(listaMeusPedidos)) {
          listaMeusPedidos = [];
        }
      } catch (err) {
        //
      }
    }
    const dataAtual = new Date();

    const lista = listaMeusPedidos.filter(e => {
      const diferenca = getDiferencaEmHoras(dataAtual, strToDate(e.data, 'yyyy-MM-dd'));
      return diferenca < 96;
    });

    lista.sort((a, b) => {
      if (`${a.data}${a.hora}` > `${b.data}${b.hora}`) {
        return -1;
      }
      if (`${a.data}${a.hora}` < `${b.data}${b.hora}`) {
        return 1;
      }
      return 0;
    });

    setListaPedidos(lista);
    // atualizaStatus;
    atualizaStatus(lista);
  }, [atualizaStatus, identificadorHtml]);

  useEffect(() => {
    if (showDialog) {
      carregaPedidos();
    }
  }, [carregaPedidos, showDialog]);

  function renderSituacaoItem(item) {
    let cor = '';
    let nomeLegenda = '';

    if (item.situacao === 'A') {
      cor = corAguardando;
      nomeLegenda = 'Aguardando';
    } else if (item.situacao === 'P') {
      cor = corPreparando;
      nomeLegenda = 'Em preparação';
    } else if (item.situacao === 'E') {
      cor = corEntregando;
      nomeLegenda = 'Entregando';
    } else if (item.situacao === 'F') {
      cor = corFinalizado;
      nomeLegenda = 'Entregue';
    } else if (item.situacao === 'C') {
      cor = corCancelado;
      nomeLegenda = 'Cancelado';
    } else if (item.situacao === '') {
      cor = '';
      nomeLegenda = 'Não definido';
    }

    return (
      <div className="legenda">
        {cor && <div style={{ background: cor }}>A</div>}
        <span>{nomeLegenda}</span>
      </div>
    );
  }

  const renderPedidos = pedidos => {
    return pedidos.map((ped, idx) => {
      const dataHora = `${formatDate(ped.data, 'dd/MM/yyyy')} ${ped.hora.substring(0, 5)}`;
      let previsao = 'Sem previsão';
      if (ped.tempoMinutosEntrega > 0) {
        previsao = `${ped.tempoMinutosEntrega} minutos`;
      }

      return (
        <div
          className="card"
          key={idx}
          style={{
            background:
              idx % 2 === 0 ? theme.colors.backgroundCardapio : theme.colors.colorZebrado,
          }}
        >
          <div className="linha" style={{ justifyContent: 'space-between' }}>
            <div className="div-caption-valor">
              <span className="titulo-caption">Data:</span>
              <span>{dataHora}</span>
            </div>
            <div className="div-caption-valor">
              <span className="titulo-caption">Situação:</span>
              <span>{renderSituacaoItem(ped)}</span>
            </div>
          </div>

          <div className="linha" style={{ justifyContent: 'space-between' }}>
            <div className="div-caption-valor">
              <span className="titulo-caption">Nome:</span>
              <span>{ped.nomeCliente}</span>
            </div>
          </div>

          <div className="linha" style={{ justifyContent: 'space-between' }}>
            <div className="div-caption-valor">
              <span className="titulo-caption">Previsão:</span>
              <span>{previsao}</span>
            </div>
            <div className="div-caption-valor">
              <span className="titulo-caption">Valor:</span>
              <span style={{ textAlign: 'end' }}>{formatFloat(ped.valor, 2)}</span>
            </div>
          </div>
        </div>
      );
    });
  };

  function renderFooter() {
    return (
      <Container>
        <DivFooterDlg>
          <Button
            text="Retornar"
            style={{
              width: 120,
              background: theme.colors.backgroundCardapio,
              borderColor: theme.colors.primary,
              borderWidth: 1,
              color: theme.colors.primary,
            }}
            onClick={() => setShowDialog(false)}
          />
        </DivFooterDlg>
      </Container>
    );
  }

  return (
    <Container>
      <Dialog
        closable
        title="Meus Pedidos"
        footer={renderFooter()}
        visible={showDialog}
        style={{
          minWidth: '350px',
          maxWidth: '450px',
          width: '95%',
          maxHeight: '90vh',
        }}
        contentStyle={{ maxHeight: '70vh', overflowY: 'auto', overflowX: 'hidden' }}
        setVisible={setShowDialog}
      >
        <div style={{ margin: -15, padding: 0, height: 25 }} id="div-add-item" />
        <div className="p-grid body">
          <div className="p-col-12 p-fluid">
            {listaPedidos.length <= 0 ? (
              'Não existe pedidos'
            ) : (
              <>
                <span className="titulo">Últimos pedidos</span>
                <div className="lista">{renderPedidos(listaPedidos)}</div>
              </>
            )}
          </div>
        </div>
      </Dialog>
    </Container>
  );
}

export default MeusPedidos;

const corAguardando = '#E94C3D';
const corPreparando = '#ece936';
const corEntregando = '#ff854a';
const corFinalizado = '#00a629';
const corCancelado = '#404c51';
