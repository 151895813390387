import React from 'react';
import PropTypes from 'prop-types';

import { useTheme } from 'styled-components';
import { Wrapper } from './styles';
import DisplayBlock from '../../../components/DisplayBlock';

export default function PublicLayout({ children }) {
  const theme = useTheme();
  let corTheme = theme.colors.primary;

  if (window.location.pathname === '/') {
    corTheme = '#343a40';
  }

  return (
    <>
      <Wrapper>
        <meta name="theme-color" content={corTheme} />
        {/* <link rel="icon" type="image/x-icon" href="%PUBLIC_URL%/favicon3.ico" /> */}

        <DisplayBlock />
        <div>{children}</div>
      </Wrapper>
    </>
  );
}

PublicLayout.propTypes = {
  children: PropTypes.element.isRequired,
};
