/* eslint-disable react/jsx-no-target-blank */
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames';

import { Topbar } from '../components/TopBar';

import { Menu } from '../components/Menu';

import './css/layout.scss';
import './css/styles.scss';

import { store } from '../../../store';
import DisplayBlock from '../../../components/DisplayBlock';
import { isMobile, getDiferencaEmMinutos } from '../../../util/functions';
import { Profile } from '../components/Profile';
import AuthService from '../../../services/AuthService';
import logoWhite from '../../../assets/images/logo-white.png';
import logoBlack from '../../../assets/images/logo.png';

import { expiringToken, validToken } from '../../../store/modules/global/actions';
import history from '../../../services/history';

export function LayoutDefault(props) {
  let menuClick = false;

  // eslint-disable-next-line no-unused-vars
  let sidebar = null;

  const [menu] = useState(createMenu());
  const [layoutColorMode] = useState('dark');
  const [staticMenuInactive, setStaticMenuInactive] = useState(false);
  const [overlayMenuActive, setOverlayMenuActive] = useState(false);
  const [mobileMenuActive, setMobileMenuActive] = useState(false);
  const [globalTime, setGlobalTime] = useState(null);

  const layoutMenuMode = useSelector(state => {
    if (state.config.ativarMenuEstatico) return 'static';
    return 'overlay';
  });

  const paddingLeftIconLoading = useSelector(state => {
    if (state.config.ativarMenuEstatico && !staticMenuInactive) return 250;
    return 0;
  });

  function testToken() {
    const { exp } = AuthService.acessToken();
    const expDate = new Date(exp * 1000);

    const atualDate = new Date();

    const dif = getDiferencaEmMinutos(expDate, atualDate);
    if (dif < 5) {
      if (!store.getState().global.tokenExpiring) {
        store.dispatch(expiringToken());
      }
    } else if (store.getState().global.tokenExpiring) {
      store.dispatch(validToken());
    }
  }

  useEffect(() => {
    if (!globalTime) {
      testToken(); // executa a primeira vez
      setGlobalTime(
        setInterval(() => {
          testToken();
        }, 2000)
      );
    }
  }, [globalTime]);

  useEffect(() => {
    return () => {
      clearInterval(globalTime);
    };
  }, [globalTime]);

  useEffect(() => {
    if (mobileMenuActive) {
      addClass(document.body, 'body-overflow-hidden');
    } else removeClass(document.body, 'body-overflow-hidden');
  });

  function onWrapperClick(_event) {
    if (!menuClick) {
      setOverlayMenuActive(false);
      setMobileMenuActive(false);
    }

    menuClick = false;
  }

  function onToggleMenu(event) {
    menuClick = true;

    if (!isMobile()) {
      if (layoutMenuMode === 'overlay') {
        setOverlayMenuActive(!overlayMenuActive);
      } else if (layoutMenuMode === 'static') {
        setStaticMenuInactive(!staticMenuInactive);
      }
    } else {
      setMobileMenuActive(!mobileMenuActive);
    }

    event.preventDefault();
  }

  function onSidebarClick(_event) {
    menuClick = true;
  }

  function onMenuItemClick(event) {
    if (!event.item.items) {
      hiddeMenu();
    }
  }

  function hiddeMenu() {
    setOverlayMenuActive(false);
    setMobileMenuActive(false);
  }

  function createMenu() {
    const usuario = AuthService.getUsuario();
    const { tipoUsuario, empresa } = usuario;

    const menuReturn = [
      {
        label: 'Home',
        title: 'Home',
        icon: 'pi pi-fw pi-home',
        to: '/painel/home',
      },
    ];

    const menuConsultas = empresa
      ? {
          label: 'Lancamentos',
          title: 'Lancamentos',
          icon: 'pi pi-list',
          items: [
            {
              title: 'Pedidos',
              label: 'Pedidos',
              icon: 'pi pi-folder',
              to: '/painel/pedidos',
            },
          ],
        }
      : null;

    let menuCadastros = { items: [] };
    if (tipoUsuario === 2) {
      menuCadastros = null;
    } else {
      menuCadastros = {
        title: 'Cadastro',
        label: 'Cadastro',
        icon: 'pi pi-th-large',
        expanded: true,
        items: [],
      };

      if (empresa) {
        menuCadastros.items.push({
          title: 'Produto',
          label: 'Produto',
          icon: 'pi pi-briefcase',
          command: () => history.push('/painel/produtos'),
        });
        menuCadastros.items.push({
          title: 'Grupo de Produto',
          label: 'Grupo de Produto',
          icon: 'pi pi-briefcase',
          command: () => history.push('/painel/produtoGrupos'),
        });

        // menuCadastros.items.push({
        //   title: 'Adicional de Produto',
        //   label: 'Adicional de Produto',
        //   icon: 'pi pi-briefcase',
        //   command: () => history.push('/painel/produtoAdicionais'),
        // });
        // menuCadastros.items.push({
        //   title: 'Observação do Produto',
        //   label: 'Observação do Produto',
        //   icon: 'pi pi-briefcase',
        //   command: () => history.push('/painel/produtoObservacoes'),
        // });
      }
      menuCadastros.items.push({
        title: 'Empresa',
        label: 'Empresa',
        icon: 'pi pi-briefcase',
        command: () => history.push('/painel/empresas'),
      });
      if (tipoUsuario === 0) {
        menuCadastros.items.push({
          title: 'Usuário',
          label: 'Usuário',
          icon: 'pi pi-briefcase',
          command: () => history.push('/painel/usuarios'),
        });
      }
    }

    if (menuCadastros) {
      menuReturn.push(menuCadastros);
    }

    if (menuConsultas) {
      menuReturn.push(menuConsultas);
    }
    if (!isMobile()) {
      menuReturn.push({
        label: 'Configurações',
        icon: 'pi pi-fw pi-cog',
        to: '/painel/configuracoes',
      });
    }
    return menuReturn;
  }

  function addClass(element, className) {
    if (element.classList) element.classList.add(className);
    else element.className += ` ${className}`;
  }

  function removeClass(element, className) {
    if (element.classList) element.classList.remove(className);
    else
      element.className = element.className.replace(
        new RegExp(`(^|\\b)${className.split(' ').join('|')}(\\b|$)`, 'gi'),
        ' '
      );
  }

  // componentDidUpdate() {
  //   if (state.mobileMenuActive)
  //     addClass(document.body, 'body-overflow-hidden');
  //   else removeClass(document.body, 'body-overflow-hidden');
  // }

  const logo = layoutColorMode === 'dark' ? logoWhite : logoBlack;

  const wrapperClass = classNames('layout-wrapper', {
    'layout-overlay': layoutMenuMode === 'overlay',
    'layout-static': layoutMenuMode === 'static',
    'layout-static-sidebar-inactive': staticMenuInactive && layoutMenuMode === 'static',
    'layout-overlay-sidebar-active': overlayMenuActive && layoutMenuMode === 'overlay',
    'layout-mobile-sidebar-active': mobileMenuActive,
  });

  const sidebarClassName = classNames('layout-sidebar', {
    'layout-sidebar-dark': layoutColorMode === 'dark',
    'layout-sidebar-light': layoutColorMode === 'light',
  });

  return (
    <>
      <DisplayBlock paddingLeftIcon={paddingLeftIconLoading} />
      <div className={wrapperClass} onClick={onWrapperClick}>
        <Topbar onToggleMenu={onToggleMenu} />
        <div
          ref={el => {
            sidebar = el;
          }}
          className={sidebarClassName}
          onClick={onSidebarClick}
        >
          <div className="layout-logo">
            <img alt="Logo" src={logo} />

            <a
              style={{ color: '#fff', fontSize: 14, fontWeight: 'normal' }}
              href="http://www.speedysistemas.com.br"
              target="_blank"
            >
              www.speedysistemas.com.br
            </a>
          </div>
          <Profile hiddeMenu={() => hiddeMenu()} />
          <Menu model={menu} onMenuItemClick={onMenuItemClick} />
        </div>

        <div className="layout-main">{props.children}</div>

        <div className="layout-mask" />
      </div>
    </>
  );
}

export default LayoutDefault;
