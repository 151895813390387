import React from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';

import { useDispatch } from 'react-redux';
import AuthLayout from '../pages/_layouts/Auth';
import DefaultLayout from '../pages/_layouts/Default';
import AuthService from '../services/AuthService';
import ApiInfoService from '../services/ApiInfoService';
import PublicLayout from '../pages/_layouts/Public';
import { clearToken } from '../store/modules/auth/actions';

export default function RouterWrapper({
  component: Component,
  roles,
  isPrivate,
  isPublic,
  ...rest
}) {
  if (!roles) {
    roles = [];
  }
  // se isPrivate nao tiver definida e roles existir, então define private true
  if (!isPrivate && roles.length > 0) {
    isPrivate = true;
  }
  const dispatch = useDispatch();

  checkVersaoApi(rest.path);
  let Layout = PublicLayout; // padrão

  if (!isPublic && rest.path.includes('/painel')) {
    let { signed } = AuthService.acessToken();

    if (rest.path === '/painel/login') {
      signed = false;
    }

    // se for diferente de /login e nao tiver logado vai para login
    if (!signed && rest.path !== '/painel/login') {
      return <Redirect to="/painel/login" />;
    }

    if (!signed && isPrivate) {
      return <Redirect to="/painel/login" />;
    }

    if (signed && !isPrivate) {
      return <Redirect to="/painel/home" />;
    }

    if (roles.length > 0 && !AuthService.checkRoles(roles)) {
      return <Redirect to="/painel/denied" />;
    }
    Layout = signed ? DefaultLayout : AuthLayout;
  } else {
    // Limpa o token
    dispatch(clearToken());
  }

  return (
    <Route
      {...rest}
      render={props => (
        <Layout>
          <Component {...props} />
        </Layout>
      )}
    />
  );
}

async function checkVersaoApi(path) {
  // verificando versao api

  if (!(await ApiInfoService.checkVersaoApi())) {
    if (path.includes('painel')) {
      if (path !== '/painel/login') {
        window.location = '/painel/login';
      }
    } else {
      window.location.reload();
    }
  }
}

RouterWrapper.propTypes = {
  isPrivate: PropTypes.bool,
  isPublic: PropTypes.bool,
  component: PropTypes.oneOfType([PropTypes.element, PropTypes.func]).isRequired,
};

RouterWrapper.defaultProps = {
  isPrivate: false,
  isPublic: false,
};
