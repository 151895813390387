import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { ListBox } from 'primereact/listbox';

import { Container } from './styles';
import { store } from '../../store';
import { hiddenDialogClienteLogin } from '../../store/modules/dialog/actions';

import { loginRequest } from '../../store/modules/auth/actions';

import LabelSp from '../LabelSp';

export default function MessageDialogLogin() {
  const { dispatch } = store;
  const { loginClienteVisible, email, senha, arrayClientes } = useSelector(state => {
    return state.dialog.config;
  });
  let listaClientes = [];
  if (arrayClientes) {
    listaClientes = arrayClientes.map(e => {
      return {
        label: e.razaoSocial,
        value: e.id,
      };
    });
  }

  const [idCliente, setIdCliente] = useState();

  function hiddenMessage() {
    dispatch(hiddenDialogClienteLogin());
  }

  function handleSubmit() {
    dispatch(loginRequest(email, senha, '/home', idCliente));
  }

  const footer = (
    <div>
      <Button
        label="Ok"
        icon="pi pi-check"
        className="p-button-success"
        type="submit"
        disabled={!idCliente}
        onClick={() => {
          handleSubmit();
        }}
      />
      <Button
        label="Sair"
        icon="pi pi-times"
        onClick={() => {
          hiddenMessage();
        }}
        className="p-button-danger"
      />
    </div>
  );
  return (
    <Container>
      <Dialog
        closable={false}
        footer={footer}
        header="Login"
        visible={loginClienteVisible}
        style={{ minWidth: '250px', maxWidth: '400px', width: '95%' }}
        modal
        onHide={() => {
          hiddenMessage();
        }}
      >
        <form onSubmit={handleSubmit}>
          <div className="p-grid p-fluid">
            <div className="p-col-12">
              <LabelSp>Selecione o Cliente</LabelSp>
            </div>
            <div className="p-col-12">
              <ListBox
                value={idCliente}
                options={listaClientes}
                listStyle={{ maxHeight: '150px' }}
                onChange={e => setIdCliente(e.value)}
              />
            </div>
          </div>
        </form>
      </Dialog>
    </Container>
  );
}
