import styled from 'styled-components';

export const Container = styled.div`
  margin: 0;
  padding: 0;

  .logo {
    margin: 4px;
    height: 88px;
    width: 88px;
    border-radius: 50%;
    border: #ccc 1px solid;
  }
  .div-logo {
    display: flex;
    border: #0002 1px solid;
    border-radius: 4px;
    margin-top: -3px;
    justify-content: center;
  }
  .clicavel {
    &:hover,
    &:focus {
      cursor: pointer;
    }
  }
`;

export const DivTema = styled.div`
  .p-dropdown-label {
    background-color: ${props => props.temaColor} !important;
    color: ${props => props.temaColor} !important;
  }
`;

export const DivFooterDlg = styled.div`
  display: flex;
  justify-content: center;
  padding: 0px;
  height: 35px;

  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
`;

export const ContainerImage = styled.div`
  border-width: 1px;
  border-style: solid;

  height: 250px;
  width: 100%;

  border-radius: 20px;

  img {
    border-radius: ${({ theme }) => theme.borderRadius};
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;
