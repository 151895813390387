import { lighten } from 'polished';
import styled from 'styled-components';

export const Container = styled.div`
  padding: 2px 10px 0px 15px;



  h1 {
    font-family: ${({ theme }) => theme.fonts.title};
    color: ${({ theme }) => theme.colors.subtitle};
    /* font-weight: bold; */
  }

  @media screen and (max-width: 800px) {
    padding: 2px 2px 2px 5px;
    h1 {
      margin-left: 6px;
    }
  }

  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  .container-body {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;

    button {
      border-width: 0;
      font-size: 30px;
      color: ${props => props.theme.colors.primary};
      background-color: ${props => props.theme.colors.backgroundCardapio};
      margin: 0;
      padding: 0;

      :hover {
        color: ${props => lighten(0.05, props.theme.colors.primary)};
      }
    }
  }

  .container-lista {
    flex: 1;
    overflow-x: hidden;
    display: flex;
    flex-flow: row nowrap;
    scroll-behavior: smooth;
  }

  .container-item {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    margin: 2px;

    min-width: ${({ theme }) => `${theme.minHeightCategoria}px`};
    min-height: ${({ theme }) => `${theme.minHeightCategoria}px`};
    max-height: ${({ theme }) => `${theme.minHeightCategoria}px`};

    border-radius: ${({ theme }) => theme.borderRadius};
    border-width: 1px;
    border-style: solid;
    border-color: ${({ theme }) => lighten(0.2, theme.colors.backgroundRodape)};

    background-color: ${props => props.theme.colors.backgroundCard};

    :hover {
      cursor: pointer;
      opacity: 0.75;
    }

    .div-categoria-nome {
      display: flex;
      font-size: 15px;
      font-family: ${props => props.theme.fonts.description};
      font-weight: normal;


      margin-left: 5px;
      margin-right: 5px;

      span {
        flex:1;
        text-align: center;

        overflow: hidden;
        white-space: nowrap;
       text-overflow: ellipsis;
        /* max-width: ${({ theme }) => `${theme.minHeightImgCategoriaMob - 12}px`}; */
      }
    }
    .item-background-top {
      border-top-left-radius: ${({ theme }) => theme.borderRadius};
      border-top-right-radius: ${({ theme }) => theme.borderRadius};
      height: ${({ theme }) => `${theme.minHeightCategoria / 2}px`};
    }

    .container-image-item {
      margin-top: -50%;
      align-self: center;
      height: ${({ theme }) => `${theme.minHeightImgCategoria}px`};
    }

    .image-item {
      margin-top: 10px;
      margin-bottom: 2px;
      height: ${({ theme }) => `${theme.minHeightImgCategoria - 7}px`};
      width: ${({ theme }) => `${theme.minHeightImgCategoria}px`};

      object-fit: cover;
      border-radius: ${({ theme }) => theme.borderRadius};
      text-align: center;
    }

    @media screen and (max-width: 800px) {
      min-width: ${({ theme }) => `${theme.minHeightCategoriaMob}px`};
      min-height: ${({ theme }) => `${theme.minHeightCategoriaMob}px`};
      max-height: ${({ theme }) => `${theme.minHeightCategoriaMob}px`};

      .container-image-item {
        height: ${({ theme }) => `${theme.minHeightImgCategoriaMob}px`};
      }

      .image-item {
        margin-top: 5px;
        height: ${({ theme }) => `${theme.minHeightImgCategoriaMob - 7}px`};
        width: ${({ theme }) => `${theme.minHeightImgCategoriaMob}px`};
      }

      .div-categoria-nome {
        font-size: 10px;
      }

      .item-background-top {
        height: ${({ theme }) => `${theme.minHeightCategoriaMob / 2}px`};
      }
    }
  }
`;
