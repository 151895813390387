import React from 'react';

import PropTypes from 'prop-types';
import { ButtonContainer } from './styles';

/**
 * @typedef ButtonProps
 * @property {String} icon pedido
 * @property {String} text Texto do button
 * @property {React.CSSProperties} style style do componente
 * @property {React.CSSProperties} styleIcon style do icone
 * @property {'left' | 'rigth'} iconPosition posição do item. Padrão left
 */

/**
 * @func Button
 * @param {ButtonProps} props
 */
function Button(props) {
  Button.propTypes = {
    icon: PropTypes.any,
    text: PropTypes.string,
    style: PropTypes.any,
    styleIcon: PropTypes.any,
    iconPosition: PropTypes.string,
  };

  const { icon, text, style, styleIcon, iconPosition, ...rest } = props;
  return (
    <ButtonContainer className="button" type="button" value="" style={style} {...rest}>
      {icon && iconPosition !== 'rigth' && <img src={icon} alt={text} style={styleIcon} />}
      {text}
      {icon && iconPosition === 'rigth' && <img src={icon} alt={text} style={styleIcon} />}
    </ButtonContainer>
  );
}

export default Button;
