/* eslint-disable react/void-dom-elements-no-children */
import React from 'react';
import PropTypes from 'prop-types';

import { useTheme } from 'styled-components';
import { Container } from './styles';
// eslint-disable-next-line no-unused-vars
import { PedidoCabecalhoModel } from '../../../../util/Models';
import inicioSacola from '../../../../assets/cardapio/icons/meus-pedidos_menu-cardapio.png';
import sacolaBotaoEditar from '../../../../assets/cardapio/icons/sacola-botao-editar.png';
import sacolaBotaoRemover from '../../../../assets/cardapio/icons/sacola-botao-remover.png';

import finalizarSacola from '../../../../assets/cardapio/icons/sacola-finalizar-pedido.png';

import { formatFloat } from '../../../../util/functions';
import Button from '../Button';
import { showMessage } from '../../../../components/MessageDialog';

/**
 * @typedef SacolaProps
 * @property {PedidoCabecalhoModel} pedido pedido
 * @property {function} setPedido Handle para alterar o pedido
 * @property {function} removeItem Handle para remover item
 * @property {function} editaItem Handle para alterar item
 * @property {function} handleFinalizar Handle para finalizar venda
 * @property {function} validaFinalizacaoPedido Handle para validar venda
 */

/**
 * @func Sacola
 * @param {SacolaProps} props
 */
function Sacola(props) {
  Sacola.propTypes = {
    pedido: PropTypes.object,
    removeItem: PropTypes.func,
    editaItem: PropTypes.func,
    handleFinalizar: PropTypes.func,
    doClose: PropTypes.func,
  };

  const { pedido, removeItem, editaItem, handleFinalizar, doClose } = props;
  const theme = useTheme();

  function renderItens() {
    return pedido.pedidoDetalhe.map((rowData, idx) => {
      const listaAdicionais = rowData.pedidoDetalhe;
      const respostasProd = [];
      const respostasObs = [];

      if (Array.isArray(rowData.perguntas)) {
        rowData.perguntas.forEach(p => {
          if (Array.isArray(p.respostas)) {
            p.respostas.forEach(r => {
              if (r.marcado && r.produto) {
                r.key = `${rowData.numeroItem}-${rowData.idProduto}-perg-${p.id}resp-${r.id}`;
                respostasProd.push(r);
              }
            });
          }
        });
      }

      if (Array.isArray(rowData.perguntas)) {
        rowData.perguntas.forEach(p => {
          if (Array.isArray(p.respostas)) {
            p.respostas.forEach(r => {
              if (r.marcado && r.observacao) {
                r.key = `${rowData.numeroItem}-${rowData.idProduto}-perg-${p.id}resp-${r.id}`;
                respostasObs.push(r);
              }
            });
          }
        });
      }

      const descricao = rowData.descricao.substring(0, 60);

      let qtdCasasDecimais = 0;

      if (rowData.quantidade === Math.round(rowData.quantidade)) {
        qtdCasasDecimais = 0;
      } else {
        qtdCasasDecimais = 3;
      }

      return (
        <div className="card-item" key={rowData.numeroItem}>
          <div className="div-descricao-valor">
            <div>
              <span className="quantidade">
                {formatFloat(rowData.quantidade, qtdCasasDecimais)}x{' '}
              </span>
              <span className="descricao">{descricao}</span>
            </div>
            <span className="valor-subtotal">R${formatFloat(rowData.valorTotal, 2)}</span>
          </div>
          <div className="div-observacao-imagem">
            <div style={{ flex: 1 }}>
              {listaAdicionais
                ? listaAdicionais.map(a => {
                    return (
                      <div
                        key={`add-${rowData.numeroItem}-${a.idProduto}`}
                        style={{ marginLeft: 10 }}
                      >{`+${a.quantidade}${a.unidade} x ${a.descricao}`}</div>
                    );
                  })
                : null}

              {respostasProd
                ? respostasProd.map(r => {
                    let quantidadeDescricao = '';
                    if (r.produto) {
                      quantidadeDescricao = `+${r.quantidadeResposta}${r.produto.produtoUnidade.sigla} x ${r.produto.descricao}`;
                    } else {
                      quantidadeDescricao = `*${r.observacao.descricao}`;
                    }

                    return (
                      <div key={`${r.key}`} style={{ marginLeft: 10 }}>
                        {quantidadeDescricao}
                      </div>
                    );
                  })
                : null}

              {rowData.pedidoDetalheObs.map(o => {
                return (
                  <div
                    key={`obs-${rowData.numeroItem}-${o.idObservacaoProduto}`}
                    style={{ marginLeft: 10 }}
                  >{`*${o.observacaoProduto.descricao}`}</div>
                );
              })}

              {respostasObs
                ? respostasObs.map(r => {
                    let quantidadeDescricao = '';
                    if (r.produto) {
                      quantidadeDescricao = `+${r.quantidadeResposta}${r.produto.produtoUnidade.sigla} x ${r.produto.descricao}`;
                    } else {
                      quantidadeDescricao = `*${r.observacao.descricao}`;
                    }

                    return (
                      <div key={`${r.key}`} style={{ marginLeft: 10 }}>
                        {quantidadeDescricao}
                      </div>
                    );
                  })
                : null}

              {rowData.observacao ? (
                <div style={{ marginLeft: 10 }}>{`*${rowData.observacao}`}</div>
              ) : null}
            </div>
            <div>
              {rowData.urlImagem ? (
                <img
                  className="image-item"
                  src={rowData.urlImagem}
                  onError={event => {
                    event.target.style = 'display: none';
                  }}
                  alt="produto"
                />
              ) : null}
            </div>
          </div>

          <div className="rodape">
            <Button
              icon={sacolaBotaoEditar}
              text="Editar"
              className="buttons"
              onClick={() => {
                editaItem(idx);
              }}
            />

            <Button
              icon={sacolaBotaoRemover}
              text="Remover"
              className="buttons"
              onClick={() => {
                showMessage('Confirmação', 'Deseja remover o item?', idxResp => {
                  if (idxResp === 1) {
                    removeItem(idx);
                  }
                });
              }}
            />
          </div>
        </div>
      );
    });
  }

  const valorEntrega =
    pedido.pedidoEntrega.retiradaDelivery === 0 ? pedido.valorEntrega || 0 : 0;

  return (
    <Container>
      <div className="header">
        <div className="qtd-itens">
          <img src={inicioSacola} alt="sacola" />
          <span>{pedido.quantidadeItens} Itens</span>
        </div>
        <span className="valor">R$ {formatFloat(pedido.valor, 2)}</span>
      </div>
      <div className="body">
        <span className="title-body">Detalhes do Pedido</span>
        {renderItens()}
      </div>
      <div className="footer">
        <hr />
        <div className="div-valores">
          <span className="valor">Subtotal</span>
          <span className="valor">R$ {formatFloat(pedido.valorSubtotal, 2)}</span>
        </div>
        <div className="div-valores">
          <span className="valor"> Taxa de Entrega</span>
          {valorEntrega ? (
            <span className="valor">R$ {formatFloat(valorEntrega, 2)}</span>
          ) : (
            <span className="valor">A definir</span>
          )}
        </div>
        <div className="div-valores">
          <span className="valor-total">Total</span>
          <span className="valor-total">R$ {formatFloat(pedido.valor + valorEntrega, 2)}</span>
        </div>
        <hr />

        <div style={{ display: 'flex' }}>
          <Button
            className="botao-voltar"
            text="VOLTAR"
            style={{
              height: 35,
              background: theme.colors.backgroundCardapio,
              color: theme.colors.primary,
              borderColor: theme.colors.primary,
              borderWidth: 1,
              flex: 1,
            }}
            onClick={() => {
              if (doClose) {
                doClose();
              }
            }}
          />

          <Button
            text="FINALIZAR"
            icon={finalizarSacola}
            style={{ height: 35, flex: 1 }}
            onClick={() => {
              if (handleFinalizar) {
                handleFinalizar();
              }
            }}
          />
        </div>
      </div>
    </Container>
  );
}

export default Sacola;
