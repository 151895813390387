/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable import/no-dynamic-require */
/* eslint-disable global-require */
/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import NavBar from '../components/NavBar';
import logo from '../../../assets/images/logo-white-full.png';
import imagemFood from '../../../assets/images/imagem-food-pequena.jpg';
import imageFoodMobile from '../../../assets/images/imagem-food-pequena-mobile.jpg';

import { Container, BotaoSuporte } from './styles';
import {
  errorHandle,
  formatTelefone,
  formatCelular,
  goToMarcador,
  configuraTitleDescription,
} from '../../../util/functions';
import EmpresaService from '../../../services/EmpresaService';
import Rodape from '../components/Rodape';
import imageWhatsapp from '../../../assets/images/whatsapp-icon.svg';
// import history from '../../../services/history';
import { ConfigApi } from '../../../config/Constantes';

export default function Principal() {
  const [listaClientes, setListaCliente] = useState([]);
  const [imagemComida, setImagemComida] = useState(imagemFood);

  const menu = [
    { id: 1, nome: 'Sistema', nomeCompleto: 'Sistema', marcador: 'sistema' },
    { id: 3, nome: 'Clientes', nomeCompleto: 'Clientes', marcador: 'clientes' },
    { id: 2, nome: 'Revendas', nomeCompleto: 'Revendas', marcador: 'revendas' },
  ];

  useEffect(() => {
    carregaEmpesas();
    configuraTitleDescription('Cardápio Online');
    // navigator.geolocation.getCurrentPosition(location => {
    //   // console.log(location);
    // });
  }, []);

  useEffect(() => {
    function handleResize() {
      if (window.innerWidth < 800) {
        setImagemComida(imageFoodMobile);
      } else {
        setImagemComida(imagemFood);
      }
    }

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  });

  async function carregaEmpesas() {
    try {
      const empresas = await EmpresaService.findAll({ inativo: 'false' });
      setListaCliente(empresas.items);
    } catch (err) {
      errorHandle(err);
    }
  }

  function renderListaClientes() {
    const lista = listaClientes.map(c => {
      // let logoCliente = require(`../../../assets/images/profile.png`);
      // try {
      //   logoCliente = require(`../../../assets/images/logo-${c.numeroContrato}.jpg`);
      // } catch (err) {
      //   //
      // }

      const logoCliente = `${ConfigApi.baseURL}/files/${c.fileUri}`;

      return (
        <div
          key={c.id}
          className="p-col-12 p-sm-6 p-lg-6 p-xl-4 p-fluid"
          onClick={() => {
            if (c.inativo) {
              toast.warn('Empresa está desativada.', { autoClose: 2000 });
              return;
            }
            window.location = `/${c.identificadorHtml}`;
            // history.push(`/${c.identificadorHtml}`);
          }}
        >
          <div className="item-lista-clientes">
            <div className="img-cliente">
              <img
                onError={e => {
                  e.currentTarget.style = 'display: none';
                }}
                src={logoCliente}
                alt="logo"
              />
            </div>
            <div className="info-cliente">
              <strong className="razao-social">{c.fantasia}</strong>

              <span className="endereco">
                {c.logradouro}, {c.numero} - {c.bairro}
              </span>
              <span className="endereco">
                {c.nomeMunicipio} - {c.uf}
              </span>
              <span>
                {formatTelefone(c.telefone)} / {formatCelular(c.celular)}
              </span>
            </div>
          </div>
        </div>
      );
    });
    return lista;
  }

  return (
    <>
      <div id="div-topo" />
      <NavBar
        logo={logo}
        menu={menu}
        title="Cardápio Online"
        // handleSearch={n => {}}
      />

      <Container>
        <img className="imagem-food" src={imagemComida} alt="imagem de comida" />
        <div id="sistema" style={{ marginTop: -40, height: 40 }} />
        <div className="geral">
          <div
            className="p-grid paddign-global"
            style={{ margin: 0, minHeight: 200, display: 'flex', alignItems: 'center' }}
          >
            <div className="p-col-12 p-sm-12 p-lg-12 p-fluid geral-item">
              {/* <div className="titulo-item-geral">Cardápio Online</div> */}
              <div className="corpo-item-geral">
                Nossos sistemas são desenvolvidos por profissionais qualificados, com ampla
                experiência em gestão. A solução <strong>Cardápio Online</strong> atende
                pizzarias, hamburguerias, restaurantes, bares, lanchonetes e similares.
                <a
                  title="Entre em contato com uma de nossas revendas"
                  className="botao-assinar"
                  style={{ display: 'block', width: '9em', marginTop: 10 }}
                  onClick={() => goToMarcador('revendas')}
                >
                  Seja um cliente
                </a>
              </div>
            </div>
          </div>
        </div>
        <div id="clientes" style={{ marginTop: -40, height: 40 }} />
        <div className="paddign-global lista-clientes">
          <div className="p-col-12 p-fluid titulo-lista-clientes">
            Cardápio dos nossos clientes
          </div>
          <div className="p-grid p-lg-12 p-fluid items-lista-clientes">
            {renderListaClientes()}
          </div>
        </div>
        <div className="geral">
          <div className="p-grid paddign-global" style={{ margin: 0 }}>
            <div
              className="p-col-12 p-sm-12 p-lg-12 p-fluid geral-item geral-ultimo-item"
              style={{ paddingLeft: 5 }}
            >
              <div
                id="revendas"
                className="titulo-item-geral p-col-12 p-fluid"
                style={{ textAlign: 'center' }}
              >
                Revendedores
              </div>
              <div
                className="p-grid p-lg-12 p-fluid corpo-item-geral"
                style={{ textAlign: 'center' }}
              >
                <div className="p-col-6 p-lg-3 p-fluid">
                  <strong>Montes Claros</strong>
                  <ul>
                    <li>(38) 3016-0687</li>
                    <li>(38) 99976-0687</li>
                  </ul>
                </div>
                <div className="p-col-6 p-lg-3 p-fluid">
                  <strong>Janaúba</strong>
                  <ul>
                    <li>(38) 98802-0243</li>
                  </ul>
                </div>
                <div className="p-col-6 p-lg-3 fluid">
                  <strong>Mato Verde</strong>
                  <ul>
                    <li>(38) 99954-3378</li>
                  </ul>
                </div>
                <div className="p-col-6 p-lg-3 p-fluid">
                  <strong>Januária</strong>
                  <ul>
                    <li>(38) 99880-2161</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <a
            title="Acesse o Painel"
            className="botao-assinar"
            style={{
              display: 'block',
              width: '9em',
              lineHeight: 2,
              marginTop: 10,
              marginBottom: 10,
            }}
            onClick={() => (window.location = '/painel/login')}
          >
            Acesse o Painel
          </a>
        </div>
        <Rodape />
      </Container>
      <BotaoSuporte title="Suporte">
        <a
          itle="Abrir Whatsapp"
          className="box-whatsapp"
          href="https://api.whatsapp.com/send?phone=553830160687&text=Olá Speedy Sistemas. Eu gostaria de um atendimento"
          target="_blank"
        >
          <img width="38" height="38" src={imageWhatsapp} alt="suporte" />
        </a>
      </BotaoSuporte>
    </>
  );
}
