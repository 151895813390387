import React from 'react';
import { useSelector } from 'react-redux';

import { store } from '../../store';
import { showDialog, hiddenDialog } from '../../store/modules/dialog/actions';
import DialogPanel from './dialog';
import DialogCardapio from './dialogCardapio';

export default function MessageDialog() {
  const { visible, title, body, handleClose, yesNoButton } = useSelector(state => {
    return state.dialog.config;
  });

  function hiddenMessage() {
    const { dispatch } = store;
    dispatch(hiddenDialog());
  }
  if (window.location.toString().includes('/painel/')) {
    return (
      <DialogPanel
        visible={visible}
        title={title}
        body={body}
        handleClose={handleClose}
        yesNoButton={yesNoButton}
        hiddenMessage={hiddenMessage}
      />
    );
  }
  return (
    <DialogCardapio
      visible={visible}
      title={title}
      body={body}
      handleClose={handleClose}
      yesNoButton={yesNoButton}
      hiddenMessage={hiddenMessage}
    />
  );
}

export function showMessage(title, header, handleClose, yesNoButton = true) {
  const { dispatch } = store;
  dispatch(showDialog(title, header, handleClose, yesNoButton));
}
