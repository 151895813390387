import styled from 'styled-components';

export const Container = styled.div`
  margin: 0;
  padding: 0;

  .legenda-consulta {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .legenda {
    display: flex;
    align-items: center;

    div {
      text-align: center;
      margin: 2px;
      width: 16px;
      height: 16px;
      font-size: 10px;
      border-radius: 50%;
      display: inline-block;
      color: #ffffff00;
    }
    span {
      margin-right: 5px;
    }
  }

  @media screen and (max-width: 640px) {
    .legenda-consulta {
      justify-content: flex-end;
    }
  }
`;
