import produce from 'immer';

const INICIAL_STATE = {
  config: {
    visible: false,
    title: '',
    body: '',
    handleClose: null,
    loginVisible: false,
  },
};

export default function dialog(state = INICIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case '@dialog/SHOW': {
        draft.config = action.config;
        break;
      }
      case '@dialog/HIDDEN': {
        draft.config = action.config;
        break;
      }

      case '@dialog/SHOW_LOGIN': {
        draft.config = action.config;
        break;
      }
      case '@dialog/HIDDEN_LOGIN': {
        draft.config = action.config;
        break;
      }

      case '@dialog/SHOW_LOGIN_CLIENTE': {
        draft.config = action.config;
        break;
      }
      case '@dialog/HIDDEN_LOGIN_CLIENTE': {
        draft.config = action.config;
        break;
      }

      default:
    }
  });
}
