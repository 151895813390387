import styled from 'styled-components';

export const Body = styled.div`
  justify-content: center;
  display: flex;
  background-color: ${({ theme }) => theme.colors.backgroundCardapio};
`;
export const Layout = styled.div`
  width: 95%;
  display: grid;
  grid-gap: 0.2rem;
  grid-template-areas: 'main main aside';
  grid-template-columns: auto auto ${({ permitePedidoOnline }) =>
      permitePedidoOnline ? '320px' : '0px'};

  grid-template-rows: auto;

  .o-aside {
    grid-area: aside;
    min-width: 320px;
    display: ${({ permitePedidoOnline }) => (permitePedidoOnline ? '' : 'none')};
  }

  .o-main {
    grid-area: main;
    min-height: calc(
      100vh - ${({ theme }) => `${theme.heightCabecalho + theme.heightRodapeMob}px`}
    );
    min-width: calc(95% - 320px);
    padding-bottom: ${({ theme }) => `${theme.heightRodape}px`};
  }

  @media screen and (max-width: 800px) {
    width: 99%;
    grid-template-columns: auto auto 0px;
    .o-aside {
      display: none;
    }
  }

  @media screen and (min-width: 1365px) {
    padding-right: calc((100% - 1365px) / 2);
    padding-left: calc((100% - 1365px) / 2);
  }
`;

export const ConfirmacaoPedido = styled.div`
  padding: 60px 30px 60px 30px;
  font-size: 18px;
  font-family: ${({ theme }) => theme.fonts.regular};
  min-height: 500px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .img {
    width: 60px;
    height: 60px;
    margin: 10px;
  }

  .msg-sucesso {
    font-family: ${({ theme }) => theme.fonts.title};
  }

  .msg-info {
    font-family: ${({ theme }) => theme.fonts.description};
    text-align: center;
    font-size: 13px;

    padding: 10px 25px 10px 25px;
  }

  .div-botoes {
    width: 100%;
    margin-top: 10px;
    padding: 30px 20px 30px 20px;

    display: flex;
    flex-direction: column;
    border-width: 1px;
    border-style: solid;
    border-radius: ${({ theme }) => theme.borderRadiusSmall};
    border-color: ${({ theme }) => theme.colors.borderColor};
  }
`;
