import React, { useRef } from 'react';
import PropTypes from 'prop-types';

import { useTheme } from 'styled-components';

import { Container } from './styles';
import { ConfigApi } from '../../../../config/Constantes';

import categoriaIcone from '../../../../assets/cardapio/icons/no-image.png';
import { capitalizeStr, goToMarcador } from '../../../../util/functions';

function Categorias(props) {
  Container.propTypes = {
    numeroContrato: PropTypes.string,
    grupos: PropTypes.arrayOf(PropTypes.object),
    onExpandGrupo: PropTypes.func,
  };

  Container.defaultProps = {
    expandido: false,
    numeroContrato: '',
  };

  const listRef = useRef();
  const theme = useTheme();

  const { grupos, numeroContrato, onExpandGrupo } = props;

  function moveScroll(value) {
    if (listRef) {
      listRef.current.scrollLeft += value;
    }
  }

  // render principal
  return (
    <Container>
      <h1>Categorias</h1>
      <div className="container-body">
        <button
          type="button"
          className="pi pi-chevron-circle-left"
          onClick={() => moveScroll(-125 * 2)}
        />
        <div ref={listRef} className="container-lista">
          {renderCategorias()}
        </div>
        <button
          type="button"
          className="pi pi-chevron-circle-right"
          onClick={() => moveScroll(125 * 2)}
        />
      </div>
    </Container>
  );

  function renderCategorias() {
    return grupos.map((gp, idx) => {
      let iconeCategoria = categoriaIcone;
      if (gp.exibirImagem) {
        iconeCategoria = `${ConfigApi.baseURL}/files/${numeroContrato}/g${gp.id}.jpg`;
      }

      const nomeCategoria = capitalizeStr(gp.nome);
      return (
        <div
          key={`gp_id_${gp.id}`}
          className="container-item"
          onClick={() => {
            goToMarcador(gp.marcador);
            if (onExpandGrupo) {
              onExpandGrupo(gp.id);
            }
          }}
          title={nomeCategoria}
        >
          <div className="item-background-top" style={{ backgroundColor: findColor(idx) }} />
          <div className="container-image-item">
            <img
              className="image-item"
              style={{ opacity: gp.exibirImagem ? 1 : 0.0 }}
              src={iconeCategoria}
              onError={event => {
                // event.target.style = 'display: none';
                event.target.style = 'opacity: 0.0';
                event.target.src = categoriaIcone;
              }}
              alt="categoria"
            />
          </div>
          <div className="div-categoria-nome">
            <span>{nomeCategoria}</span>
          </div>
        </div>
      );
    });
  }

  function findColor(idx) {
    let index = 0;
    if (idx > theme.colors.categorias.length - 1) {
      index = idx % theme.colors.categorias.length;
    } else {
      index = idx;
    }

    return theme.colors.categorias[index];
  }
}

export default Categorias;
