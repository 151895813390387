import styled from 'styled-components';
import { Sidebar } from 'primereact/sidebar';

export const MenuSideBar = styled(Sidebar)`
  .titulo-menu {
    display: inline-block;

    margin-left: 4px;
    width: 15em;
    line-height: 3;
    font-size: 18px;
    color: #fff;
    text-align: center;
    border-bottom: #feee 1px solid;
    /* background: #343a40; */
    background: #19a890;
    font-weight: bold;
    border-radius: 3px;
  }

  .menu-item-pedidos {
    margin: 3px;
    width: 15em;
    padding: 0px;

    a {
      margin-left: 1px;
      text-align: center;
      display: inline-block;
      width: 15em;
      line-height: 3;
      background: #007ad9;
      font-size: 18px;
      color: #fff;
      font-weight: bold;
      border-radius: 3px;

      border-bottom: #a1a1a1 1px solid;
      &:hover,
      &:focus {
        background: #1586dd;
      }
    }
  }

  .menu {
    .menu-item {
      margin: 3px;
      width: 15em;
      padding: 0px;

      a {
        text-align: center;
        display: inline-block;
        width: 15em;
        margin-left: 2px;
        padding-right: 20px;
        line-height: 3;
        background: #f0f0f5;
        padding-left: 5px;
        font-size: 18px;
        color: #19a890;
        font-weight: bold;

        border-bottom: #a1a1a1 1px solid;
        &:hover,
        &:focus {
          background: #e6e6eb;
        }
      }
    }
  }
`;

export const Container = styled.div`
  z-index: 100;
  position: fixed;
  top: 0;
  width: 100%;
  color: #fff;
`;

export const Nav = styled.nav`
  background: #343a40;
  margin-bottom: 0px;
  border-width: 0 0 1px;
  display: flex;
  flex: 1;
  justify-content: space-between;

  height: 50px;
  font-size: 18px;

  .logo {
    img {
      margin-top: 6px;
      vertical-align: top;
      height: 40px;
    }
  }

  .titulo {
    width: 100%;

    display: flex;
    flex: 1;
    align-content: center;
    font-size: 22px;
    font-weight: bold;
  }

  .nav-bar-rigth {
    display: flex;

    justify-content: flex-end;
    /* justify-content: space-around; */
  }

  .div-input-busca {
    margin-top: 8px;
    display: flex;

    input {
      vertical-align: top;
      margin-right: 0px;
      border: #19a890 1px solid;
      padding-right: 10px;
      padding-left: 10px;
      height: 35px;
      min-width: 100px;
      width: 100%;

      margin-left: 3px;
      border-top-left-radius: 3px;
      border-bottom-left-radius: 3px;

      @media screen and (max-width: 50em) {
        margin-right: 6px;
        border-radius: 3px;
      }
    }

    button {
      padding-top: 4px;
      vertical-align: top;
      padding-left: 8px;
      padding-right: 8px;
      background: #19a890;
      border: #19a890 1px solid;
      color: white;
      height: 35px;
      font-size: 18px;
      border-top-right-radius: 3px;
      border-bottom-right-radius: 3px;
      border-left: 0;

      /* border-left: none;  */
      cursor: pointer;

      &:hover {
        background: #17b399;
      }

      @media screen and (max-width: 50em) {
        display: none;
      }
    }

    .botao-pedidos {
      background: #007ad9;
      border: #007ad9 1px solid;
      cursor: pointer;
      border-radius: 3px;

      &:hover,
      &:focus {
        background: #1586dd;
      }

      @media screen and (max-width: 50em) {
        display: inline;
      }
    }
  }

  .botao-right {
    margin-top: 8px;
    margin-left: 1px;
    font-size: 33px;

    border: #fff 1px solid;
    color: #fff;
    border-radius: 4px;
    background: #17b399;
    align-items: center;

    &:hover,
    &:focus {
      background: #1aad94;
    }
  }

  @media screen and (min-width: 50em) {
    .botao-right {
      display: none;
    }
    .titulo {
      position: absolute;
      left: 0;
      top: 10px;
    }
  }
  @media screen and (max-width: 50em) {
    .titulo {
      font-size: 19px;
    }
    .menu {
      display: none;
    }
  }
`;

export const MenuBar = styled.div`
  background: #343a40;
  padding-top: 50px;
  .menu {
    word-break: break-word; /* Chrome, Safari */
    overflow-wrap: anywhere; /* Firefox */
    text-align: center;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;

    padding-left: 2px;
    padding-right: 2px;
    .menu-item {
      margin-top: 2px;
      margin-bottom: 2px;
      text-align: center;
      width: 120px;
      margin-right: 2px;
      padding: 0px;

      a {
        display: inline-block;
        margin: 0;
        width: 120px;
        line-height: 2;
        background: linear-gradient(00deg, #343a40, #424549);
        border: #949494 1px solid;
        border-radius: 3px;
        color: #fff;
        transition: background 0.2s;
        &:hover,
        &:focus {
          cursor: pointer;
          background: #19a890;
        }
      }
    }
  }

  @media screen and (max-width: 50em) {
    .menu {
      display: none;
    }
  }
`;
