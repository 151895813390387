/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/iframe-has-title */
import React from 'react';
import PropTypes from 'prop-types';

import { Container } from './styles';

function MapsEmpresa(props) {
  MapsEmpresa.propTypes = {
    endereco: PropTypes.string,
  };

  if (!props.endereco) {
    return null;
  }
  return (
    <Container className="mapouter">
      <div className="gmap_canvas">
        <iframe
          width="280"
          height="200"
          id="gmap_canvas"
          src={`https://maps.google.com/maps?q=${props.endereco}&t=&z=16&ie=UTF8&iwloc=&output=embed`}
          frameBorder="0"
          scrolling="no"
          marginHeight="0"
          marginWidth="0"
        />
        {/* <a href="https://123movies-to.org" /> */}
        {/* <br /> */}
        {/* <a href="https://www.embedgooglemap.net">google maps web pages</a> */}
      </div>
    </Container>
  );
}

export default MapsEmpresa;
