import { ThemeProvider } from 'styled-components';
import React from 'react';
import { useSelector } from 'react-redux';

const colors = [
  '#1AAD94',
  '#2DCC70',
  '#9B58B5',
  '#3699DD',
  '#2F3F56',
  '#E97C27',
  '#E94C3D',
  '#890000',
  '#F2C311',
  '#788C8D',
  '#000000',
];

export default function Theme(props) {
  const idThemeColor = useSelector(state => state.global.themeColor);

  const cardapioTheme = {
    colors: {
      primary: colors[idThemeColor],
      backgroundCardapio: '#F4F5F7',
      backgroundSacola: '#F9FAFB',
      borderColor: '#dddfe0',
      colorZebrado: '#eeeff0',
      backgroundRodape: '#B3B3B3',
      closed: '#E94C3D',
      open: '#2DCC70',
      title: '#000000',
      subtitle: '#333333',
      description: '#808080',
      backgroundCard: '#ffffff',
      categorias: ['#FFE384', '#D0A9F1', '#fbc2d0', '#BCED9F'],
      barraSacola: '#f9a260',
      required: '#fac1c1',
    },

    heightCabecalho: 420,
    heightCabecalhoMob: 320,
    heightContainerMenu: 200,
    heightContainerMenuMob: 80,
    heightContainerImage: 250,
    heightContainerImageMob: 140,
    heightLogo: 170,
    heightLogoMob: 108,

    heightRodape: 120,
    heightRodapeMob: 70,
    heightBarraSacola: 40,

    borderRadiusSmall: '10px',
    borderRadius: '20px',
    borderRadiusCirculo: '50%',
    minHeightCardItem: 120,

    borderButtonRadius: '10px',

    minHeightImgCategoria: 90,
    minHeightCategoria: 120,

    minHeightImgCategoriaMob: 63,
    minHeightCategoriaMob: 84,

    fonts: {
      title: 'PoppinsBold',
      fontSubttitle: 'PoppinsSemiBold',
      regular: 'Poppins',
      description: 'Poppins',
    },
  };

  return <ThemeProvider theme={cardapioTheme}>{props.children}</ThemeProvider>;
}
