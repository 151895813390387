import { BaseService } from './BaseService';
import { ConfigApi } from '../config/Constantes';
import api from './api';

class EnderecoService extends BaseService {
  constructor() {
    super(ConfigApi.enderecoURL);
    this.filter = new Filter();
  }

  getFilter() {
    return this.filter;
  }

  setFilter(filter) {
    this.filter = filter;
  }

  async findByCep(cep) {
    this.setLoading(true);
    try {
      const urlSend = `${ConfigApi.enderecoURL}/${cep}`;
      const response = await api.get(urlSend, this.getHeaderAuth(urlSend));
      return response.data;
    } finally {
      this.setLoading(false);
    }
  }
}

class Filter {
  constructor() {
    this.razaoSocial = '';
    this.identificadorHtml = '';
    this.limit = 11;
    this.page = 0;
  }
}
export default new EnderecoService();
