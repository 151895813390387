import styled from 'styled-components';

export const Container = styled.div`
  max-width: 300px;
  text-align: center;
  margin: 5px;

  form {
    display: flex;
    flex-direction: column;
  }
  img {
    width: 300px;
  }
`;
