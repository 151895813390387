/* eslint-disable import/no-cycle */
import { call, put, all, takeLatest } from 'redux-saga/effects';

import jwt from 'jsonwebtoken';
import api from '../../../services/api';
import { loginSuccess } from './actions';
import { loadingSuccess, loading } from '../global/actions';
import { hiddenDialogLogin } from '../dialog/actions';
import authConfig from '../../../config/auth';
import { errorHandle } from '../../../util/functions';

export function* doLogin({ payload, redirectTo }) {
  yield put(loading());
  const { email, senha, numeroContrato } = payload;
  try {
    const response = yield call(api.post, 'sessions', {
      email,
      senha,
      numeroContrato,
    });
    const { token, usuario, apiInfo } = response.data;
    const decodedToken = jwt.verify(token, authConfig.secret);

    api.defaults.headers.Authorization = `Bearer ${token}`;

    sessionStorage.setItem(
      'apiInfo',
      JSON.stringify({
        apiVersion: apiInfo.versionNumber,
        dateLastUpdate: new Date(),
      })
    );

    yield put(loginSuccess(token, decodedToken, usuario));
    yield put(loadingSuccess());
    yield put(hiddenDialogLogin());

    if (redirectTo && redirectTo !== '') {
      window.location = redirectTo; // força recarregar a pagina
    }
  } catch (err) {
    yield put(loadingSuccess());
    errorHandle(err);
    // toast.error('Falha na autenticação, verifique seus dados');
  }
}
export function setToken({ payload }) {
  if (!payload) return;
  const { token } = payload.auth;
  if (token) {
    api.defaults.headers.Authorization = `Bearer ${token}`;
  }
}

export function doLogout() {
  // tive que usar o setTimeout para evitar um problema que a tela travava no telefone
  // setTimeout(() => history.push('/'), 200);
  setTimeout(() => window.location.reload(), 200);
}

export default all([
  takeLatest('persist/REHYDRATE', setToken),
  takeLatest('@auth/LOGIN_REQUEST', doLogin),
  takeLatest('@auth/LOGOUT', doLogout),
]);
