import React, { useState, useCallback, useEffect, useMemo } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

import { toast } from 'react-toastify';
import { Container } from './styles';
import BarraTitulo from '../../components/BarraTitulo';
import PainelFiltro from '../../components/PainelFiltro';
import ProdutoService from '../../services/ProdutoService';
import ProdutoGrupoService from '../../services/ProdutoGrupoService';

import history from '../../services/history';
import { StatusTela } from '../../util/Tipos';
import {
  buscaPageParams,
  errorHandle,
  validateFields,
  padLeft,
  formatFloat,
} from '../../util/functions';
import LabelSp from '../../components/LabelSp';
import { ProdutoModel } from '../../util/Models';

import { showMessage } from '../../components/MessageDialog';
import InputTextSp from '../../components/InputTextSp';
import InputTextareaSp from '../../components/InputTextareaSp';

import DropdownSp from '../../components/DropdownSp';
import InputCurrencySp from '../../components/InputCurrencySp';
import AuthService from '../../services/AuthService';
import PainelBotesCadastro from '../../components/PainelBotesCadastro';
import BotaoMenuGrid from '../../components/BotaoMenuGrid';
import ButtonSp from '../../components/ButtonSp';
import ProdutoUnidadeService from '../../services/ProdutoUnidadeService';

export default function Produto(props) {
  // useMemo
  const pageParams = useMemo(
    () => buscaPageParams(props.match.params, props.location.search),
    [props.location.search, props.match.params]
  );
  const filterService = useMemo(() => ProdutoService.getFilter(), []);

  // useStates
  const backTo = pageParams.backTo || '/painel/produtos';
  const [filter, setFilter] = useState(filterService);
  const [produtos, setProdutos] = useState([]);
  const [pageLimit, setPageLimit] = useState(filterService.limit);
  const [first, setFirst] = useState(0);
  const [totalRecords, setTotalRecords] = useState(0);
  const [produto, setProduto] = useState(new ProdutoModel());
  const [unidades, setUnidades] = useState([]);
  const [grupos, setGrupos] = useState([]);

  // useCalllbacks
  const handleBuscar = useCallback(async (_filter, _page, resetPage = true) => {
    _filter.page = _page;
    try {
      const result = await ProdutoService.findAll(_filter);
      setProdutos(result.items);
      setPageLimit(result.limit);
      setTotalRecords(result.totalRecords);
      if (resetPage) {
        setFirst(0);
      }
    } catch (err) {
      errorHandle(err);
    }
  }, []);

  const carregaRegistro = useCallback(async _id => {
    try {
      const retorno = await ProdutoService.findById(_id);
      setProduto(retorno);
    } catch (err) {
      errorHandle(err);
    }
  }, []);

  function onPage(event) {
    const pagina = event.first / event.rows;
    setFirst(event.first);
    handleBuscar(filter, pagina, false);
  }

  const carregaUnidades = useCallback(async () => {
    try {
      const retorno = await ProdutoUnidadeService.findAll();
      const listaUnidades = retorno.items.map(e => {
        return {
          label: e.sigla,
          value: e.id,
        };
      });
      setUnidades(listaUnidades);
    } catch (err) {
      errorHandle(err);
    }
  }, []);

  const carregaGrupos = useCallback(async () => {
    try {
      const retorno = await ProdutoGrupoService.findAll();
      const listaGrupos = retorno.items.map(e => {
        return {
          label: e.nome,
          value: e.id,
        };
      });
      setGrupos(listaGrupos);
    } catch (err) {
      errorHandle(err);
    }
  }, []);

  const excluirRegistro = useCallback(
    async _id => {
      try {
        await ProdutoService.delete(_id);
        toast.success('Registro excluído com sucesso.');
        handleBuscar(filterService);
      } catch (err) {
        errorHandle(err);
      }
    },
    [filterService, handleBuscar]
  );

  const confirmaExclusao = useCallback(
    idSelecionado => {
      showMessage('Confirmação', 'Confirma a exclusão do registro?', idx => {
        if (idx === 1) {
          excluirRegistro(idSelecionado);
        }
      });
    },
    [excluirRegistro]
  );

  // functions
  function handleVoltar() {
    if (pageParams.statusTela === StatusTela.stVisualizar) {
      history.push(backTo);
      handleBuscar(filter);
    } else {
      showMessage('Confirmação', 'Abandonar mudanças?', idx => {
        if (idx === 1) {
          history.push(backTo);
          handleBuscar(filter);
        }
      });
    }
  }

  function handleSalvar() {
    if (pageParams.statusTela === StatusTela.stVisualizar) {
      history.push(backTo);
    } else {
      salvarRegistro();
    }
  }

  function modoConsulta() {
    return pageParams.statusTela === StatusTela.stVisualizar;
  }

  async function salvarRegistro() {
    try {
      if (pageParams.statusTela === StatusTela.stInserir) {
        await ProdutoService.insert(produto);
      } else {
        await ProdutoService.update(produto);
      }
      toast.success('Registro salvo com sucesso.');
      history.push(backTo);
      handleBuscar(filter);
    } catch (err) {
      errorHandle(err);
    }
  }

  // useEffects
  useEffect(() => {
    if (pageParams.statusTela === StatusTela.stPesquisar) {
      handleBuscar(filter, 0, 800);
    }
    // desativado para evitar que a cada vez que o usuario digitasse o sistema buscasse
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // simulando componentDidMount
  useEffect(() => {
    carregaUnidades();
    carregaGrupos();
  }, [carregaGrupos, carregaUnidades]);

  // simulando componentDidUpdate
  useEffect(() => {
    if (
      pageParams.statusTela === StatusTela.stAlterar ||
      pageParams.statusTela === StatusTela.stVisualizar
    ) {
      carregaRegistro(pageParams.idSelecionado);
    } else if (pageParams.statusTela === StatusTela.stInserir) {
      const novo = new ProdutoModel();
      setProduto(novo);
    }
  }, [
    carregaRegistro,
    filterService,
    handleBuscar,
    pageParams.idSelecionado,
    pageParams.statusTela,
  ]);

  // renders
  return (
    <Container className="container-page">
      <div className="p-grid">
        <BarraTitulo title="Produtos" />
        {pageParams.statusTela === StatusTela.stPesquisar
          ? renderPesquisa()
          : renderCadastro()}
      </div>
    </Container>
  );

  function renderPesquisa() {
    return (
      <>
        <form
          onSubmit={e => {
            e.preventDefault();
            handleBuscar(filter);
          }}
        >
          <PainelFiltro className="p-grid p-col-12" visible="true">
            <div className="p-col-12 p-sm-6 p-lg-6 p-fluid">
              <LabelSp>Descrição</LabelSp>
              <InputTextSp
                value={filter.descricao}
                onChange={e => {
                  setFilter({ ...filter, descricao: e.target.value });
                }}
              />
            </div>

            <div className="p-col-12 p-sm-6 p-lg-6 p-fluid">
              <LabelSp>Descrição Resumida</LabelSp>
              <InputTextSp
                value={filter.descricaoResumida}
                onChange={e => {
                  setFilter({ ...filter, descricaoResumida: e.target.value });
                }}
              />
            </div>
          </PainelFiltro>
          <PainelBotesCadastro
            handleInserir={() => {
              history.push('/painel/produtos/inserir');
            }}
            buttonInserirDisabled={!AuthService.checkRoles('PRODUTO_INSERIR')}
          />
        </form>
        <div className="p-col-12 p-fluid">
          <DataTable
            value={produtos}
            style={{ marginBottom: '2px' }}
            paginator
            rows={pageLimit}
            lazy
            responsive
            totalRecords={totalRecords}
            first={first}
            onPage={onPage}
          >
            <Column
              field="id"
              body={rowData => padLeft(rowData.id, 6)}
              header="Id"
              className="grid-col-id"
            />
            <Column field="descricao" className="grid-col" header="Descrição" />
            <Column field="descricaoResumida" className="grid-col" header="Desc. Resumida" />
            <Column
              field="valorVenda"
              className="grid-col-val"
              header="Preço"
              body={rowData => formatFloat(rowData.valorVenda, 2)}
            />

            <Column
              className="gid-col-acoes-35"
              bodyStyle={{ textAlign: 'end' }}
              body={renderButtonOp}
            />
          </DataTable>
        </div>
      </>
    );
  }

  function renderButtonOp(rowData) {
    return (
      <BotaoMenuGrid
        handles={[
          () => history.push(`/painel/produtos/${rowData.id}?visualizar`),
          () => history.push(`/painel/produtos/${rowData.id}`),
          () => confirmaExclusao(rowData.id),
        ]}
        disableds={[
          false,
          !AuthService.checkRoles('PRODUTO_ALTERAR'),
          !AuthService.checkRoles('PRODUTO_EXCLUIR'),
        ]}
      />
    );
  }

  function renderCadastro() {
    return (
      <>
        <div className="p-col-12 p-sm-6 p-lg-5 p-fluid">
          <LabelSp>Descrição</LabelSp>
          <InputTextSp
            value={produto.descricao}
            maxLength={100}
            required
            disabled={modoConsulta()}
            onChange={e => {
              setProduto({ ...produto, descricao: e.target.value });
            }}
          />
        </div>
        <div className="p-col-12 p-sm-6 p-lg-3 p-fluid">
          <LabelSp>Desc. Resumida</LabelSp>
          <InputTextSp
            value={produto.descricaoResumida}
            maxLength={30}
            required
            disabled={modoConsulta()}
            onChange={e => {
              setProduto({ ...produto, descricaoResumida: e.target.value });
            }}
          />
        </div>
        <div className="p-col-6 p-sm-5 p-lg-4 p-fluid">
          <LabelSp>Grupo</LabelSp>
          <DropdownSp
            required
            disabled={modoConsulta()}
            placeholder="selecione"
            value={produto.idProdutoGrupo}
            options={grupos}
            place
            onChange={e => {
              setProduto({ ...produto, idProdutoGrupo: e.target.value });
            }}
          />
        </div>
        <div className="p-col-6 p-sm-2 p-lg-3 p-fluid">
          <LabelSp>Unidade</LabelSp>
          <DropdownSp
            required
            disabled={modoConsulta()}
            placeholder="selecione"
            value={produto.idProdutoUnidade}
            options={unidades}
            onChange={e => {
              setProduto({ ...produto, idProdutoUnidade: e.target.value });
            }}
          />
        </div>
        <div className="p-col-6 p-sm-2 p-lg-2 p-fluid">
          <LabelSp>Valor</LabelSp>
          <InputCurrencySp
            required
            disabled={modoConsulta()}
            maxLength={10}
            onlyPositive
            value={produto.valorVenda}
            onChange={(e, v) => {
              setProduto({ ...produto, valorVenda: v });
            }}
          />
        </div>
        <div className="p-col-12 p-sm-3 p-lg-2 p-fluid">
          <LabelSp>Cod. Interno</LabelSp>
          <InputTextSp
            value={produto.codigoInterno}
            maxLength={15}
            disabled={modoConsulta()}
            onChange={e => {
              setProduto({ ...produto, codigoInterno: e.target.value });
            }}
          />
        </div>
        <div className="p-col-12 p-sm-12 p-lg-12 p-fluid">
          <LabelSp>Observação</LabelSp>
          <InputTextareaSp
            value={produto.observacao}
            maxLength={100}
            disabled={modoConsulta()}
            onChange={e => {
              setProduto({ ...produto, observacao: e.target.value });
            }}
          />
        </div>
        <div className="p-col-12 p-lg-12" style={{ textAlign: 'end' }}>
          {!modoConsulta() ? (
            <ButtonSp
              className="p-button-success"
              icon="pi pi-save"
              label="Salvar"
              disabled={
                !validateFields(produto, [
                  'descricao',
                  'descricaoResumida',
                  'idProdutoGrupo',
                  'idProdutoUnidade',
                  'valorVenda',
                ])
              }
              showConfirmation
              onClick={handleSalvar}
            />
          ) : null}
          <ButtonSp
            className="p-button-secondary"
            label="Voltar"
            icon="pi pi-chevron-circle-left"
            onClick={handleVoltar}
          />
        </div>
      </>
    );
  }
}
