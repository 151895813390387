import { lighten } from 'polished';
import styled from 'styled-components';

export const Container = styled.div`
  .dialog {
    min-width: 370px;
    max-width: 900px;
    width: 95%;
    border-radius: ${({ theme }) => theme.borderRadius};
    border-style: solid;
    border-width: 0;
  }
`;

export const DivBodyDlg = styled.div`
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  display: flex;
  flex-direction: column;

  button {
    border-radius: ${({ theme }) => theme.borderRadiusSmall};
    border-style: solid;
    border-width: 0;
    margin: 5px;
  }

  .div-botao-fechar-dlg {
    flex: 1;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    height: 30px;
    z-index: 100;

    .button-close {
      font-size: 30px;
      color: ${props => props.theme.colors.backgroundRodape};
      background-color: ${props => props.theme.colors.backgroundCardapio};

      :hover {
        color: ${props => lighten(0.05, props.theme.colors.backgroundRodape)};
      }

      border-width: 0;
      margin: 0;
      padding: 0;
      border-radius: ${({ theme }) => theme.borderRadius};
    }
  }

  .div-container-dlg {
    margin-top: -30px;
    padding: 10px;
    display: flex;
    flex-direction: column;
    min-height: 150px;
  }

  .titulo-dlg {
    margin-bottom: 10px;
    font-family: ${({ theme }) => theme.fonts.fontSubttitle};
    font-size: 15px;
    text-align: center;
  }
`;
