export class EmpresaModel {
  constructor() {
    this.id = null;
    this.razaoSocial = '';
    this.fantasia = '';
    this.tipoPessoa = 'J';
    this.cpfCnpj = '';
    this.cep = '';
    this.logradouro = '';
    this.numero = '';
    this.complemento = '';
    this.bairro = '';
    this.telefone = '';
    this.celular = '';
    this.email = '';
    this.inativo = false;
    this.codigoIbge = null;
    this.uf = 'MG';
    this.nomeMunicipio = '';
    this.identificadorHtml = '';
    this.numeroContrato = null;
    this.estaFuncionando = true;
    this.valorTaxaEntrega = 0;
    this.listaHorarios = [];
    this.fileUri = '';
    this.fileUriBanner = '';
    this.permitePedidoOnline = true;
    this.tipoGrupoExpandido = 0;
  }
}

export class ProdutoGrupoModel {
  constructor() {
    this.id = null;
    this.nome = '';
    this.descricao = '';
    this.mostrarCardapio = true;
    this.exibirImagem = false;
  }
}

export class UsuarioModel {
  constructor() {
    this.id = null;
    this.email = '';
    this.nome = '';
    this.senhaHash = '';
    this.dataCadastro = new Date();
    this.inativo = false;
    this.tipoUsuario = 1;
    this.numeroContrato = 0;
  }
}
export class PedidoEntregaModel {
  constructor() {
    this.id = null;
    this.idVendaCabecalho = null;
    this.nome = '';
    this.cep = '';
    this.logradouro = '';
    this.numero = '';
    this.complemento = '';
    this.bairro = '';
    this.nomeMunicipio = '';
    this.codigoIbge = null;
    this.uf = 'MG';
    this.telefone = '';
    // this.retirarNoBalcao = false;
    this.retiradaDelivery = 0; // 0 - Entrega, 1-retirada na loja, 2-Consumir no local
    this.latitude = 0;
    this.longitude = 0;
  }
}

export class FormaPagamentoModel {
  constructor() {
    this.id = null;
    this.nome = '';
    this.idTipoFinalizadora = null;
  }
}

export class PedidoCabecalhoModel {
  constructor() {
    this.id = null;
    this.idEmpresa = null;
    this.idPedidoDetalhe = null;
    this.idFormaPagamento = 0;
    this.data = new Date();
    this.hora = new Date();
    this.localEntrega = '';
    this.valorSubtotal = 0;
    this.valor = 0;
    this.valorEntrega = 0;
    this.valorTrocoPara = 0;
    this.observacao = '';
    this.situacao = 'A';
    this.quantidadeItens = 0;
    this.idTema = 4;
    this.pedidoEntrega = new PedidoEntregaModel();
    this.formaPagamento = new FormaPagamentoModel();
    this.pedidoDetalhe = [];
    this.numeroMesa = 0;
  }
}

export class PedidoDetalheModel {
  constructor() {
    this.id = null;
    this.idPedidoCabecalho = null;
    this.idProduto = null;
    this.numeroItem = null;
    this.unidade = '';
    this.descricao = '';
    this.quantidade = 0;
    this.valorUnitario = 0;
    this.valorAdicional = 0;
    this.valorSubtotal = 0;
    this.taxaDesconto = 0;
    this.valorDesconto = 0;
    this.valorTotal = 0;
    this.observacao = '';
    this.calculouEntrega = true;
    this.valorProdutoAdicional = 0;
    this.valorPerguntaResposta = 0;

    this.adicional = false;
    this.pedidoDetalheObs = [];
    this.pedidoDetalhe = [];
  }
}

export class PedidoDetalheObsModel {
  constructor() {
    this.id = null;
    this.idPedidoDetalhe = null;
    this.idObservacaoProduto = null;
    this.observacaoProduto = null;
  }
}

export class PedidoPerguntaModel {
  constructor() {
    this.id = null;
    this.idPedidoDetalhe = null;
    this.descricao = '';
    this.tipoResposta = null;
    this.qtdeMinimaOpcaoResposta = 1;
    this.qtdeMaximaOpcaoResposta = 1;
    this.respostaObrigatoria = false;
    this.ordem = null;
    this.respostas = [];
  }
}

export class PedidoPerguntaRespostaModel {
  constructor() {
    this.id = null;
    this.idPedidoPergunta = null;
    this.idProduto = null;
    this.idProdutoObservacao = null;
    this.qtdeMinimaResposta = 1;
    this.quantidadeResposta = 0;
    this.qtdeMaximaResposta = 1;
    this.produto = null;
    this.observacao = null;
    this.marcado = false;
  }
}

export class ProdutoModel {
  constructor() {
    this.id = null;
    this.idProdutoUnidade = null;
    this.idProdutoGrupo = null;
    this.inativo = false;
    this.gtin = '';
    this.codigoInterno = '';
    this.codigoBalanca = null;
    this.codigoFabricante = '';
    this.descricao = '';
    this.descricaoResumida = '';
    this.dataCadastro = null;
    this.dataHoraAlteracao = new Date();
    this.dataHoraSincronizacao = null;
    this.observacao = '';
    this.valorVenda = 0;
  }
}
export class FileImage {
  constructor() {
    this.fileName = '';
    this.file = null;
  }
}
export class Taxas {
  constructor() {
    this.id0 = undefined;
    this.acimaDeKm0 = 0;
    this.valorTaxa0 = 0;

    this.id1 = undefined;
    this.acimaDeKm1 = 0;
    this.valorTaxa1 = 0;

    this.id2 = undefined;
    this.acimaDeKm2 = 0;
    this.valorTaxa2 = 0;

    this.id3 = undefined;
    this.acimaDeKm3 = 0;
    this.valorTaxa3 = 0;

    this.id4 = undefined;
    this.acimaDeKm4 = 0;
    this.valorTaxa4 = 0;

    this.id5 = undefined;
    this.acimaDeKm5 = 0;
    this.valorTaxa5 = 0;

    this.id6 = undefined;
    this.acimaDeKm6 = 0;
    this.valorTaxa6 = 0;

    this.id7 = undefined;
    this.acimaDeKm7 = 0;
    this.valorTaxa7 = 0;

    this.id8 = undefined;
    this.acimaDeKm8 = 0;
    this.valorTaxa8 = 0;

    this.id9 = undefined;
    this.acimaDeKm9 = 0;
    this.valorTaxa9 = 0;

    this.id10 = undefined;
    this.acimaDeKm10 = 0;
    this.valorTaxa10 = 0;

    this.id11 = undefined;
    this.acimaDeKm11 = 0;
    this.valorTaxa11 = 0;
  }
}

export class Horarios {
  constructor() {
    this.segunda = 'Segunda';
    this.segundaAbre0 = '';
    this.segundaFecha0 = '';
    this.segundaAbre1 = '';
    this.segundaFecha1 = '';

    this.terca = 'Terça';
    this.tercaAbre0 = '';
    this.tercaFecha0 = '';
    this.tercaAbre1 = '';
    this.tercaFecha1 = '';

    this.quarta = 'Quarta';
    this.quartaAbre0 = '';
    this.quartaFecha0 = '';
    this.quartaAbre1 = '';
    this.quartaFecha1 = '';

    this.quinta = 'Quinta';
    this.quintaAbre0 = '';
    this.quintaFecha0 = '';
    this.quintaAbre1 = '';
    this.quintaFecha1 = '';

    this.sexta = 'Sexta';
    this.sextaAbre0 = '';
    this.sextaFecha0 = '';
    this.sextaAbre1 = '';
    this.sextaFecha1 = '';

    this.sabado = 'Sábado';
    this.sabadoAbre0 = '';
    this.sabadoFecha0 = '';
    this.sabadoAbre1 = '';
    this.sabadoFecha1 = '';

    this.domingo = 'Domingo';
    this.domingoAbre0 = '';
    this.domingoFecha0 = '';
    this.domingoAbre1 = '';
    this.domingoFecha1 = '';
  }
}
