import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import BarraTitulo from '../../components/BarraTitulo';
import LabelSp from '../../components/LabelSp';

import { store } from '../../store';

import { Container } from './styles';
import { saveConfig } from '../../store/modules/config/actions';
import { errorHandle } from '../../util/functions';
import { showMessage } from '../../components/MessageDialog';

const opcoesSimNao = [
  { label: 'Sim', value: true },
  { label: 'Não', value: false },
];

export default function Configuracoes() {
  const dispatch = useDispatch();
  const [ativarMenuEstatico, setAtivarMenuEstatico] = useState(
    store.getState().config.ativarMenuEstatico
  );

  // useEffect(() => {

  // }, [email, nome]);

  // eventos
  function handleConfirmacaoSalvar() {
    showMessage('Confirmação', 'Confirma os dados?', handleSalvar);
  }

  function handleSalvar(idx) {
    if (idx === 1) {
      try {
        dispatch(saveConfig(ativarMenuEstatico));
        toast.success('Registro salvo com sucesso.');
      } catch (error) {
        errorHandle(error);
      }
    }
  }

  return (
    <Container className="container-page">
      <div className="p-grid">
        <BarraTitulo title="Configurações" />

        <div className="p-col-12 p-sm-4 p-lg-3 p-fluid">
          <LabelSp htmlFor="text">Menu estático:</LabelSp>
          <Dropdown
            value={ativarMenuEstatico}
            options={opcoesSimNao}
            onChange={e => setAtivarMenuEstatico(e.value)}
          />
        </div>

        <div className="p-col-12">
          <Button label="Salvar" onClick={handleConfirmacaoSalvar} />
        </div>
      </div>
    </Container>
  );
}
