import { BaseService } from './BaseService';
import { ConfigApi } from '../config/Constantes';
import api from './api';

class EmpresaService extends BaseService {
  constructor() {
    super(ConfigApi.empresaURL);
    this.filter = new Filter();
  }

  getFilter() {
    return this.filter;
  }

  setFilter(filter) {
    this.filter = filter;
  }

  async findByIdHtml(idHtml) {
    this.setLoading(true);
    try {
      const urlSend = `${this.url}/identificadorHtml/${idHtml}`;
      const response = await api.get(urlSend, this.getHeaderAuth(urlSend));
      return response.data;
    } finally {
      this.setLoading(false);
    }
  }

  async insert(body) {
    delete body.id;

    const data = new FormData();
    Object.keys(body).forEach(k => {
      if (Array.isArray(body[k])) {
        data.append(k, JSON.stringify(body[k]));
      } else {
        data.append(k, body[k]);
      }
    });

    this.setLoading(true);
    try {
      const response = await api.post(this.url, data, this.getHeaderAuth(this.url));
      return response.data;
    } finally {
      this.setLoading(false);
    }
  }

  async update(body) {
    const data = new FormData();
    Object.keys(body).forEach(k => {
      if (Array.isArray(body[k])) {
        data.append(k, JSON.stringify(body[k]));
      } else {
        data.append(k, body[k]);
      }
    });

    try {
      const urlSend = `${this.url}/${body.id}`;
      const response = await api.put(urlSend, data, this.getHeaderAuth(urlSend));
      return response.data;
    } finally {
      this.setLoading(false);
    }
  }
}

class Filter {
  constructor() {
    this.razaoSocial = '';
    this.identificadorHtml = '';
    this.limit = 11;
    this.page = 0;
  }
}
export default new EmpresaService();
