import React from 'react';

import { Container } from './styles';
import InputTextSp from '../../../../components/InputTextSp';

function Input(props) {
  const { required, ...rest } = props;

  return (
    <Container required={required}>
      <InputTextSp className={required ? 'required' : ''} {...rest} />
    </Container>
  );
}

export default Input;
