import React from 'react';

import { Button } from 'primereact/button';
import { Container } from './styles';

export default function NotFound() {
  return (
    <Container className="p-grid">
      <div className="p-col-12 p-lg-12 p-fluid" style={{ marginBottom: 10 }}>
        <h1 style={{ color: 'red', textAlign: 'center' }}>Página não encontrada</h1>
      </div>
      <div className="p-col-12" />
      <div className="p-col-12" style={{ textAlign: 'center' }}>
        <Button
          className="p-button-secondary"
          label="Voltar"
          icon="pi pi-chevron-circle-left"
          onClick={() => window.history.back()} // força reload da página
        />
      </div>
    </Container>
  );
}
