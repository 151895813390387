/* eslint-disable prefer-promise-reject-errors */
import jwt from 'jsonwebtoken';
import axios from 'axios';

import { differenceInMinutes } from 'date-fns';
import { ConfigApi } from '../config/Constantes';
import authConfig from '../config/auth';

// import AuthService from './AuthService';
const routesOpen = [
  'sessions',
  'grupos',
  'empresas',
  'apiInfo',
  'municipios',
  'formasPagamento',
  'enderecos',
  'pedidos',
];
function chedkOpenRoutes(url) {
  let retorno = false;
  routesOpen.forEach(e => {
    if (url.includes(e)) {
      retorno = true;
    }
  });

  return retorno;
}

function checkToken(token) {
  try {
    jwt.verify(token, authConfig.secret);
    return true;
  } catch (error) {
    return false;
  }
}

const api = axios.create({
  baseURL: ConfigApi.baseURL,
});

// configurando a api
api.interceptors.request.use(
  config => {
    const { Authorization } = config.headers;
    const { url } = config;

    if (Authorization && !chedkOpenRoutes(url)) {
      const token = Authorization.split(' ')[1];
      if (token && !checkToken(token)) {
        return Promise.reject({ tokenExpired: true });
      }
    }
    if (url !== 'apiInfo') {
      checkApi();
    }
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

api.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    return Promise.reject(error);
  }
);

export async function checkApi() {
  const check = await checkVersaoApi();

  if (!check) {
    if (window.location.href.includes('painel')) {
      window.location = '/painel/login';
    } else {
      window.location.reload();
    }
  }
}

export async function getApiInfo() {
  try {
    const response = await api.get(`${ConfigApi.apiInfoURL}`);
    return response.data;
  } catch (err) {
    return null;
  }
}

export async function checkVersaoApi() {
  let retorno = true;

  try {
    const dataAtual = new Date();

    const apiInfo = JSON.parse(sessionStorage.getItem('apiInfo'));
    const dataUltimaVersao = apiInfo && apiInfo.dateLastUpdate ? apiInfo.dateLastUpdate : null;
    const versaoApi = Number(apiInfo ? apiInfo.apiVersion : '0');

    let versao = versaoApi;
    let atualizarApiInfo = false;
    const ultimaData =
      new Date(dataUltimaVersao).toString() !== 'Invalid Date'
        ? new Date(dataUltimaVersao)
        : null;

    if (!ultimaData || Math.abs(differenceInMinutes(ultimaData, dataAtual)) >= 1) {
      const data = await getApiInfo();
      versao = data.versionNumber;
      atualizarApiInfo = true;
    }

    if (versaoApi !== versao) {
      atualizarApiInfo = true;
      retorno = false;
    }
    if (atualizarApiInfo) {
      sessionStorage.setItem(
        'apiInfo',
        JSON.stringify({
          apiVersion: versao,
          dateLastUpdate: dataAtual,
        })
      );
    }
  } catch (err) {
    // toast.error('Não foi possível obter versão da API');
  }

  return retorno;
}

export default api;
