/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import { useTheme } from 'styled-components';

// import { Container } from './styles';

function Label(props) {
  const theme = useTheme();

  const { style, ...rest } = props;
  return (
    <label
      style={{ fontFamily: theme.fonts.fontSubttitle, fontSize: 11, ...style }}
      {...rest}
    />
  );
}

export default Label;
