import React from 'react';

import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';

import { Container } from './styles';

function DialogPanel(props) {
  const { visible, title, body, handleClose, yesNoButton, hiddenMessage } = props;

  const nameButton1 = yesNoButton ? 'Sim' : 'OK';
  const footer = (
    <div>
      <Button
        label={nameButton1}
        icon="pi pi-check"
        onClick={() => {
          hiddenMessage();
          if (handleClose) {
            handleClose(1);
          }
        }}
        className="p-button-success"
      />
      {yesNoButton ? (
        <Button
          label="Não"
          icon="pi pi-times"
          onClick={() => {
            hiddenMessage();
            if (handleClose) {
              handleClose(2);
            }
          }}
          className="p-button-danger"
        />
      ) : null}
    </div>
  );
  return (
    <Container>
      <Dialog
        closable={false}
        footer={footer}
        header={title || 'Confirmação'}
        visible={visible}
        style={{ minWidth: '350px', maxWidth: '400px', width: '95%' }}
        modal
        onHide={() => {
          hiddenMessage();
          if (handleClose) {
            handleClose(0);
          }
        }}
      >
        {body || ''}
      </Dialog>
    </Container>
  );
}

export default DialogPanel;
