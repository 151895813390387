import { lighten } from 'polished';
import styled from 'styled-components';

export const ButtonContainer = styled.button`
  margin: 10px;
  border-width: 1px;
  border-radius: ${({ theme }) => theme.borderButtonRadius};
  border-color: ${({ theme }) => theme.colors.primary};
  border-style: solid;

  padding: 3px 7px 3px 7px;
  height: 30px;
  /* flex: 1; */
  font-size: 18px;
  display: flex;
  align-items: center;
  justify-content: center;

  background: ${props => props.theme.colors.primary};

  :hover {
    background: ${props => lighten(0.05, props.theme.colors.primary)};
    border-color: ${props => lighten(0.05, props.theme.colors.primary)};
  }

  :active {
    background: ${props => lighten(0.08, props.theme.colors.primary)};
    border-color: ${props => lighten(0.08, props.theme.colors.primary)};
  }

  cursor: pointer;

  font-family: 'PoppinsBold';
  color: ${({ theme }) => theme.colors.backgroundCard};

  img {
    height: 15px;
    margin-right: 5px;
    margin-left: 5px;
  }
`;
