import React from 'react';
import { InputTextarea } from 'primereact/inputtextarea';
import PropTypes from 'prop-types';
// import { Container } from './styles';

export default function InputTextAreaSp(props) {
  InputTextAreaSp.propTypes = {
    required: PropTypes.bool,
    className: PropTypes.string,
    disabled: PropTypes.bool,
    resize: PropTypes.bool,
  };

  InputTextAreaSp.defaultProps = {
    required: false,
    resize: true,
  };

  const { value, required, disabled, className, resize, ...rest } = props;

  // const classNames = required && !disabled ? `p-error ${className || ''}` : className || '';

  let classNames = required && !disabled ? `p-error ${className || ''}` : className || '';
  if (!resize) {
    classNames += ' unresize ';
  }

  return (
    <InputTextarea {...rest} disabled={disabled} value={value || ''} className={classNames} />
  );
}
