import { combineReducers } from 'redux';

import auth from './auth/reducer';
import global from './global/reducer';
import config from './config/reducer';
import dialog from './dialog/reducer';

export default combineReducers({
  auth,
  global,
  config,
  dialog,
});
