import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Container } from './styles';
// import logo from '../../../../assets/images/logo-white2-full.png';
import logo from '../../../../assets/cardapio/images/rodape-logo-speedy.png';
import iconFaceBook from '../../../../assets/cardapio/images/rodape-logo-facebook.png';
import iconInstagram from '../../../../assets/cardapio/images/rodape-logo-istagram.png';
import iconYoutube from '../../../../assets/cardapio/images/rodape-logo-youtube.png';
import iconHome from '../../../../assets/cardapio/icons/inicio-menu-cardapio.png';
import iconSacola from '../../../../assets/cardapio/icons/meus-pedidos_menu-cardapio.png';

import { formatFloat, goToMarcador } from '../../../../util/functions';
import MeusPedidos from '../MeusPedidos';

function RodapeCardapio(props) {
  RodapeCardapio.propTypes = {
    identificadorHtml: PropTypes.string,
    setShowSacola: PropTypes.func,
    valorPedido: PropTypes.number,
    qtdItens: PropTypes.number,
    permitePedidoOnline: PropTypes.bool,
  };

  const [mostrarMeusPedidos, setMostrarMeusPedidos] = useState(false);
  const {
    identificadorHtml,
    valorPedido,
    setShowSacola,
    qtdItens,
    permitePedidoOnline,
  } = props;

  return (
    <Container>
      <MeusPedidos
        setShowDialog={setMostrarMeusPedidos}
        identificadorHtml={identificadorHtml}
        showDialog={mostrarMeusPedidos}
      />
      <div className="div-desktop">
        <div className="div-inf-empresa">
          <img src={logo} alt="logo" />
          <span>Todos os direitos reservados à SPEEDY SISTEMAS LTDA</span>
        </div>
        <div className="div-inf-redes">
          <div className="div-privacidade-contato">
            <a href="https://www.speedysistemas.com.br/politica-privacidade-cardapio">
              Privacidade
            </a>
            <a href="https://speedysistemas.com.br/contato">Fale conosco</a>
          </div>
          <div>
            <div className="div-redes">
              <span>Acompanhe nossas redes Sociais</span>
              <div className="div-icones">
                <a href="https://www.facebook.com/speedysistemas">
                  <img src={iconFaceBook} alt="facebook" title="Facebook" />
                </a>
                <a href="https://www.instagram.com/speedysistemas/">
                  <img src={iconInstagram} alt="instagram" title="Instagram" />
                </a>
                <a href="https://www.speedysistemas.com.br/politica-privacidade-cardapio">
                  <img src={iconYoutube} alt="youtube" title="Youtube" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="div-mobile">
        <div className="item" onClick={() => goToMarcador('topoPagina')}>
          <img src={iconHome} alt="Home" />
          Inicio
        </div>

        <div className="item" onClick={() => setMostrarMeusPedidos(true)}>
          <img src={iconSacola} alt="Home" />
          Pedidos
        </div>
      </div>
      {permitePedidoOnline && valorPedido ? (
        <div className="div-sacola" onClick={() => setShowSacola(true)}>
          <div className="div-icon">
            <img src={iconSacola} alt="Ver sacola" />
            <span>{qtdItens || 0}</span>
          </div>
          <span> Ver sacola</span>
          <span> R$ {formatFloat(valorPedido || 0, 2)}</span>
        </div>
      ) : null}
    </Container>
  );
}

export default RodapeCardapio;
