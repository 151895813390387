import { lighten } from 'polished';
import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  /* height: ${({ theme }) => `${theme.heightCabecalho}px`}; */

  display: flex;
  flex-direction: column;
  justify-items: center;
  align-items: center;

  @media screen and (max-width: 800px) {
    /* height: ${({ theme }) => `${theme.heightCabecalhoMob}px`}; */
  }

  @media screen and (min-width: 1365px) {
    padding-right: calc((100% - 1365px)/2);
    padding-left: calc((100% - 1365px)/2);
  }
`;
export const ContainerMenu = styled.div`
  background-color: ${({ theme }) => theme.colors.primary};
  /* height: 200px; */

  height: ${({ theme }) => `${theme.heightContainerMenu}px`};

  width: 100%;
  padding-left: 5%;
  padding-right: 5%;

  display: flex;
  flex-direction: row;
  justify-content: flex-end;

  span {
    color: ${({ theme }) => theme.colors.backgroundCard};
    font-family: 'PoppinsBold';
    margin-left: 60px;
    margin-top: 30px;
    cursor: pointer;
  }

  img {
    height: 13px;
    width: 13px;
    margin-right: 5px;
  }

  @media screen and (max-width: 800px) {
    height: ${({ theme }) => `${theme.heightContainerMenuMob}px`};
    span {
      display: none;
    }
  }
`;

export const ContainerImage = styled.div`
  /* margin-top: -125px; */
  border-width: 3px;
  border-style: solid;
  border-color: ${({ theme }) => theme.colors.backgroundCardapio};
  /* height: 250px; */
  width: 100%;
  background-color: ${({ theme }) => theme.colors.backgroundCardapio};
  border-radius: ${({ theme }) => theme.borderRadius};

  margin-top: ${({ theme }) => `${-theme.heightContainerImage / 2}px`};
  height: ${({ theme }) => `${theme.heightContainerImage}px`};
  @media screen and (max-width: 800px) {
    height: ${({ theme }) => `${theme.heightContainerImageMob}px`};
    margin-top: ${({ theme }) => `${-theme.heightContainerImageMob / 2}px`};
  }

  img {
    border-radius: ${({ theme }) => theme.borderRadius};
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

export const ContainerEmpresa = styled.div`
  /* height: 220px; */

  height: ${({ theme }) => `${theme.heightCabecalho / 2}px`};
  width: 95%;

  @media screen and (max-width: 800px) {
    height: ${({ theme }) => `${theme.heightCabecalhoMob / 2}px`};
  }
`;

export const ContainerDadosPesquisa = styled.div`
  margin-top: 0px;
  /* margin-left: 230px; */
  display: flex;
  flex-direction: column;

  margin-left: ${({ theme }) => `${theme.heightContainerImage}px`};

  @media screen and (max-width: 800px) {
    margin-left: ${({ theme }) => `${theme.heightLogoMob + theme.heightLogoMob / 4}px`};
  }

  .dados-empresa-pesquisa {
    display: flex;
    flex-wrap: wrap;
  }

  .font-titulo {
    font-family: 'PoppinsBold';
    color: ${({ theme }) => theme.colors.title};
    font-size: 1.8em;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    @media screen and (max-width: 800px) {
      font-size: 1.2em;
    }
  }

  .titulo-municipio {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .dados-empresa {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex: 1;

    span {
      padding: 10px 10px 10px 2px;
      font-size: 0.8em;
      @media screen and (max-width: 800px) {
        font-size: 0.6em;
      }
    }

    .marcador {
      font-size: 20px;
      padding: 0;

      @media screen and (max-width: 800px) {
        display: none;
      }
    }

    img {
      width: 10px;
    }

    .button {
      margin: 10px;
      border-width: 0;
      border-radius: ${({ theme }) => theme.borderRadius};
      padding: 3px 7px 3px 7px;

      font-size: 0.8em;
      @media screen and (max-width: 800px) {
        font-size: 0.6em;
        margin: 0;
      }

      background: linear-gradient(
        ${props => props.theme.colors.primary},
        ${props => lighten(0.2, props.theme.colors.primary)}
      );

      :hover {
        background: ${props => lighten(0.2, props.theme.colors.primary)};
      }

      :active {
        background: linear-gradient(
          ${props => props.theme.colors.primary},
          ${props => lighten(0.3, props.theme.colors.primary)}
        );
      }

      cursor: pointer;

      font-weight: bold;
      color: ${({ theme }) => theme.colors.backgroundCard};
    }
  }
  .container-pesquisa {
    display: flex;
    align-items: center;
    flex: 1;

    @media screen and (max-width: 800px) {
      margin-left: 10px;
    }

    @media screen and (max-width: 613px) {
      margin-left: 0px;
    }

    .pesquisa {
      flex: 1;
      height: 30px;
      min-width: 200px;

      display: flex;
      flex-direction: row;
      align-items: center;

      background-color: ${({ theme }) => theme.colors.backgroundCard};
      border-radius: ${({ theme }) => theme.borderRadius};
      input {
        border: 0;
        width: 80%;
      }
      img {
        margin-left: 5px;
        margin-right: 2px;
        height: 60%;
      }
    }
  }
`;

export const ContainerLogo = styled.div`
  border-radius: ${({ theme }) => theme.borderRadiusCirculo};
  border-color: ${({ theme }) => theme.colors.backgroundCardapio};
  background-color: ${({ theme }) => theme.colors.backgroundCardapio};
  padding: 5px;
  /* height: 170px;
  width: 170px; */
  position: absolute;

  margin-top: ${({ theme }) => `${-theme.heightLogo / 2}px`};
  margin-left: ${({ theme }) => `${theme.heightLogo / 4}px`};
  height: ${({ theme }) => `${theme.heightLogo}px`};
  width: ${({ theme }) => `${theme.heightLogo}px`};

  @media screen and (max-width: 800px) {
    margin-top: ${({ theme }) => `${-theme.heightLogoMob / 2}px`};
    margin-left: ${({ theme }) => `${theme.heightLogoMob / 4}px`};
    height: ${({ theme }) => `${theme.heightLogoMob}px`};
    width: ${({ theme }) => `${theme.heightLogoMob}px`};
  }

  .logo {
    border-radius: ${({ theme }) => theme.borderRadiusCirculo};
    /* margin: 5px; */
    height: 100%;
    width: 100%;
    object-fit: cover;
  }

  .img-disponivel {
    margin-left: -15px;
    margin-top: 55px;

    height: 20%;
    width: 20%;
    position: absolute;

    @media screen and (max-width: 800px) {
      margin-left: -10px;
      margin-top: 35px;

      /* margin-left: -15px;
      margin-top: 55px; */
    }
  }
`;
