import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import MapGL, { Marker, NavigationControl } from 'react-map-gl';

import { toast } from 'react-toastify';
import mapboxgl from 'mapbox-gl';
import Pin from './pin';
import { ContainerLocalizacao, ContainerCentralizaLoc } from './styles';
import { showMessage } from '../MessageDialog';

// mapbox não carregava quando era compilado em produção, então tive q fazer isso
// eslint-disable-next-line import/no-webpack-loader-syntax, import/no-unresolved
mapboxgl.workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default;

const TOKEN =
  'pk.eyJ1Ijoic3BlZWR5c2lzdGVtYXMiLCJhIjoiY2ttNHhpbnR1MDZoejJ0b2N3ZXE5aHhieCJ9.LWV29sD_Byo2_5_zmPDv4w';

const navStyle = {
  position: 'absolute',
  top: 0,
  left: 0,
  padding: '10px',
};

export default function Mapa(props) {
  const latitudePadrao = 0;
  const longitudePadrao = 0;

  Mapa.propTypes = {
    markerDefault: PropTypes.object.isRequired,
    onChangeMarker: PropTypes.func,
    draggable: PropTypes.bool,
  };

  Mapa.defaultProps = {
    markerDefault: {
      latitude: latitudePadrao,
      longitude: longitudePadrao,
    },
    draggable: true,
  };

  const [viewport, setViewport] = useState({
    zoom: 17,
    minZoom: 12,
    maxZoom: 20,
    latitude: latitudePadrao,
    longitude: longitudePadrao,
    bearing: 0,
    pitch: 0,
  });

  const [markerSelecionada, setMarkerSelecionada] = useState({
    latitude: latitudePadrao,
    longitude: longitudePadrao,
  });

  const [, logEvents] = useState({});

  const onMarkerDragStart = useCallback(event => {
    logEvents(_events => ({ ..._events, onDragStart: event.lngLat }));
  }, []);

  const onMarkerDrag = useCallback(event => {
    logEvents(_events => ({ ..._events, onDrag: event.lngLat }));
  }, []);

  const confirmeLocalizacao = () => {
    showMessage('Confirmação', 'Deseja usar sua localização atual?', idx => {
      if (idx === 1) {
        navigator.geolocation.getCurrentPosition(
          location => {
            if (location && location.coords) {
              setViewport({
                ...viewport,
                latitude: location.coords.latitude,
                longitude: location.coords.longitude,
              });
              setMarkerSelecionada({
                latitude: location.coords.latitude,
                longitude: location.coords.longitude,
              });
              if (onChangeMarker) {
                onChangeMarker({
                  latitude: location.coords.latitude,
                  longitude: location.coords.longitude,
                });
              }
            }
          },
          () => {
            toast.warn(`Não foi possível obter a localização`);
          }
        );
      }
    });
  };

  const { onChangeMarker, draggable } = props;
  const onMarkerDragEnd = useCallback(
    event => {
      logEvents(_events => ({ ..._events, onDragEnd: event.lngLat }));
      setMarkerSelecionada({
        longitude: event.lngLat[0],
        latitude: event.lngLat[1],
      });
      if (onChangeMarker) {
        onChangeMarker({
          longitude: event.lngLat[0],
          latitude: event.lngLat[1],
        });
      }
    },
    [onChangeMarker]
  );

  useEffect(() => {
    if (props.markerDefault) {
      setMarkerSelecionada(props.markerDefault);
      setViewport({
        ...viewport,
        latitude: props.markerDefault.latitude,
        longitude: props.markerDefault.longitude,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.markerDefault]);

  return (
    <>
      {viewport && markerSelecionada ? (
        <>
          <MapGL
            {...viewport}
            width="100%"
            height="100%"
            mapStyle="mapbox://styles/mapbox/streets-v11"
            onViewportChange={vp => {
              if (viewport) {
                setViewport(vp);
              }
            }}
            mapboxApiAccessToken={TOKEN}
          >
            <Marker
              longitude={markerSelecionada ? markerSelecionada.longitude : 0}
              latitude={markerSelecionada ? markerSelecionada.latitude : 0}
              offsetTop={-20}
              offsetLeft={-10}
              draggable={draggable}
              onDragStart={onMarkerDragStart}
              onDrag={onMarkerDrag}
              onDragEnd={onMarkerDragEnd}
            >
              <Pin size={32} />
            </Marker>
            <div className="nav" style={navStyle}>
              <NavigationControl />
            </div>
            {draggable && (
              <>
                <ContainerCentralizaLoc
                  title="Trazer marca para o centro"
                  onClick={() => {
                    setMarkerSelecionada({
                      latitude: viewport.latitude,
                      longitude: viewport.longitude,
                    });
                    if (onChangeMarker) {
                      onChangeMarker({
                        latitude: viewport.latitude,
                        longitude: viewport.longitude,
                      });
                    }
                  }}
                >
                  <Pin size={28} className="image" />
                </ContainerCentralizaLoc>
                <ContainerLocalizacao
                  style={{ top: 45 }}
                  title="Usar minha localização atual"
                  onClick={async () => {
                    confirmeLocalizacao();
                  }}
                >
                  <Pin size={28} className="image" />
                </ContainerLocalizacao>
              </>
            )}
          </MapGL>
        </>
      ) : null}
    </>
  );
}
