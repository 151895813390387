import styled from 'styled-components';

export const ContainerLocalizacao = styled.div`
  position: absolute;
  top: 5px;
  right: 5px;
  padding: 0;
  height: 36px;
  width: 36px;

  border: #d8d8d8 1px solid;
  border-radius: 3px;
  background: #f8f6f6;
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  .image {
    cursor: pointer;
    align-self: center;
    fill: #34a835 !important;
    &:hover,
    &:focus {
      cursor: pointer;
      fill: #107d11 !important;
    }
  }
`;

export const ContainerCentralizaLoc = styled.div`
  position: absolute;
  top: 5px;
  right: 5px;
  padding: 0;
  height: 36px;
  width: 36px;

  border: #d8d8d8 1px solid;
  border-radius: 3px;
  background: #f8f6f6;
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  .image {
    cursor: pointer;
    align-self: center;
    fill: #b31f2e !important;
    &:hover,
    &:focus {
      cursor: pointer;
      fill: #db2b3c !important;
    }
  }
`;
