import React, { createRef, useEffect } from 'react';
import { useTheme } from 'styled-components';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';

import { Container, DivBodyDlg } from './styles';

function DialogCardapio(props) {
  const { visible, title, body, handleClose, yesNoButton, hiddenMessage } = props;
  const theme = useTheme();
  const nameButton1 = yesNoButton ? 'Sim' : 'OK';

  const buttonRef = createRef();

  useEffect(() => {
    if (
      buttonRef &&
      buttonRef.current &&
      buttonRef.current.element &&
      buttonRef.current.element.focus
    ) {
      buttonRef.current.element.focus();
    }
  }, [buttonRef]);

  const footer = (
    <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 10 }}>
      <Button
        ref={buttonRef}
        label={nameButton1}
        icon="pi pi-checkx"
        onClick={() => {
          hiddenMessage();
          if (handleClose) {
            handleClose(1);
          }
        }}
        className="p-button-success"
      />
      {yesNoButton ? (
        <Button
          label="Não"
          icon="pi pi-times"
          onClick={() => {
            hiddenMessage();
            if (handleClose) {
              handleClose(2);
            }
          }}
          className="p-button-danger"
        />
      ) : null}
    </div>
  );
  return (
    <Container>
      <Dialog
        closable={false}
        // footer={footer}
        className="dialog"
        // header={title || 'Confirmação'}
        showHeader={false}
        visible={visible}
        style={{ minWidth: '350px', maxWidth: '400px', width: '90%' }}
        contentStyle={{
          // maxHeight: '70vh',
          overflowY: 'hidden',
          overflowX: 'hidden',

          borderRadius: theme.borderRadius,
          borderStyle: 'solid',
          borderWith: 0,
          backgroundColor: theme.colors.backgroundCardapio,
          margin: 0,
          padding: 0,
        }}
        modal
        onHide={() => {
          hiddenMessage();
          if (handleClose) {
            handleClose(0);
          }
        }}
      >
        <DivBodyDlg>
          <div className="div-botao-fechar">
            <button
              className="pi pi-times-circle button-close"
              onClick={() => {
                hiddenMessage();
                if (handleClose) {
                  handleClose(0);
                }
              }}
            />
          </div>
          <div className="div-container">
            <span className="titulo">{title}</span>
            <div style={{ flex: 1 }}>{body || ''}</div>
            {footer}
          </div>
        </DivBodyDlg>
      </Dialog>
    </Container>
  );
}

export default DialogCardapio;
