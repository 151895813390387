export function montaMenuGrid(
  handles = [],
  disableds = [],
  labels = ['Visualizar', 'Alterar', 'Excluir'],
  icons = ['pi pi-search', 'pi pi-pencil', 'pi pi-trash']
) {
  let i;
  const retorno = [];
  for (i = 0; i < labels.length; i++) {
    const e = {};
    e.label = labels[i];
    if (icons[i]) {
      e.icon = icons[i];
    }
    if (handles[i]) {
      e.command = handles[i];
    }
    if (disableds[i]) {
      e.disabled = true;
    }
    retorno.push(e);
  }
  return retorno;
}
