export function saveConfig(ativarMenuEstatico) {
  return {
    type: '@config/SAVE_CONFIG',
    config: { ativarMenuEstatico },
  };
}

export function saveConfigApi(tiposDocumento) {
  return {
    type: '@config/SAVE_CONFIG_API',
    config: { tiposDocumento },
  };
}
