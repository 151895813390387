export const StatusTela = {
  stInserir: 'INSERIR',
  stAlterar: 'ALTERAR',
  stVisualizar: 'VISUALIZAR',
  stPesquisar: 'PESQUISAR',
};

export const StatusTelaCardapio = {
  stCardapio: 'CARDAPIO',
  stAddPedido: 'ADD_PEDIDO',
  stAlterandoPedido: 'ALTERANDO_PEDIDO',
  stFinalizaPedido: 'FINALIZA_PEDIDO',
};
