import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  border-color: ${props => props.theme.colors.subtitle};
  border-style: solid;
  border-width: 1.5px;

  .marcacao {
    width: 60%;
    height: 60%;
    background-color: ${props => props.theme.colors.primary};
  }
`;
