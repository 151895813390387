import React from 'react';

import PropTypes from 'prop-types';
import { useTheme } from 'styled-components';
import { Container } from './styles';
import InputCurrency from '../InputCurrency';

/**
 * @typedef ButtonProps
 * @property {func} handleClickAdd funcao executada ao clicar no +
 * @property {func} handleClickRemove funcao executada ao clicar no -
 * @property {value} number valor que atual
 * @property {React.CSSProperties} style style do componente
 * @property {podeFracionar} boolean define se pode ou não fracionar
 * @property {func} handleSetValue funcao para definir um valor manualmente
 */

/**
 * @func ButtonQtd
 * @param {ButtonProps} props
 */
function ButtonQtd(props) {
  ButtonQtd.propTypes = {
    handleClickAdd: PropTypes.func,
    handleClickRemove: PropTypes.func,
    handleSetValue: PropTypes.func,
    style: PropTypes.any,
    value: PropTypes.number,
    podeFracionar: PropTypes.bool,
  };

  const {
    value,
    handleClickAdd,
    handleClickRemove,
    style,
    podeFracionar,
    handleSetValue,
  } = props;

  const theme = useTheme();

  return (
    <Container style={{ ...style }}>
      <button
        onClick={() => {
          if (handleClickRemove) {
            handleClickRemove();
          }
        }}
      >
        -
      </button>
      {podeFracionar ? (
        <InputCurrency
          maxLength={10}
          onlyPositive
          digits={3}
          style={{
            textAlign: 'center',
            backgroundColor: theme.colors.backgroundCardapio,
            borderWidth: 0,
            fontSize: 18,
            fontFamily: theme.fonts.fontSubttitle,
          }}
          value={value || 0}
          onChange={(e, v) => {
            if (handleSetValue) {
              handleSetValue(v);
            }
          }}
        />
      ) : (
        <span>{value || 0}</span>
      )}

      <button
        onClick={() => {
          if (handleClickAdd) {
            handleClickAdd();
          }
        }}
      >
        +
      </button>
    </Container>
  );
}

ButtonQtd.defaultPropos = {
  podeFracionar: false,
};
export default ButtonQtd;
