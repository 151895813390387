import { lighten } from 'polished';
import styled from 'styled-components';

export const Container = styled.div`
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  height: 40px;
  min-width: 150px;
  max-width: 150px;
  background-color: ${props => props.theme.colors.backgroundCardapio};
  border-radius: ${({ theme }) => theme.borderRadiusSmall};
  padding-left: 5px;
  padding-right: 5px;

  font-family: ${({ theme }) => theme.fonts.fontSubttitle};
  font-size: 18px;

  button {
    width: 25px;
    height: 25px;
    font-size: inherit;
    font-family: inherit;
    border-width: 0;
    background-color: ${props => props.theme.colors.backgroundCardapio};
    border-radius: ${({ theme }) => theme.borderRadiusCirculo};
    color: #000000;

    /* :hover {
      background-color: ${props => lighten(0.05, props.theme.colors.backgroundCardapio)};
      border-color: ${props => lighten(0.05, props.theme.colors.backgroundCardapio)};
    } */

    :active {
      background-color: ${props => lighten(0.08, props.theme.colors.backgroundCardapio)};
      border-color: ${props => lighten(0.08, props.theme.colors.backgroundCardapio)};
    }
  }

  span {
    flex: 1;
    text-align: center;
  }
`;
