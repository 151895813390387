import styled from 'styled-components';

export const Container = styled.div`
  .head {
    padding-right: 10px;
    padding-left: 10px;
    border: 0;
    border-bottom-width: 1px;
    border-style: solid;
    border-color: ${({ theme }) => theme.colors.borderColor};
  }
  .body {
    min-height: 500px;
    max-height: 55vh;
    padding-right: 20px;
    padding-left: 20px;
    padding-top: 10px;
    background-color: ${({ theme }) => theme.colors.backgroundCard};

    overflow-y: auto;
    scrollbar-width: 1px;
    overflow-x: hidden;

    h4 {
      font-family: ${({ theme }) => theme.fonts.regular};
    }
  }

  .footer {
    margin: 10px 20px 10px 20px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  .div-andamento {
    height: 50px;
    padding: 10px 15px 15px 15px;
    /* background-color: #faa; */

    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .item-andamento {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 1;

    .marcacao {
      background-color: ${({ theme }) => theme.colors.borderColor};
      border-radius: ${({ theme }) => theme.borderRadiusCirculo};
      border-width: 0;
      height: 20px;
      width: 20px;
      text-align: center;
      font-size: 14px;
      font-family: ${({ theme }) => theme.fonts.fontSubttitle};
    }

    .texto {
      font-size: 12px;
    }

    .item-marcado {
      background-color: ${({ theme }) => theme.colors.primary};
      color: ${({ theme }) => theme.colors.backgroundCard};
    }
  }

  .linha {
    border-top: none;
    border-bottom: 1px dashed;
  }

  .footer-conta {
    margin-top: 12px;
    font-family: 'Poppins';
    font-size: 13px;

    /* height: 260px; */
    display: flex;
    flex-direction: column;

    .div-valores {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin: 0 0px 0 0px;
    }

    .valor-total {
      font-family: 'PoppinsSemiBold';
      font-size: 16px;
    }

    .valor {
      color: ${({ theme }) => theme.colors.description};
    }
  }

  .entrega-conta {
    font-size: 13px;

    .titulo {
      font-family: 'PoppinsSemiBold';
      font-size: 15px;
    }

    .nome {
      font-family: 'PoppinsSemiBold';
    }

    .telefone {
      margin-left: 13px;
      display: block;
      font-family: 'PoppinsSemiBold';
      color: ${({ theme }) => theme.colors.description};
    }
    .div-endereco {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }

    .endereco {
      font-family: 'PoppinsSemiBold';
      flex: 1;
    }

    .endereco-bairro-municipio {
      margin-left: 13px;
      display: block;
      font-family: 'PoppinsSemiBold';
      color: ${({ theme }) => theme.colors.description};
    }
  }

  .botao-editar {
    background-color: ${({ theme }) => theme.colors.backgroundCard};
    border-color: ${({ theme }) => theme.colors.description};
    border-width: 1px;
    border-style: solid;
    border-radius: 4px;
    color: ${({ theme }) => theme.colors.description};
    font-size: 24px;

    cursor: pointer;
  }

  .div-produtos {
    font-family: ${({ theme }) => theme.fonts.regular};
    font-size: 13px;

    .quantidade {
      border-radius: 4px;
      border-width: 1px;
      border-style: solid;
      border-color: ${({ theme }) => theme.colors.borderColor};
      margin-bottom: 2px;

      font-size: 14px;

      min-width: 40px;
      text-align: center;
      margin-right: 5px;
    }
    .valor {
      /* flex: 1; */
      text-align: end;
      font-size: 13px;
      font-family: ${({ theme }) => theme.fonts.fontSubttitle};
    }

    .descricao {
      flex: 1;
      font-size: 15px;
      font-family: ${({ theme }) => theme.fonts.fontSubttitle};
      line-height: 16px;
    }

    .detalhes {
    }
  }

  .itens {
    /* margin: 10px; */
    margin: 0;
  }

  .div-forma-pagamento {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 15px;
    margin-top: 10px;

    .forma-pagamento {
      flex: 1;
      display: flex;
      flex-direction: column;

      .nome {
        /* margin-left: 13px; */
        font-family: ${({ theme }) => theme.fonts.regular};
        color: ${({ theme }) => theme.colors.description};
      }
    }
  }
`;
