import { lighten } from 'polished';
import styled from 'styled-components';

export const Container = styled.div`
  margin-top: 20px;
  background-color: ${({ theme }) => theme.colors.backgroundSacola};
  min-height: 100px;
  border-radius: ${({ theme }) => theme.borderRadius};
  display: flex;
  flex-direction: column;

  @media screen and (min-width: 800px) {
    .botao-voltar {
      display: none;
    }
  }

  .header {
    padding: 10px;
    height: 60px;
    background-color: ${({ theme }) => theme.colors.primary};
    border-top-left-radius: ${({ theme }) => theme.borderRadius};
    border-top-right-radius: ${({ theme }) => theme.borderRadius};
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    font-size: 18px;

    color: ${({ theme }) => theme.colors.backgroundCard};

    .valor {
      font-family: 'PoppinsBold';
    }
    .qtd-itens {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
    }

    img {
      height: 18px;
      width: 18px;
      margin-right: 5px;
    }
  }

  .title-body {
    font-family: 'PoppinsSemiBold';
    font-size: 15px;
  }

  .body {
    flex: 1;
    margin: 10px;

    @media screen and (max-width: 800px) {
      max-height: 55vh;

      overflow-y: auto;
      scrollbar-width: 1px;
      overflow-x: hidden;

      ::-webkit-scrollbar {
        display: none;
      }
    }
  }
  .footer {
    font-family: 'Poppins';
    font-size: 13px;

    /* height: 260px; */
    display: flex;
    flex-direction: column;

    hr {
      border-top: none;
      border-bottom: 1px dashed;
    }
    .div-valores {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin: 0 10px 0 10px;
    }

    .valor-total {
      font-family: 'PoppinsSemiBold';
      font-size: 16px;
    }

    .valor {
      color: ${({ theme }) => theme.colors.description};
    }
  }

  .card-item {
    font-family: 'PoppinsSemiBold';
    font-size: 15px;
    display: flex;
    flex-direction: column;
    min-height: 120px;
    border-radius: ${({ theme }) => theme.borderRadiusSmall};
    background: ${props => props.theme.colors.backgroundCard};

    border-color: ${({ theme }) => lighten(0.25, theme.colors.backgroundRodape)};
    border-width: 1px;
    border-style: solid;
    margin-bottom: 10px;

    .div-descricao-valor {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin: 10px;
      margin-bottom: 0;
    }
    .quantidade {
      margin-right: 5px;
    }
    .descricao {
      color: ${({ theme }) => theme.colors.primary};
    }

    .valor-subtotal {
      font-size: 13px;
      flex: 1;
      justify-self: center;
      text-align: end;
    }

    .div-observacao-imagem {
      flex: 1;
      padding-right: 5px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      font-family: 'Poppins';
      color: ${({ theme }) => theme.colors.description};
      font-size: 11px;
      margin: 5px;
      margin-top: 0;
      flex: 1;

      .image-item {
        margin-left: 8px;
        align-self: center;
        height: 65px;
        width: 65px;
        object-fit: cover;
        border-radius: ${({ theme }) => theme.borderRadiusSmall};
      }
    }

    .rodape {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding-left: 20px;
      height: 30px;
      background-color: ${({ theme }) => theme.colors.primary};
      border-bottom-right-radius: ${({ theme }) => theme.borderRadiusSmall};
      border-bottom-left-radius: ${({ theme }) => theme.borderRadiusSmall};

      .buttons {
        background: ${({ theme }) => theme.colors.primary};
        border-color: ${({ theme }) => theme.colors.backgroundCard};
        margin: 0;
        height: 22px;
        font-size: 13px;
        border-radius: 5px;
        margin-right: 10px;

        :hover {
          background: ${props => lighten(0.05, props.theme.colors.primary)};
          border-color: ${props => lighten(0.05, props.theme.colors.backgroundCard)};
        }

        :active {
          background: ${props => lighten(0.08, props.theme.colors.primary)};
          border-color: ${props => lighten(0.08, props.theme.colors.backgroundCard)};
        }
      }
    }
  }
`;
