/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect, useRef } from 'react';
import { InputMask } from 'primereact/inputmask';
import PropTypes from 'prop-types';
// import { Container } from './styles';

const InputMaskSp = React.forwardRef((props, ref) => {
  InputMaskSp.propTypes = {
    required: PropTypes.bool,
    className: PropTypes.string,
    disabled: PropTypes.bool,
  };

  InputMaskSp.defaultProps = {
    required: false,
    className: '',
    disabled: false,
  };

  const { value, required, disabled, className, ...rest } = props;

  const classNames = required && !disabled ? `p-error ${className || ''}` : className || '';

  const input = ref || useRef();
  // gambiarra pois o InputMask não permite adicionar autocomplete
  useEffect(() => {
    try {
      input.current.input.autocomplete = 'none';
    } catch (err) {
      //
    }
  }, [input]);

  return (
    <InputMask
      ref={input}
      {...rest}
      disabled={disabled}
      value={value || ''}
      className={classNames}
    />
  );
});

export default InputMaskSp;
