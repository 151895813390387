import styled from 'styled-components';

export const Container = styled.div`

  @media screen and (min-width: 1365px) {
     padding-right: calc((100% - 1365px)/2);
     padding-left: calc((100% - 1365px)/2);
     background-color: ${({ theme }) => theme.colors.backgroundCardapio};
  }

  .div-desktop {
    height: ${({ theme }) => `${theme.heightRodape}px`};
    border-top-width: 7px;
    border-style: solid;
    border-color: #cdcdcd;

    color: #fff;
    font-size: 11px;
    background: #bababa;

    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: space-between;

    padding: 10px 30px 10px 30px;

    @media screen and (max-width: 800px) {
      display: none;
    }

    /* position: fixed;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 2; */

    .div-inf-empresa {
      display: flex;
      flex-direction: column;
      justify-content: center;

      img {
        width: 150px;
      }
    }

    .div-inf-redes {
      font-size: 15px;
      flex: 0.8;
      max-width: 600px;
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;

      .div-privacidade-contato {
        flex: 1;
        a {
          color: #fff;
        }

        flex: 1;
        flex-direction: row;
        justify-content: space-evenly;
        display: flex;
      }

      .div-redes {
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        align-items: center;
        justify-content: center;

        span {
          text-align: center;
        }

        .div-icones {
          flex: 1;
          display: flex;
          flex-direction: row;
          justify-content: space-evenly;
          align-items: center;

          img {
            margin: 2px;
            width: 35px;
          }
        }
      }
    }
  }

  .div-mobile {
    height: ${({ theme }) => `${theme.heightRodapeMob}px`};
    position: fixed;
    bottom: 0;
    right: -3px;
    left: -3px;
    z-index: 2;
    background-color: ${({ theme }) => theme.colors.primary};
    color: ${({ theme }) => theme.colors.backgroundCard};
    padding: 5px 40px 5px 40px;

    border-width: 0;
    border-top-width: 5px;
    border-style: solid;
    border-color: #e7e7e7;

    font-size: 14x;
    font-family: ${({ theme }) => theme.fonts.fontSubttitle};

    display: flex;
    justify-content: space-between;
    align-items: center;

    img {
      width: 18px;
    }

    @media screen and (min-width: 800px) {
      display: none;
    }

    .item {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      cursor: pointer;
    }
  }

  .div-sacola {
    height: ${({ theme }) => `${theme.heightBarraSacola}px`};
    position: fixed;
    bottom: ${({ theme }) => `${theme.heightRodapeMob}px`};
    right: 40px;
    left: 40px;
    z-index: 2;
    background-color: ${({ theme }) => theme.colors.barraSacola};
    color: ${({ theme }) => theme.colors.backgroundCard};
    padding: 5px 20px 5px 20px;
    margin-bottom: 10px;

    cursor: pointer;

    border-width: 1px;
    border-style: solid;
    border-color: ${({ theme }) => theme.colors.barraSacola};
    border-radius: 40px;

    font-size: 16px;
    font-family: ${({ theme }) => theme.fonts.fontSubttitle};

    display: flex;
    justify-content: space-between;
    align-items: center;

    img {
      width: 25px;
      height: 25px;
    }

    @media screen and (min-width: 800px) {
      display: none;
    }

    .div-icon {
      display: flex;
      span {
        margin-top: 12px;
        margin-left: -9px;
        font-size: 11px;
        color: ${({ theme }) => theme.colors.barraSacola};
        background-color: ${({ theme }) => theme.colors.backgroundCard};
        /* font-family: ${({ theme }) => theme.fonts.regular}; */
        border-radius: 50%;
        width: 16px;
        height: 16px;
        text-align: center;
      }
    }
  }
`;
