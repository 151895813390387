import React, { useState, useCallback, useEffect, useMemo } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

import { toast } from 'react-toastify';
import { MultiSelect } from 'primereact/multiselect';
import { Dialog } from 'primereact/dialog';
import { Container } from './styles';
import BarraTitulo from '../../components/BarraTitulo';
import PainelFiltro from '../../components/PainelFiltro';
import PedidoService from '../../services/PedidoService';
import history from '../../services/history';
import { StatusTela } from '../../util/Tipos';
import {
  buscaPageParams,
  errorHandle,
  validateFields,
  padLeft,
  isNumber,
  formatDate,
  formatFloat,
  strToDate,
  cloneObj,
} from '../../util/functions';
import LabelSp from '../../components/LabelSp';
import { PedidoCabecalhoModel } from '../../util/Models';
import { cfgPtBr } from '../../config/Constantes';

import { showMessage } from '../../components/MessageDialog';
import InputTextSp from '../../components/InputTextSp';
import CalendarSp from '../../components/CalendarSp';

import DropdownSp from '../../components/DropdownSp';
import AuthService from '../../services/AuthService';
import BotaoMenuGrid from '../../components/BotaoMenuGrid';
import ButtonSp from '../../components/ButtonSp';
import FormaPagamentoService from '../../services/FormaPagamentoService';
import InputMaskSp from '../../components/InputMaskSp';
import InputTextAreaSp from '../../components/InputTextareaSp';

export default function Pedido(props) {
  // useMemo
  const pageParams = useMemo(
    () => buscaPageParams(props.match.params, props.location.search),
    [props.location.search, props.match.params]
  );
  const filterService = useMemo(() => PedidoService.getFilter(), []);

  // useStates
  const backTo = pageParams.backTo || '/painel/pedidos';
  const [filter, setFilter] = useState(filterService);
  const [pedidos, setPedidos] = useState([]);
  const [pageLimit, setPageLimit] = useState(filterService.limit);
  const [first, setFirst] = useState(0);
  const [totalRecords, setTotalRecords] = useState(0);
  const [pedido, setPedido] = useState(new PedidoCabecalhoModel());
  const [formasPagamento, setFormasPagamento] = useState([]);
  const [dialogSituacaoVisivel, setDialogSituacaoVisivel] = useState(false);
  const [pedidoResumido, setPedidoResumido] = useState({ id: null, situacao: '' });

  // funcoes
  const handleBuscar = useCallback(async (_filter, _page, resetPage = true) => {
    _filter.page = _page;
    const filtro = cloneObj(_filter);

    if (filtro.listaSituacao.length === 0) {
      filtro.listaSituacao = ['-1']; // para nao filtrar nenhum
    }

    try {
      const result = await PedidoService.findAll(filtro);
      result.items.forEach(p => {
        p.dataHora = `${formatDate(strToDate(p.data), 'dd/MM/yyyy')} ${p.hora.substring(
          0,
          5
        )}`;
        p.corSituacao = buscaCorStatus(p.situacao);
        p.nomeSituacao = buscaNomeStatus(p.situacao);
      });

      setPedidos(result.items);
      setPageLimit(result.limit);
      setTotalRecords(result.totalRecords);
      if (resetPage) {
        setFirst(0);
      }
    } catch (err) {
      errorHandle(err);
    }
  }, []);

  const carregaRegistro = useCallback(async (_id, resumido = false) => {
    try {
      const retorno = await PedidoService.findById(_id, resumido);
      retorno.dataHora = `${formatDate(
        strToDate(retorno.data),
        'dd/MM/yyyy'
      )} ${retorno.hora.substring(0, 5)}`;

      setPedido(retorno);
    } catch (err) {
      setPedido(new PedidoCabecalhoModel());
      errorHandle(err);
    }
  }, []);

  const carregaFormasPagamento = useCallback(async () => {
    const retorno = await FormaPagamentoService.findAll();
    const formas = retorno.items.map(f => {
      return { value: f.id, label: f.nome };
    });
    setFormasPagamento(formas);
  }, []);

  const alterarSituacao = useCallback(async _id => {
    try {
      const retorno = await PedidoService.findById(_id, true);
      setPedidoResumido(retorno);
      setDialogSituacaoVisivel(true);
    } catch (err) {
      errorHandle(err);
    }
  }, []);

  // functions
  function buscaCorStatus(status) {
    switch (status) {
      case 'A':
        return corAguardando;
      case 'P':
        return corPreparando;
      case 'E':
        return corEntregando;
      case 'F':
        return corFinalizado;
      case 'C':
        return corCancelado;
      default:
        return corAguardando;
    }
  }
  function buscaNomeStatus(status) {
    switch (status) {
      case 'A':
        return 'Aguardando';
      case 'P':
        return 'Preparando';
      case 'E':
        return 'Entregando';
      case 'F':
        return 'Finalizado';
      case 'C':
        return 'Cancelado';
      default:
        return 'Aguardando';
    }
  }
  function onPage(event) {
    const pagina = event.first / event.rows;
    setFirst(event.first);
    handleBuscar(filter, pagina, false);
  }

  function handleVoltar() {
    if (pageParams.statusTela === StatusTela.stVisualizar) {
      history.push(backTo);
      handleBuscar(filter);
    } else {
      showMessage('Confirmação', 'Abandonar mudanças?', idx => {
        if (idx === 1) {
          history.push(backTo);
          handleBuscar(filter);
        }
      });
    }
  }

  function handleSalvar() {
    if (pageParams.statusTela === StatusTela.stVisualizar) {
      history.push(backTo);
    } else {
      salvarRegistro();
    }
  }

  async function handleSalvaSituacao() {
    try {
      await PedidoService.updateSituacao(pedidoResumido.id, pedidoResumido.situacao);
      setDialogSituacaoVisivel(false);
      toast.success('Pedido atualizado com sucesso.');
      handleBuscar(filter);
    } catch (err) {
      errorHandle(err);
    }
  }

  function modoConsulta() {
    return pageParams.statusTela === StatusTela.stVisualizar;
  }

  async function salvarRegistro() {
    try {
      if (pageParams.statusTela === StatusTela.stInserir) {
        await PedidoService.insert(pedido);
      } else {
        await PedidoService.update(pedido);
      }
      toast.success('Registro salvo com sucesso.');
      history.push(backTo);
      handleBuscar(filter);
    } catch (err) {
      errorHandle(err);
    }
  }
  // useEffects
  useEffect(() => {
    carregaFormasPagamento();
  }, [carregaFormasPagamento]);

  useEffect(() => {
    if (pageParams.statusTela === StatusTela.stPesquisar) {
      handleBuscar(filter, 0, 800);
    }
    // desativado para evitar que a cada vez que o usuario digitasse o sistema buscasse
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      pageParams.statusTela === StatusTela.stAlterar ||
      pageParams.statusTela === StatusTela.stVisualizar
    ) {
      carregaRegistro(pageParams.idSelecionado);
    } else if (pageParams.statusTela === StatusTela.stInserir) {
      const novo = new PedidoCabecalhoModel();
      setPedido(novo);
    }
  }, [
    carregaRegistro,
    filterService,
    handleBuscar,
    pageParams.idSelecionado,
    pageParams.statusTela,
  ]);

  // exemplo de componentWillUnmount
  // useEffect(() => {
  //   return function cleanup() {
  //
  //   };
  // }, [filter]);

  // renders
  return (
    <Container className="container-page">
      <div className="p-grid">
        <BarraTitulo title="Pedidos" />
        {pageParams.statusTela === StatusTela.stPesquisar
          ? renderPesquisa()
          : renderCadastro()}
      </div>
    </Container>
  );

  function renderPesquisa() {
    return (
      <>
        <form
          onSubmit={e => {
            e.preventDefault();
            handleBuscar(filter);
          }}
        >
          <PainelFiltro className="p-grid p-col-12" visible="true">
            <div className="p-grid p-col-12 p-sm-5 p-lg-5" style={{ margin: 0, padding: 0 }}>
              <div className="p-col-6 p-fluid">
                <LabelSp>Período de </LabelSp>
                <CalendarSp
                  required
                  dateFormat="dd/mm/yy"
                  readOnlyInput
                  locale={cfgPtBr}
                  value={filter.dataInicio}
                  viewDate={filter.dataInicio}
                  onChange={e => setFilter({ ...filter, dataInicio: e.value })}
                />
              </div>

              <div className="p-col-6 p-fluid">
                <LabelSp>Até</LabelSp>
                <CalendarSp
                  required
                  dateFormat="dd/mm/yy"
                  readOnlyInput
                  locale={cfgPtBr}
                  value={filter.dataFim}
                  viewDate={filter.dataFim}
                  onChange={e => setFilter({ ...filter, dataFim: e.value })}
                />
              </div>
            </div>
            <div className="p-col-12 p-sm-7 p-lg-7 p-fluid">
              <LabelSp>Situação</LabelSp>
              <MultiSelect
                options={[
                  { label: 'Aguardando', value: 'A' },
                  { label: 'Preparando', value: 'P' },
                  { label: 'Entregando', value: 'E' },
                  { label: 'Finalizado', value: 'F' },
                  { label: 'Cancelado', value: 'C' },
                ]}
                maxSelectedLabels={3}
                value={filter.listaSituacao}
                onChange={e => {
                  setFilter({ ...filter, listaSituacao: e.value });
                }}
              />
            </div>
            <div className="p-col-8 p-sm-5 p-lg-5 p-fluid">
              <LabelSp>Cliente</LabelSp>
              <InputTextSp
                maxLength={40}
                value={filter.nomeCliente}
                onChange={e => {
                  setFilter({ ...filter, nomeCliente: e.target.value });
                }}
              />
            </div>
            <div className="p-col-4 p-sm-2 p-lg-2 p-fluid">
              <LabelSp>Id</LabelSp>
              <InputTextSp
                maxLength={7}
                value={filter.id}
                onChange={e => {
                  if (isNumber(e.target.value)) {
                    setFilter({ ...filter, id: e.target.value });
                  }
                }}
              />
            </div>
            <div className="p-col-4 p-sm-2 p-lg-2 p-fluid">
              <LabelSp>Sincronizado</LabelSp>
              <DropdownSp
                options={[
                  { label: 'Sim', value: 'True' },
                  { label: 'Não', value: 'False' },
                ]}
                showClear
                maxSelectedLabels={3}
                value={filter.sincronizado}
                onChange={e => {
                  setFilter({ ...filter, sincronizado: e.value });
                }}
              />
            </div>
            <div className="p-col-8 p-sm-3 p-lg-3 p-fluid">
              <LabelSp>Forma Pagto</LabelSp>
              <DropdownSp
                options={formasPagamento}
                showClear
                value={filter.idFormaPagamento}
                onChange={e => {
                  setFilter({ ...filter, idFormaPagamento: e.value });
                }}
              />
            </div>
          </PainelFiltro>
          <div className="p-col-12 p-lg-12 legenda-consulta">
            <div className="legenda desktop-visible">
              <div style={{ background: corAguardando }}>A</div>
              <span>Aguardando</span>

              <div style={{ background: corPreparando }}>A</div>
              <span>Preparando</span>

              <div style={{ background: corEntregando }}>A</div>
              <span>Entregando</span>

              <div style={{ background: corFinalizado }}>A</div>
              <span>Finalizado</span>

              <div style={{ background: corCancelado }}>A</div>
              <span>Cancelado</span>
            </div>
            <ButtonSp
              className="p-button-secondary buttons"
              icon="pi pi-search"
              title="Buscar"
              type="submit"
              label="Buscar"
            />
          </div>
        </form>
        {renderAlteraSituacao()}
        <div className="p-col-12 p-fluid">
          <DataTable
            value={pedidos}
            style={{ marginBottom: '2px' }}
            paginator
            rows={pageLimit}
            lazy
            responsive
            totalRecords={totalRecords}
            first={first}
            onPage={onPage}
          >
            <Column
              field="id"
              header="St."
              className="grid-col-status"
              body={rowData => {
                return (
                  <>
                    <div className="legenda desktop-visible">
                      <div style={{ background: rowData.corSituacao }}>{rowData.id}</div>
                    </div>
                    <span className="mobile-visible" style={{ color: rowData.corSituacao }}>
                      {rowData.nomeSituacao}
                    </span>
                  </>
                );
              }}
            />
            <Column
              field="id"
              body={rowData => padLeft(rowData.id, 6)}
              header="Id"
              className="grid-col-id"
            />
            <Column field="dataHora" header="Data" className="grid-col-data-hora" />
            <Column
              field="id"
              className="grid-col"
              header="Cliente"
              style={{ width: 150 }}
              body={rowData => rowData.nomeCliente}
            />
            <Column
              className="grid-col"
              header="Local Entrega"
              body={rowData => rowData.localEntrega}
            />
            <Column
              className="grid-col-p-5"
              style={{ width: 130 }}
              header="Forma Pagto"
              body={rowData => rowData.nomeFormaPagamento}
            />
            <Column
              body={rowData => formatFloat(rowData.valor, 2)}
              header="Valor"
              className="grid-col-val-p"
            />
            <Column
              className="gid-col-acoes-35"
              bodyStyle={{ textAlign: 'end' }}
              body={renderButtonOp}
            />
          </DataTable>
        </div>
      </>
    );
  }

  function renderButtonOp(rowData) {
    return (
      <BotaoMenuGrid
        labels={['Visualizar', 'Alterar Situação']}
        icons={['pi pi-search', 'pi pi-pencil']}
        handles={[
          () => history.push(`/painel/pedidos/${rowData.id}?visualizar`),
          () => alterarSituacao(rowData.id),
        ]}
        disableds={[false, !AuthService.checkRoles('PEDIDO_ALTERAR')]}
      />
    );
  }

  function renderDescricaoProduto(rowData) {
    const listaAdicionais = rowData.pedidoDetalhe;
    const descricao = rowData.descricao.toUpperCase().substring(0, 17);
    return (
      <div>
        <strong>{descricao}</strong>
        {listaAdicionais
          ? listaAdicionais.map(a => {
              return (
                <div
                  key={`add-${rowData.numeroItem}-${a.idProduto}`}
                  style={{ marginLeft: 10 }}
                >{`+${a.quantidade}${a.unidade} x ${a.descricao}`}</div>
              );
            })
          : null}
        {rowData.pedidoDetalheObs.map(o => {
          return (
            <div
              key={`obs-${rowData.numeroItem}-${o.idObservacaoProduto}`}
              style={{ marginLeft: 10 }}
            >{`*${o.descricaoObservacaoProduto}`}</div>
          );
        })}
        {rowData.observacao ? (
          <div style={{ marginLeft: 10 }}>{`*${rowData.observacao}`}</div>
        ) : null}
      </div>
    );
  }

  function renderCadastro() {
    let { dataHoraSincronizacao } = pedido;

    if (dataHoraSincronizacao) {
      dataHoraSincronizacao = formatDate(dataHoraSincronizacao, 'dd/MM/yyyy HH:mm');
    }

    return (
      <>
        <div
          className="p-grid p-col-7 p-sm-4 p-lg-4 p-fluid"
          style={{ margin: 0, padding: 0 }}
        >
          <div className="p-col-4 p-fluid">
            <LabelSp>Id</LabelSp>
            <InputTextSp
              value={padLeft(pedido.id, 6)}
              maxLength={7}
              required
              disabled={modoConsulta()}
              onChange={e => {
                setPedido({ ...pedido, id: e.target.value });
              }}
            />
          </div>

          <div className="p-col-8 p-fluid">
            <LabelSp>Data</LabelSp>
            <InputTextSp
              value={pedido.dataHora}
              maxLength={7}
              required
              disabled={modoConsulta()}
            />
          </div>
        </div>

        <div className="p-col-5 p-sm-3 p-lg-2 p-fluid">
          <LabelSp>Telefone</LabelSp>
          <InputMaskSp
            mask="(99) 99999-9999"
            disabled={modoConsulta()}
            value={pedido.pedidoEntrega.telefone}
          />
        </div>

        <div className="p-col-12 p-sm-5 p-lg-6 p-fluid">
          <LabelSp>Nome do Cliente</LabelSp>
          <InputTextSp
            value={pedido.pedidoEntrega.nome}
            maxLength={100}
            required
            disabled={modoConsulta()}
          />
        </div>

        <div className="p-col-12 p-sm-5 p-lg-5 p-fluid">
          <LabelSp>Local de Entrega</LabelSp>
          <InputTextSp
            value={pedido.localEntrega}
            maxLength={100}
            required
            disabled={modoConsulta()}
          />
        </div>

        <div className="p-col-6 p-sm-4 p-lg-4 p-fluid">
          <LabelSp>Forma de Pagamento</LabelSp>
          <InputTextSp
            value={pedido.nomeFormaPagamento}
            maxLength={100}
            required
            disabled={modoConsulta()}
          />
        </div>

        <div className="p-col-6 p-sm-3 p-lg-3 p-fluid">
          <LabelSp>Data Sincro.</LabelSp>
          <InputTextSp
            value={dataHoraSincronizacao}
            maxLength={30}
            required
            disabled={modoConsulta()}
          />
        </div>

        <div className="p-col-4 p-sm-3 p-lg-2 p-fluid">
          <LabelSp>Vr. SubTotal</LabelSp>
          <InputTextSp
            style={{ textAlign: 'end' }}
            value={formatFloat(pedido.valorSubtotal, 2)}
            maxLength={12}
            required
            disabled={modoConsulta()}
          />
        </div>

        <div className="p-col-4 p-sm-3 p-lg-2 p-fluid">
          <LabelSp>Vr. Entrega</LabelSp>
          <InputTextSp
            style={{ textAlign: 'end' }}
            value={formatFloat(pedido.valorEntrega, 2)}
            maxLength={12}
            required
            disabled={modoConsulta()}
          />
        </div>

        <div className="p-col-4 p-sm-3 p-lg-2 p-fluid">
          <LabelSp>Vr. Total</LabelSp>
          <InputTextSp
            style={{ textAlign: 'end' }}
            value={formatFloat(pedido.valor, 2)}
            maxLength={12}
            required
            disabled={modoConsulta()}
          />
        </div>

        <div className="p-col-12 p-fluid">
          <LabelSp>Observação</LabelSp>
          <InputTextAreaSp
            value={pedido.observacao}
            maxLength={3000}
            required
            disabled={modoConsulta()}
          />
        </div>

        {/* produtos */}
        <div className="p-col-12 p-fluid" style={{ paddingRight: 0, paddingLeft: 0 }}>
          <DataTable value={pedido.pedidoDetalhe} style={{ marginBottom: '2px' }}>
            <Column
              field="descricao"
              headerStyle={{ textAlign: 'start' }}
              className="grid-col"
              header="Descricao"
              body={renderDescricaoProduto}
            />
            <Column
              field="quantidade"
              headerStyle={{ textAlign: 'center' }}
              className="grid-col-qtd-int"
              header="Qtd"
              body={rowData => formatFloat(rowData.quantidade, 0)}
            />
            <Column
              field="valorUnitario"
              headerStyle={{ textAlign: 'end' }}
              className="grid-col-val-p"
              header="Vr. Tot."
              body={rowData => formatFloat(rowData.valorTotal, 2)}
            />
          </DataTable>
        </div>

        <div className="p-col-12 p-lg-12" style={{ textAlign: 'end' }}>
          {!modoConsulta() ? (
            <ButtonSp
              className="p-button-success"
              icon="pi pi-save"
              label="Salvar"
              disabled={!validateFields(pedido, ['nome', 'descricao'])}
              showConfirmation
              onClick={handleSalvar}
            />
          ) : null}
          <ButtonSp
            className="p-button-secondary"
            label="Voltar"
            icon="pi pi-chevron-circle-left"
            onClick={handleVoltar}
          />
        </div>
      </>
    );
  }

  function renderAlteraSituacao() {
    function renderFooter() {
      return (
        <div>
          <ButtonSp
            label="Confirmar"
            icon="pi pi-check"
            disabled={!validateFields(pedido, ['situacao'])}
            onClick={() => {
              handleSalvaSituacao();
            }}
          />
          <ButtonSp
            label="Cancelar"
            icon="pi pi-times"
            onClick={() => {
              setDialogSituacaoVisivel(false);
            }}
            className="p-button-secondary"
          />
        </div>
      );
    }

    return (
      <Dialog
        closable={false}
        header="Alteração de Situação"
        footer={renderFooter()}
        visible={dialogSituacaoVisivel}
        style={{ minWidth: '350px', maxWidth: '400px', width: '95%' }}
        modal
        onHide={() => {
          setDialogSituacaoVisivel(false);
        }}
      >
        <div className="p-grid">
          <div className="p-col-12 p-sm-12 p-lg-12 p-fluid">
            <LabelSp>Situação</LabelSp>
            <DropdownSp
              options={[
                { label: 'Aguardando', value: 'A' },
                { label: 'Preparando', value: 'P' },
                { label: 'Entregando', value: 'E' },
                { label: 'Finalizado', value: 'F' },
                { label: 'Cancelado', value: 'C' },
              ]}
              required
              value={pedidoResumido.situacao}
              onChange={e => {
                setPedidoResumido({ ...pedidoResumido, situacao: e.value });
              }}
            />
          </div>
        </div>
      </Dialog>
    );
  }
}

const corAguardando = '#e10000';
const corPreparando = '#ece936';
const corEntregando = '#ff854a';
const corFinalizado = '#00a629';
const corCancelado = '#404c51';
