import React, { useState, useCallback, useEffect, useMemo } from 'react';

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { toast } from 'react-toastify';
import { Container } from './styles';
import BarraTitulo from '../../components/BarraTitulo';
import PainelFiltro from '../../components/PainelFiltro';
import UsuarioService from '../../services/UsuarioService';
import AuthService from '../../services/AuthService';
import history from '../../services/history';
import { StatusTela } from '../../util/Tipos';
import {
  buscaPageParams,
  errorHandle,
  validateFields,
  validCNPJ,
  padLeft,
  isNumber,
} from '../../util/functions';
import LabelSp from '../../components/LabelSp';
import { UsuarioModel } from '../../util/Models';
import { showMessage } from '../../components/MessageDialog';
import InputTextSp from '../../components/InputTextSp';
import DropdownSp from '../../components/DropdownSp';
import PainelBotesCadastro from '../../components/PainelBotesCadastro';
import BotaoMenuGrid from '../../components/BotaoMenuGrid';
import ButtonSp from '../../components/ButtonSp';

export default function Usuario(props) {
  // useMemo
  const pageParams = useMemo(
    () => buscaPageParams(props.match.params, props.location.search),
    [props.location.search, props.match.params]
  );

  const filterService = useMemo(() => UsuarioService.getFilter(), []);
  const usuarioLogado = useMemo(() => AuthService.getUsuario(), []);

  // useStates
  const backTo = pageParams.backTo || '/painel/usuarios';

  const [filter, setFilter] = useState(filterService);
  const [usuarios, setUsuarios] = useState([]);
  const [pageLimit, setPageLimit] = useState(filterService.limit);
  const [first, setFirst] = useState(0);
  const [totalRecords, setTotalRecords] = useState(0);
  const [usuario, setUsuario] = useState(new UsuarioModel());

  // funcoes
  function modoConsulta() {
    return pageParams.statusTela === StatusTela.stVisualizar;
  }

  const handleBuscar = useCallback(async (_filter, _page) => {
    _filter.page = _page;

    try {
      const result = await UsuarioService.findAll(_filter);
      setUsuarios(result.items);
      setPageLimit(result.limit);
      setTotalRecords(result.totalRecords);
    } catch (err) {
      errorHandle(err);
    }
  }, []);

  const carregaRegistro = useCallback(async _id => {
    try {
      const retorno = await UsuarioService.findById(_id);

      setUsuario(retorno);
    } catch (err) {
      errorHandle(err);
    }
  }, []);

  function onPage(event) {
    const pagina = event.first / event.rows;
    setFirst(event.first);
    handleBuscar(filter, pagina);
  }

  // useCallbacks
  const excluirRegistro = useCallback(
    async _id => {
      try {
        await UsuarioService.delete(_id);
        toast.success('Registro excluído com sucesso.');
        handleBuscar(filterService);
      } catch (err) {
        errorHandle(err);
      }
    },
    [filterService, handleBuscar]
  );

  const confirmaExclusao = useCallback(
    idSelecionado => {
      showMessage('Confirmação', 'Confirma a exclusão do registro?', idx => {
        if (idx === 1) {
          excluirRegistro(idSelecionado);
        }
      });
    },
    [excluirRegistro]
  );

  // functions
  function handleVoltar() {
    if (pageParams.statusTela === StatusTela.stVisualizar) {
      history.push(backTo);
      handleBuscar(filter);
    } else {
      showMessage('Confirmação', 'Abandonar mudanças?', idx => {
        if (idx === 1) {
          history.push(backTo);
          handleBuscar(filter);
        }
      });
    }
  }

  function handleSalvar() {
    if (pageParams.statusTela === StatusTela.stVisualizar) {
      history.push(backTo);
    } else {
      salvarRegistro();
    }
  }

  async function salvarRegistro() {
    if (!validCNPJ(usuario.cnpj, true)) {
      toast.warn('CNPJ inválido.');
      return;
    }

    if (usuario.senha || usuario.confirmSenha) {
      if (usuario.senha !== usuario.confirmSenha) {
        toast.warn('Senha e confirmação não conferem.');
        return;
      }
    }

    try {
      if (pageParams.statusTela === StatusTela.stInserir) {
        await UsuarioService.insert(usuario);
      } else {
        await UsuarioService.update(usuario);
      }
      toast.success('Registro salvo com sucesso.');
      history.push(backTo);
      handleBuscar(filter);
    } catch (err) {
      errorHandle(err);
    }
  }

  // useEffects
  useEffect(() => {
    if (pageParams.statusTela === StatusTela.stPesquisar) {
      handleBuscar(filter, 0, 800);
    }
    // desativado para evitar que a cada vez que o usuario digitasse o sistema buscasse
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      pageParams.statusTela === StatusTela.stAlterar ||
      pageParams.statusTela === StatusTela.stVisualizar
    ) {
      carregaRegistro(pageParams.idSelecionado);
    } else if (pageParams.statusTela === StatusTela.stInserir) {
      const novo = new UsuarioModel();

      novo.tipoUsuario = 1;
      setUsuario(novo);
    }
  }, [carregaRegistro, pageParams.idSelecionado, pageParams.statusTela]);

  // renders
  return (
    <Container className="container-page">
      <div className="p-grid">
        <BarraTitulo title="Usuarios" />
        {pageParams.statusTela === StatusTela.stPesquisar
          ? renderPesquisa()
          : renderCadastro()}
      </div>
    </Container>
  );

  function renderPesquisa() {
    return (
      <>
        <form
          onSubmit={e => {
            e.preventDefault();
            handleBuscar(filter);
          }}
        >
          <PainelFiltro className="p-grid p-col-12" visible="true">
            <div className="p-col-12 p-sm-4 p-lg-4 p-fluid">
              <LabelSp>Nome</LabelSp>
              <InputTextSp
                value={filter.nome}
                onChange={e => {
                  setFilter({ ...filter, nome: e.target.value });
                }}
              />
            </div>
            <div className="p-col-12 p-sm-3 p-lg-3 p-fluid">
              <LabelSp>email</LabelSp>
              <InputTextSp
                value={filter.email}
                onChange={e => {
                  setFilter({ ...filter, email: e.target.value });
                }}
              />
            </div>
            <div className="p-col-12 p-sm-2 p-lg-2 p-fluid">
              <LabelSp>Nr. Contrato</LabelSp>
              <InputTextSp
                maxLength={6}
                value={filter.numeroContrato}
                onChange={e => {
                  if (isNumber(e.target.value)) {
                    setFilter({ ...filter, numeroContrato: e.target.value });
                  }
                }}
              />
            </div>
          </PainelFiltro>
          <PainelBotesCadastro
            handleInserir={() => {
              history.push('/painel/usuarios/inserir');
            }}
            handleBuscar={() => {
              handleBuscar(filter);
            }}
            buttonInserirDisabled={!AuthService.checkRoles('USUARIO_INSERIR')}
          />

          <div className="p-col-12 p-fluid">
            <DataTable
              value={usuarios}
              style={{ marginBottom: '2px' }}
              paginator
              rows={pageLimit}
              lazy
              responsive
              totalRecords={totalRecords}
              first={first}
              onPage={onPage}
            >
              <Column
                field="id"
                body={rowData => padLeft(rowData.id, 6)}
                header="Id"
                className="grid-col-id"
              />
              <Column field="nome" className="grid-col" header="Nome" />
              <Column field="email" className="grid-col" header="email" />
              <Column
                className="grid-col"
                style={{ width: 110 }}
                header="Tipo"
                body={rowData => {
                  if (rowData.tipoUsuario === 0) {
                    return 'Administrador';
                  }
                  if (rowData.tipoUsuario === 1) {
                    return 'Cliente';
                  }
                }}
              />

              <Column
                className="gid-col-acoes-35"
                bodyStyle={{ textAlign: 'end' }}
                body={renderButtonOp}
              />
            </DataTable>
          </div>
        </form>
      </>
    );
  }

  function renderButtonOp(rowData) {
    return (
      <BotaoMenuGrid
        handles={[
          () => history.push(`/painel/usuarios/${rowData.id}?visualizar`),
          () => history.push(`/painel/usuarios/${rowData.id}`),
          () => confirmaExclusao(rowData.id),
        ]}
        disableds={[
          false,
          !AuthService.checkRoles('USUARIO_ALTERAR'),
          !AuthService.checkRoles('USUARIO_EXCLUIR'),
        ]}
      />
    );
  }

  function renderCadastro() {
    return (
      <>
        <div className="p-col-12 p-sm-4 p-lg-4 p-fluid">
          <LabelSp>email</LabelSp>
          <InputTextSp
            disabled={modoConsulta()}
            value={usuario.email}
            maxLength={255}
            required
            onChange={e =>
              setUsuario({
                ...usuario,
                email: e.target.value,
              })
            }
          />
        </div>
        <div className="p-col-12 p-sm-4 p-lg-4 p-fluid">
          <LabelSp>Nome</LabelSp>
          <InputTextSp
            value={usuario.nome}
            maxLength={40}
            required
            disabled={modoConsulta()}
            onChange={e => {
              setUsuario({ ...usuario, nome: e.target.value });
            }}
          />
        </div>
        <div className="p-col-12 p-sm-4 p-lg-4 p-fluid">
          <LabelSp>Tipo</LabelSp>
          <DropdownSp
            required
            value={usuario.tipoUsuario}
            options={[
              { label: 'Administrador', value: 0 },
              { label: 'Cliente', value: 1 },
            ]}
            disabled={modoConsulta() || AuthService.getUsuario().tipoUsuario !== 0}
            filterInputAutoFocus={false}
            onChange={e => {
              setUsuario({ ...usuario, tipoUsuario: e.target.value });
              const nContrato = e.target.value !== 0 ? usuario.numeroContrato : null;
              setUsuario({
                ...usuario,
                numeroContrato: nContrato,
                tipoUsuario: e.target.value,
              });
            }}
          />
        </div>

        <div className="p-col-12 p-sm-2 p-lg-2 p-fluid">
          <LabelSp>Nr. Contrato</LabelSp>
          <InputTextSp
            required
            maxLength={6}
            disabled={
              modoConsulta() || usuarioLogado.tipoUsuario !== 0 || usuario.tipoUsuario === 0
            }
            value={usuario.numeroContrato}
            onChange={e => {
              if (isNumber(e.target.value)) {
                setUsuario({ ...usuario, numeroContrato: e.target.value });
              }
            }}
          />
        </div>

        <div className="p-col-6 p-sm-2 p-lg-2 p-fluid">
          <LabelSp>Senha</LabelSp>
          <InputTextSp
            type="password"
            name="password"
            disabled={modoConsulta()}
            required={
              pageParams.statusTela === StatusTela.stInserir ||
              (usuario.confirmSenha && usuario.confirmSenha !== '')
            }
            value={usuario.senha}
            onChange={e => {
              setUsuario({ ...usuario, senha: e.target.value });
            }}
          />
        </div>
        <div className="p-col-6 p-sm-2 p-lg-2 p-fluid">
          <LabelSp>Confirmação</LabelSp>
          <InputTextSp
            type="password"
            name="password"
            disabled={modoConsulta()}
            required={
              pageParams.statusTela === StatusTela.stInserir ||
              (usuario.senha && usuario.senha !== '')
            }
            value={usuario.confirmSenha}
            onChange={e => {
              setUsuario({ ...usuario, confirmSenha: e.target.value });
            }}
          />
        </div>

        <div className="p-col-12 p-lg-12" style={{ textAlign: 'end' }}>
          {!modoConsulta() ? (
            <ButtonSp
              className="p-button-success"
              icon="pi pi-save"
              label="Salvar"
              disabled={
                !validateFields(usuario, [
                  'nome',
                  'email',
                  usuario.tipoUsuario !== 0 ? 'numeroContrato' : '',
                  pageParams.statusTela === StatusTela.stInserir ? 'senha' : '',
                  pageParams.statusTela === StatusTela.stInserir ? 'confirmSenha' : '',
                ])
              }
              showConfirmation
              onClick={handleSalvar}
            />
          ) : null}
          <ButtonSp
            className="p-button-secondary"
            label="Voltar"
            icon="pi pi-chevron-circle-left"
            onClick={handleVoltar}
          />
        </div>
      </>
    );
  }
}
