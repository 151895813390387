import React from 'react';

import { Dialog } from 'primereact/dialog';
import { useTheme } from 'styled-components';
import PropTypes from 'prop-types';
import { Container, DivBodyDlg } from './styles';

/**
 * @typedef DialogCardapio
 * @property {boolean} visible Determina se o dialog vai estar visivel ou nao
 * @property {Function} setVisible Funcao para definir se está visivel ou não
 * @property {string} title Título
 * @property {ReactNode} children Children
 * @property {ReactNode} footer Footer do dialog
 * @property {boolean} closable Determina de a janela será fechada com esc
 * @property {React.CSSProperties} style style do componente
 * @property {React.CSSProperties} styleBody styleBody do componente
 */

/**
 * @func DialogCardapio
 * @param {DialogCardapio} props
 */

function DialogCardapio(props) {
  DialogCardapio.propTypes = {
    visible: PropTypes.bool,
    setVisible: PropTypes.func,
    title: PropTypes.any,
    children: PropTypes.any,
    footer: PropTypes.any,
    style: PropTypes.any,
    closable: PropTypes.bool,
    styleBody: PropTypes.any,
  };

  const { visible, setVisible, title, children, footer, style, styleBody, closable } = props;

  const { backgroundColor } = style;
  const theme = useTheme();
  return (
    <Container>
      <Dialog
        closable={closable}
        className="dialog"
        showHeader={false}
        visible={visible}
        style={style}
        contentStyle={{
          overflowY: 'hidden',
          overflowX: 'hidden',
          borderRadius: theme.borderRadius,
          borderStyle: 'solid',
          borderWith: 0,
          backgroundColor: backgroundColor || theme.colors.backgroundCardapio,
          margin: 0,
          padding: 0,
        }}
        modal
        onHide={() => {
          setVisible(false);
        }}
      >
        <DivBodyDlg>
          <div className="div-botao-fechar-dlg">
            <button
              className="pi pi-times-circle button-close"
              onClick={() => {
                if (setVisible) {
                  setVisible(false);
                }
              }}
            />
          </div>
          <div className="div-container-dlg" style={styleBody}>
            {title ? <span className="titulo-dlg">{title}</span> : null}
            <div style={{ flex: 1 }}>{children}</div>
            {footer}
          </div>
        </DivBodyDlg>
      </Dialog>
    </Container>
  );
}

export default DialogCardapio;
