import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { store } from '../../../../store';
import { showDialogLogin } from '../../../../store/modules/dialog/actions';
import { showMessage } from '../../../../components/MessageDialog';
import { logout } from '../../../../store/modules/auth/actions';
import AuthService from '../../../../services/AuthService';
import { capitalizeStr } from '../../../../util/functions';

export function Topbar(props) {
  Topbar.defaultProps = {
    onToggleMenu: null,
  };

  Topbar.propTypes = {
    onToggleMenu: PropTypes.func.isRequired,
  };

  const usuario = AuthService.getUsuario();
  const empresaLogada = usuario.empresa;
  let nomeFantasia = empresaLogada ? capitalizeStr(empresaLogada.fantasia, true) : '';
  nomeFantasia = `${nomeFantasia.substring(0, 23)}`;
  if (nomeFantasia) {
    nomeFantasia = ` - ${nomeFantasia}`;
  }

  const { tokenExpiring } = useSelector(state => {
    return state.global;
  });

  function handleSair() {
    showMessage('Confirmação', 'Deseja sair?', idx => {
      if (idx === 1) {
        store.dispatch(logout());
      }
    });
  }

  function handleLogin() {
    store.dispatch(showDialogLogin());
  }

  return (
    <>
      <div className="layout-topbar clearfix">
        <button className="p-link layout-menu-button" onClick={props.onToggleMenu}>
          <span className="pi pi-bars" />
        </button>

        <div className="layout-topbar-icons">
          {tokenExpiring ? (
            <button
              className="p-link"
              title="Conexão vence em menos de 5 mintos. Click para renovar conexão"
              onClick={() => handleLogin()}
            >
              <span className="layout-topbar-item-text">Usuário</span>
              <span className="layout-topbar-icon pi pi-user" />
              <span className="layout-topbar-badge blink-animated">!</span>
            </button>
          ) : null}
          <button className="p-link" title="Sair" onClick={() => handleSair()}>
            <span className="layout-topbar-item-text">Settings</span>
            <span className="layout-topbar-icon pi pi-fw pi-power-off" />
          </button>
        </div>
        <div className="layout-topbar-title">
          <span>Cardápio Online</span>
          <span className="desktop-visible">{nomeFantasia}</span>
        </div>
      </div>
    </>
  );
}
