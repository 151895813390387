/* eslint-disable no-restricted-syntax */
/* eslint-disable import/no-cycle */
import {
  format,
  parse,
  addDays,
  differenceInMinutes,
  getDay,
  differenceInHours,
} from 'date-fns';
import { toast } from 'react-toastify';
import { lastDayOfMonth, lastDayOfWeek, addMonths } from 'date-fns/esm';
import queryString from 'query-string';

import { StatusTela } from './Tipos';
import { store } from '../store';
import { showDialogLogin } from '../store/modules/dialog/actions';

export const { format: formatCurr } = new Intl.NumberFormat('pt-BR', {
  style: 'currency',
  currency: 'BRL',
});

export function formatFloat(value, digits = 2) {
  const f = new Intl.NumberFormat('pt-BR', {
    minimumFractionDigits: digits,
    maximumFractionDigits: digits,
  });
  return f.format(value);
}

export function formatDate(date, dateFormat = 'yyyy-MM-dd') {
  let dateRetorno = date;
  if (typeof dateRetorno === 'string') {
    dateRetorno = strToDate(date);
    if (dateRetorno.toString() === 'Invalid Date') {
      dateRetorno = new Date(date);
    }
  }
  return format(dateRetorno, dateFormat);
}

export function addMes(date, qtd) {
  return addMonths(date, qtd);
}

export function strToDate(strDate, dateFormat = 'yyyy-MM-dd') {
  if (isDate(strDate, dateFormat)) {
    return parse(strDate, dateFormat, new Date());
  }
  return null;
}

export function primeiroDiaAno(data) {
  const ano = formatDate(data, 'yyyy');
  return strToDate(`01-01-${ano}`, 'dd-MM-yyyy');
}

export function ultimoDiaAno(data) {
  const ano = formatDate(data, 'yyyy');
  return strToDate(`31-12-${ano}`, 'dd-MM-yyyy');
}

export function primeiroDiaMes(data) {
  const mesAno = formatDate(data, 'MM-yyyy');

  return strToDate(`01-${mesAno}`, 'dd-MM-yyyy');
}

export function ultimoDiaMes(data) {
  return lastDayOfMonth(data);
}

export function ultimoDiaSemana(date, domingoPrimeiroDia = true) {
  const ultimoDia = lastDayOfWeek(date);
  const dias = domingoPrimeiroDia ? 1 : 0;
  return addDays(ultimoDia, dias);
}

export function primeiroDiaSemana(date, domingoPrimeiroDia = true) {
  const ultimoDia = ultimoDiaSemana(date, domingoPrimeiroDia);
  return addDays(ultimoDia, -7);
}

export function isDate(strDate, dateFormat = 'yyyy-MM-dd') {
  const retorno = parse(strDate, dateFormat, new Date());
  return !!retorno;
}

export function isTime(strTime) {
  const time = new Date(`2000-01-01 ${strTime}`);
  return time.toString() !== 'Invalid Date';
}

export function geraFiltroHttp(filtros, aceitaVazio = true) {
  if (!filtros) return '';
  let retorno = '';

  Object.entries(filtros).forEach(([key, value]) => {
    if (value && typeof value === 'string') {
      if (aceitaVazio && value.trim() === '') {
        value = null;
      } else {
        value = value.trim();
      }
    }

    if (value) {
      if (retorno === '') {
        retorno += `?${key}=${value}`;
      } else {
        retorno += `&${key}=${value}`;
      }
    }
  });
  return retorno;
}

export function isMobile() {
  return window.innerWidth <= 1024;
}

// calcula distância entre coordenas e retorna valor em KM
export function calcDistanciaCoord(Lat1, Lng1, Lat2, Lng2) {
  const r = 6371.0; // Raio da terra
  const retorno =
    r *
    Math.acos(
      Math.cos((Math.PI * (90 - Lat2)) / 180) * Math.cos(((90 - Lat1) * Math.PI) / 180) +
        Math.sin(((90 - Lat2) * Math.PI) / 180) *
          Math.sin(((90 - Lat1) * Math.PI) / 180) *
          Math.cos(((Lng1 - Lng2) * Math.PI) / 180)
    );

  return retorno;
}

export function sleep(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

export function errorHandle(error) {
  console.logs.error(error);
  let msg = '';
  if (typeof error === 'string') {
    toast.error(error);
  } else if (typeof error === 'object') {
    if (error.tokenExpired) {
      msg = 'Seu acesso expirou, é necessário fazer login';
      store.dispatch(showDialogLogin());
    } else if (error.response) {
      if (error.response.data.error && typeof error.response.data.error === 'string') {
        msg = error.response.data.error;
      } else if (error.response.status === 404) {
        msg = `404 - Recurso não encontrato no servidor.`;
      } else if (error.response.status === 400) {
        msg = '400 - Requisição inválida';
      } else if (error.response.status === 401) {
        msg = '401 - Não autorizado';
      } else if (error.response.status === 405) {
        msg = '405 - Método não permitido';
      } else if (error.response.status === 403) {
        msg = '403 - Você não tem permissão para executar esta ação';
      }
      if (error.response.data.mensagemUsuario && error.response.data.mensagemUsuario !== '') {
        msg = error.response.data.mensagemUsuario;
      }
    }
  } else {
    msg = 'Erro ao processar serviço remoto. Tente novamente.';
  }
  if (msg === '') {
    msg = 'Erro ao processar ação. Tente novamente';
  }
  if (error?.response?.data?.mensagemDesenvolvedor) {
    console.logs.error(
      `msg dev: ${error?.response?.data?.mensagemDesenvolvedor}`,
      error?.response?.data.detalhe
    );
  } else {
    console.logs.error(`msg dev: ${msg}`);
  }
  toast.error(msg);
}

export function isNumber(val) {
  // eslint-disable-next-line no-restricted-globals
  return !isNaN(val);
}

export function buscaPageParams(params, search) {
  const { opcao } = params;
  const opcoes = queryString.parse(search);
  const { backTo } = queryString.parse(search);
  const visualizar = opcoes.visualizar !== undefined;
  let stTela = StatusTela.stPesquisar;
  let idSelecionado = 0;

  if (isNumber(opcao)) {
    if (visualizar) {
      stTela = StatusTela.stVisualizar;
      idSelecionado = Number(opcao);
    } else {
      idSelecionado = Number(opcao);
      stTela = StatusTela.stAlterar;
    }
  } else if (opcao === 'inserir') {
    stTela = StatusTela.stInserir;
  }

  return {
    statusTela: stTela,
    idSelecionado,
    backTo,
  };
}

export function cloneObj(obj) {
  if (obj !== undefined && obj !== null) {
    return JSON.parse(JSON.stringify(obj));
  }
  return null;
}

export function getElementArray(array, localValue, prevNext) {
  const i = array.indexOf(localValue);
  if (i >= 0 && i + prevNext < array.length && i + prevNext >= 0) {
    return array[i + prevNext];
  }
  return null;
}

export function getDiferencaEmMinutos(dateFinal, dateInicial) {
  return differenceInMinutes(dateFinal, dateInicial);
}

export function getDiferencaEmHoras(dateFim, dateInicial) {
  return differenceInHours(dateFim, dateInicial);
}

export function validateFields(obj, fields) {
  const array = fields && typeof fields.forEach === 'function' ? fields : [fields];

  let ret = false;
  if (obj && typeof obj === 'object') {
    if (array && array.length > 0) {
      ret = true;
      array.forEach(e => {
        if (e !== '') {
          if (obj[e] !== null && obj[e] !== undefined) {
            if (typeof obj[e] === 'string' && obj[e] === '') {
              ret = false;
            } else if (typeof obj[e] === 'number' && obj[e] <= 0) {
              ret = false;
            } else if (typeof obj[e] === 'object' && !obj[e]) {
              ret = false;
            } else if (typeof obj[e] === 'bigint' && obj[e] <= 0) {
              ret = false;
            } else if (typeof obj[e] === 'undefined') {
              ret = false;
            }
          } else {
            ret = false;
          }
        }
      });
    }
  }

  return ret;
}

export function validCPF(strCPF, blankValid = false) {
  if (!strCPF && blankValid) {
    return true;
  }
  strCPF = strCPF.replace(/[^\d]+/g, '');
  let Soma;
  let Resto;
  Soma = 0;
  let i;
  if (!strCPF || strCPF === '00000000000' || strCPF === '') return false;

  for (i = 1; i <= 9; i++) Soma += parseInt(strCPF.substring(i - 1, i), 10) * (11 - i);
  Resto = (Soma * 10) % 11;

  if (Resto === 10 || Resto === 11) Resto = 0;
  if (Resto !== parseInt(strCPF.substring(9, 10), 10)) return false;

  Soma = 0;
  for (i = 1; i <= 10; i++) Soma += parseInt(strCPF.substring(i - 1, i), 10) * (12 - i);
  Resto = (Soma * 10) % 11;

  if (Resto === 10 || Resto === 11) Resto = 0;
  if (Resto !== parseInt(strCPF.substring(10, 11), 10)) return false;
  return true;
}

export function validCNPJ(strCNPJ, blankValid = false) {
  if (!strCNPJ && blankValid) {
    return true;
  }

  strCNPJ = strCNPJ.replace(/[^\d]+/g, '');

  if (strCNPJ === '') return false;

  if (strCNPJ.length !== 14) {
    return false;
  }

  // Elimina CNPJs invalidos conhecidos
  if (strCNPJ === '00000000000000') return false;

  // Valida DVs
  let tamanho = strCNPJ.length - 2;
  let numeros = strCNPJ.substring(0, tamanho);
  const digitos = strCNPJ.substring(tamanho);
  let soma = 0;
  let pos = tamanho - 7;
  let i;
  for (i = tamanho; i >= 1; i--) {
    soma += numeros.charAt(tamanho - i) * pos--;
    if (pos < 2) pos = 9;
  }
  let resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
  if (resultado !== parseInt(digitos.charAt(0), 10)) {
    return false;
  }

  tamanho += 1;
  numeros = strCNPJ.substring(0, tamanho);
  soma = 0;
  pos = tamanho - 7;
  for (i = tamanho; i >= 1; i--) {
    soma += numeros.charAt(tamanho - i) * pos--;
    if (pos < 2) pos = 9;
  }
  resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
  if (resultado !== parseInt(digitos.charAt(1), 10)) {
    return false;
  }

  return true;
}

export function padLeft(text, size) {
  if (text) {
    return (String('0').repeat(size) + text).substr(size * -1, size);
  }
  return '';
}

export function isValidArray(array) {
  return array && typeof array.map === 'function';
}

export function capitalizeStr(text, porPalavra) {
  if (!text || text.trim() === '') {
    return '';
  }

  if (porPalavra) {
    const arrayPalavras = text.split(' ');
    let novaPalavra = '';
    arrayPalavras.forEach(p => {
      const palavra = p.trim().toLowerCase();
      if (palavra) {
        novaPalavra = `${novaPalavra} ${palavra[0].toUpperCase()}${palavra.slice(1)}`;
      }
    });
    return novaPalavra;
  }

  const val = text.toLowerCase();
  return val[0].toUpperCase() + val.slice(1);
}

export function goToMarcador(marcador) {
  const elmnt = document.getElementById(marcador);
  if (elmnt) {
    elmnt.scrollIntoView();
  }
}

export function arredonda(valor, casas) {
  if (!valor) {
    return valor;
  }
  const strValor = String(valor);
  const idx = strValor.indexOf('.');
  const cDecimais = idx >= 0 ? strValor.substring(idx + 1, strValor.length) : '';

  if (casas < 1) {
    console.tron.log(cDecimais);
    return Math.trunc(valor);
  }

  if (cDecimais.length <= casas) {
    return valor;
  }

  // && (!strValor[idx+2+casas] || strValor[idx+2+casas] === '0')

  // Se a casa decimal SUBSEQUENTE for DIFERENTE de 5
  if (idx < 0 || strValor[idx + 1 + casas] !== '5') {
    return Number(valor.toFixed(casas)); // arredonda
  } // se for igual a 5
  if (strValor[idx + 1] % 2 !== 0) {
    // Se a casa decimal que será CONSERVADA, for IMPAR
    return Number(valor.toFixed(casas)); // arredonda
  } // se for par
  if (strValor[idx + 2 + casas] > 0) {
    return Number(valor.toFixed(casas)); // arredonda
  }
  return Number(strValor.substring(0, idx + 1 + casas));
}

export function setFocusElement(id) {
  const elemet = document.getElementById(id);
  if (elemet) {
    elemet.focus();
  }
}

export function formatCelular(strNum) {
  const num = strNum.replace(/\(|\)|-| /g, '');
  if (num) {
    return `(${num.slice(0, 2)}) ${num.slice(2, 7)}-${num.slice(7, 11)}`;
  }
  return '';
}

export function formatCelularWhatsApp(strNum) {
  const num = strNum.replace(/\(|\)|-| /g, '');
  if (num) {
    return num;
  }
  return '';
}

export function formatTelefone(strNum) {
  const num = strNum.replace(/\(|\)|-| /g, '');
  if (num) {
    return `(${num.slice(0, 2)}) ${num.slice(2, 6)}-${num.slice(6, 10)}`;
  }
  return '';
}

// functions
export function verificaEmpresaFuncionando(empresaFuncionando, listaHorarios) {
  if (!empresaFuncionando) {
    return false;
  }

  const dataHora = new Date();

  const strDiaAtual = formatDate(dataHora, 'yyyyMMdd');

  const horaAtual = Number(strDiaAtual + formatDate(dataHora, 'HHmm'));

  let retorno = false;

  const diaSemanaAtual = getDay(dataHora);
  const diaSemanaAnterior = getDay(addDays(dataHora, -1));

  // array listaHorarios
  // rotina vai buscar os intervalos do dia atual e também do dia anterior, pois
  // se for após 00h posivelmente devemos utilizar o intervalo do dia anterior

  const horariosDia = listaHorarios.filter(
    e => e.diaSemana === diaSemanaAtual || e.diaSemana === diaSemanaAnterior
  );

  horariosDia.forEach(h => {
    const strDiaAtualHorario = formatDate(
      addDays(dataHora, h.diaSemana - diaSemanaAtual),
      'yyyyMMdd'
    );

    const strDiaPosteriorHorario = formatDate(
      addDays(dataHora, h.diaSemana - diaSemanaAtual + 1),
      'yyyyMMdd'
    );

    const horaAbertura = Number(
      strDiaAtualHorario + h.horaAbertura.replace(/:/g, '').substring(0, 4)
    );

    let horaFechamento = Number(
      strDiaAtualHorario + h.horaFechamento.replace(/:/g, '').substring(0, 4)
    );
    if (horaFechamento < horaAbertura) {
      horaFechamento = Number(
        strDiaPosteriorHorario + h.horaFechamento.replace(/:/g, '').substring(0, 4)
      );
    }

    if (horaAtual >= horaAbertura && horaAtual <= horaFechamento) {
      retorno = true;
      return true;
    }
  });
  return retorno;
}

export function nomeDiaSemana(numeroDia, inicioEmUm = false, retornaNomeField = false) {
  let incrementaUm = 0;
  if (inicioEmUm) {
    incrementaUm = 1;
  }

  switch (numeroDia) {
    case 0 + incrementaUm:
      return retornaNomeField ? 'domingo' : 'Domingo';
    case 1 + incrementaUm:
      return retornaNomeField ? 'segunda' : 'Segunda';
    case 2 + incrementaUm:
      return retornaNomeField ? 'terca' : 'Terça';
    case 3 + incrementaUm:
      return retornaNomeField ? 'quarta' : 'Quarta';
    case 4 + incrementaUm:
      return retornaNomeField ? 'quinta' : 'Quinta';
    case 5 + incrementaUm:
      return retornaNomeField ? 'sexta' : 'Sexta';
    case 6 + incrementaUm:
      return retornaNomeField ? 'sabado' : 'Sábado';
    default:
      break;
  }
}

export function strNumBrToNumber(srtNumber, valDefault = undefined) {
  if (typeof strnumber === 'number') {
    return srtNumber;
  }
  if (!srtNumber || srtNumber.trim() === '') {
    return valDefault;
  }
  const sNumber = srtNumber.replace(/\./g, '').replace(/,/g, '.');
  if (isNumber(sNumber)) {
    return Number(sNumber);
  }
  return valDefault;
}

export function configuraTitleDescription(nome) {
  const elementTextTitle = document.createTextNode(capitalizeStr(nome));
  const elementTitle = document.querySelector('#app-title-speedy');
  elementTitle.innerHTML = '';
  elementTitle.appendChild(elementTextTitle);
  const elementkeyword = document.querySelector('#app-keywords-speedy');
  elementkeyword.setAttribute(
    'content',
    `Food, Cardápio Online, Pedido Online, ${capitalizeStr(nome)}`
  );
  const elementDescription = document.querySelector('#app-keywords-speedy');
  elementDescription.setAttribute(
    'content',
    `Food, Cardápio Online, Pedido Online, ${capitalizeStr(nome)}`
  );
}
