/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-restricted-syntax */
import React, { useState, useCallback, useEffect, useMemo } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

import { toast } from 'react-toastify';
import { Dialog } from 'primereact/dialog';
import { Container, ContainerImage, DivFooterDlg, DivTema } from './styles';
import BarraTitulo from '../../components/BarraTitulo';
import PainelFiltro from '../../components/PainelFiltro';
import InputCurrencySp from '../../components/InputCurrencySp';
import EmpresaService from '../../services/EmpresaService';
import MunicipioService from '../../services/MunicipioService';
import MapsService from '../../services/MapsService';
import history from '../../services/history';
import { StatusTela } from '../../util/Tipos';
import {
  buscaPageParams,
  errorHandle,
  validateFields,
  validCNPJ,
  validCPF,
  padLeft,
  nomeDiaSemana,
  isTime,
  cloneObj,
  isNumber,
} from '../../util/functions';
import LabelSp from '../../components/LabelSp';
import { EmpresaModel, Horarios, FileImage, Taxas } from '../../util/Models';
import { listaUfs, ConfigApi, ListaTiposServicos } from '../../config/Constantes';
import DropdownLazy from '../../components/DropdownLazy';
import { showMessage } from '../../components/MessageDialog';
import InputTextSp from '../../components/InputTextSp';
import InputMaskSp from '../../components/InputMaskSp';
import DropdownSp from '../../components/DropdownSp';
import AuthService from '../../services/AuthService';
import PainelBotesCadastro from '../../components/PainelBotesCadastro';
import BotaoMenuGrid from '../../components/BotaoMenuGrid';
import ButtonSp from '../../components/ButtonSp';
import MultiSelectSp from '../../components/MultiSelectSp';
import Mapa from '../../components/Mapa';

export default function Empresa(props) {
  // useMemo
  const pageParams = useMemo(
    () => buscaPageParams(props.match.params, props.location.search),
    [props.location.search, props.match.params]
  );
  const filterService = useMemo(() => EmpresaService.getFilter(), []);

  // useStates
  const backTo = pageParams.backTo || '/painel/empresas';
  const [filter, setFilter] = useState(filterService);
  const [empresas, setEmpresas] = useState([]);
  const [pageLimit, setPageLimit] = useState(filterService.limit);
  const [first, setFirst] = useState(0);
  const [totalRecords, setTotalRecords] = useState(0);
  const [empresa, setEmpresa] = useState(new EmpresaModel());

  const [municipioSelecionado, setMunicipioSelecionado] = useState(null);
  const [horarios, setHorarios] = useState(new Horarios());
  const [taxas, setTaxas] = useState(new Taxas());

  const [fileLogo, setFileLogo] = useState(new FileImage());
  const [dialogVisible, setDialogVisible] = useState(false);
  const [srcImg, setSrcImg] = useState(null);
  const [fileSelecionado, setFileSelecionado] = useState(null);

  const [dialogBannerVisible, setDialogBannerVisible] = useState(false);
  const [fileBanner, setFileBanner] = useState(new FileImage());

  const [srcImgBaner, setSrcImgBanner] = useState(null);
  const [fileBannerSelecionado, setFileBannerSelecionado] = useState(null);

  const [markerSelecionada, setMarkerSelecionada] = useState({
    latitude: 0,
    longitude: 0,
  });
  const [markerDefault, setMarkerDefault] = useState({
    latitude: 0,
    longitude: 0,
  });

  const [showConfirmeLocalizacao, setShowConfirmeLocalizacao] = useState(false);
  const [tiposServico, setTiposServico] = useState();

  // funcoes
  const handleBuscar = useCallback(async (_filter, _page, resetPage = true) => {
    _filter.page = _page;
    try {
      const result = await EmpresaService.findAll(_filter);
      setEmpresas(result.items);
      setPageLimit(result.limit);
      setTotalRecords(result.totalRecords);
      if (resetPage) {
        setFirst(0);
      }
    } catch (err) {
      errorHandle(err);
    }
  }, []);

  const carregaDadosMapa = async () => {
    let marker;
    if (empresa.latitude && empresa.longitude) {
      marker = { latitude: empresa.latitude, longitude: empresa.longitude };
    } else if (empresa.logradouro && empresa.nomeMunicipio && empresa.uf) {
      const loc = await MapsService.findLocation(
        empresa.logradouro,
        empresa.numero,
        empresa.bairro,
        empresa.nomeMunicipio,
        empresa.uf
      );
      if (loc.longitude && loc.longitude) {
        marker = { latitude: loc.latitude, longitude: loc.longitude };
      }
    }

    if (marker) {
      setMarkerDefault(marker);
      // setMarkerSelecionada(marker);
      setShowConfirmeLocalizacao(true);
    } else {
      toast.warn('Não foi possível identificar seu endereço no mapa.');
    }
  };

  const carregaMunicipio = useCallback(async (_nome, _uf) => {
    if (_nome !== '') {
      const r = await MunicipioService.findAll({ nome: _nome, uf: _uf, limit: 100 });
      const retorno = r.items.map(e => {
        return {
          label: e.nome,
          value: e.codigoIbge,
        };
      });
      return retorno;
    }
    return [];
  }, []);

  const carregaRegistro = useCallback(async _id => {
    try {
      setFileSelecionado(null);
      setFileBannerSelecionado(null);
      setFileLogo(new FileImage());
      setFileBanner(new FileImage());

      const retorno = await EmpresaService.findById(_id);

      // montando inf. dos horarios
      const horarioTmp = new Horarios();
      if (retorno.listaHorarios && retorno.listaHorarios.length > 0) {
        for (let i = 0; i < 7; i++) {
          const horariosDia = retorno.listaHorarios.filter(h => h.diaSemana === i);

          const fieldSemana = nomeDiaSemana(i, false, true);
          for (let j = 0; j < Math.min(horariosDia.length, 2); j++) {
            horarioTmp[`${fieldSemana}Abre${j}`] = horariosDia[j].horaAbertura;
            horarioTmp[`${fieldSemana}Fecha${j}`] = horariosDia[j].horaFechamento;
          }
        }
      }

      // montando inf. das taxas
      const taxaTmp = new Taxas();
      if (retorno.listaTaxas && retorno.listaTaxas.length > 0) {
        const max = retorno.listaTaxas.length > 12 ? 12 : retorno.listaTaxas.length;
        for (let i = 0; i < max; i++) {
          taxaTmp[`id${i}`] = retorno.listaTaxas[i].id;
          taxaTmp[`acimaDeKm${i}`] = retorno.listaTaxas[i].acimaDeKm;
          taxaTmp[`valorTaxa${i}`] = retorno.listaTaxas[i].valorTaxa;
        }
      }

      setEmpresa(retorno);
      setHorarios(horarioTmp);
      setTaxas(taxaTmp);
      const municipio = {
        label: retorno.nomeMunicipio,
        value: retorno.codigoIbge,
        uf: retorno.uf,
      };
      setMunicipioSelecionado(municipio);

      let tipoServicosSelecionado = [0, 1, 2];

      if (retorno.tiposServico) {
        try {
          const listaTipoServicosTemp = JSON.parse(retorno.tiposServico);
          tipoServicosSelecionado = listaTipoServicosTemp.map(e => e.value);
        } catch (error) {
          //
        }
      }

      setTiposServico(tipoServicosSelecionado);
    } catch (err) {
      setEmpresa(new EmpresaModel());
      errorHandle(err);
      throw err;
    }
  }, []);

  function onPage(event) {
    const pagina = event.first / event.rows;
    setFirst(event.first);
    handleBuscar(filter, pagina, false);
  }

  // useCallbacks
  const excluirRegistro = useCallback(
    async _id => {
      try {
        await EmpresaService.delete(_id);
        toast.success('Registro excluído com sucesso.');
        handleBuscar(filterService);
      } catch (err) {
        errorHandle(err);
      }
    },
    [filterService, handleBuscar]
  );

  const confirmaExclusao = useCallback(
    idSelecionado => {
      const empresaLogada = AuthService.getUsuario().empresa;
      const idEmpresaLogada = empresaLogada ? empresaLogada.id : 0;
      if (idSelecionado === idEmpresaLogada) {
        toast.error('Você não pode excluir uma empresa estando logado nela');
        return;
      }
      showMessage('Confirmação', 'Confirma a exclusão do registro?', idx => {
        if (idx === 1) {
          excluirRegistro(idSelecionado);
        }
      });
    },
    [excluirRegistro]
  );

  // functions
  function handleVoltar() {
    if (pageParams.statusTela === StatusTela.stVisualizar) {
      history.push(backTo);
      handleBuscar(filter);
    } else {
      showMessage('Confirmação', 'Abandonar mudanças?', idx => {
        if (idx === 1) {
          history.push(backTo);
          handleBuscar(filter);
        }
      });
    }
  }

  function handleSalvar() {
    if (pageParams.statusTela === StatusTela.stVisualizar) {
      history.push(backTo);
    } else {
      salvarRegistro();
    }
  }

  const modoConsulta = useCallback(() => {
    return pageParams.statusTela === StatusTela.stVisualizar;
  }, [pageParams.statusTela]);

  async function salvarRegistro() {
    if (!empresa.latitude || !empresa.longitude) {
      toast.error('Seleciona a localização da empresa no mapa');
      return;
    }
    if (fileLogo.file) {
      if (fileLogo.file.size > 1000001) {
        toast.warn('Arquivo nao pode ter tamanho superior a 1M');
        return;
      }
    }
    if (empresa.tipoPessoa === 'J' && !validCNPJ(empresa.cpfCnpj, true)) {
      toast.warn('CNPJ inválido.', { autoClose: 2000 });
      return;
    }
    if (empresa.tipoPessoa === 'F' && !validCPF(empresa.cpfCnpj, true)) {
      toast.warn('CPF inválido.', { autoClose: 2000 });
      return;
    }

    if (tiposServico.length === 0) {
      toast.warn('Selecione pelo menos um tipo de Serviço.', { autoClose: 2000 });
      return;
    }

    // valida horarios
    if (!validaHorarios()) {
      return;
    }

    // valida taxas
    if (!validaTaxas()) {
      return;
    }
    const empresaParaGravar = cloneObj(empresa);

    ajustaHorariosGravar(empresaParaGravar);
    ajustaTaxasGravar(empresaParaGravar);

    empresaParaGravar.fileLogo = fileLogo.file;
    empresaParaGravar.fileBanner = fileBanner.file;

    if (tiposServico) {
      const tipoServicoJson = [];
      tiposServico.forEach(e => {
        tipoServicoJson.push(ListaTiposServicos[e]);
      });
      empresaParaGravar.tiposServico = JSON.stringify(tipoServicoJson);
    } else {
      empresaParaGravar.tiposServico = [];
    }

    try {
      if (pageParams.statusTela === StatusTela.stInserir) {
        await EmpresaService.insert(empresaParaGravar);
      } else {
        await EmpresaService.update(empresaParaGravar);
      }
      toast.success('Registro salvo com sucesso.');
      setFileLogo(new FileImage()); // limpa obj para evitar bug ao visualizar proximo registro
      setFileBanner(new FileImage());

      history.push(backTo);
      handleBuscar(filter);
    } catch (err) {
      errorHandle(err);
    }
  }

  function ajustaTaxasGravar(empresaParaGravar) {
    const listaTaxas = [];
    if (taxas.acimaDeKm0 > 0) {
      listaTaxas.push({
        id: taxas.id,
        acimaDeKm: taxas.acimaDeKm0,
        valorTaxa: taxas.valorTaxa0,
      });
    }

    if (taxas.acimaDeKm1 > 0) {
      listaTaxas.push({
        id: taxas.id,
        acimaDeKm: taxas.acimaDeKm1,
        valorTaxa: taxas.valorTaxa1,
      });
    }

    if (taxas.acimaDeKm2 > 0) {
      listaTaxas.push({
        id: taxas.id,
        acimaDeKm: taxas.acimaDeKm2,
        valorTaxa: taxas.valorTaxa2,
      });
    }
    if (taxas.acimaDeKm3 > 0) {
      listaTaxas.push({
        id: taxas.id,
        acimaDeKm: taxas.acimaDeKm3,
        valorTaxa: taxas.valorTaxa3,
      });
    }
    if (taxas.acimaDeKm4 > 0) {
      listaTaxas.push({
        id: taxas.id,
        acimaDeKm: taxas.acimaDeKm4,
        valorTaxa: taxas.valorTaxa4,
      });
    }

    if (taxas.acimaDeKm5 > 0) {
      listaTaxas.push({
        id: taxas.id,
        acimaDeKm: taxas.acimaDeKm5,
        valorTaxa: taxas.valorTaxa5,
      });
    }

    if (taxas.acimaDeKm6 > 0) {
      listaTaxas.push({
        id: taxas.id,
        acimaDeKm: taxas.acimaDeKm6,
        valorTaxa: taxas.valorTaxa6,
      });
    }

    if (taxas.acimaDeKm7 > 0) {
      listaTaxas.push({
        id: taxas.id,
        acimaDeKm: taxas.acimaDeKm7,
        valorTaxa: taxas.valorTaxa7,
      });
    }

    if (taxas.acimaDeKm8 > 0) {
      listaTaxas.push({
        id: taxas.id,
        acimaDeKm: taxas.acimaDeKm8,
        valorTaxa: taxas.valorTaxa8,
      });
    }

    if (taxas.acimaDeKm9 > 0) {
      listaTaxas.push({
        id: taxas.id,
        acimaDeKm: taxas.acimaDeKm9,
        valorTaxa: taxas.valorTaxa9,
      });
    }

    if (taxas.acimaDeKm10 > 0) {
      listaTaxas.push({
        id: taxas.id,
        acimaDeKm: taxas.acimaDeKm10,
        valorTaxa: taxas.valorTaxa10,
      });

      if (taxas.acimaDeKm11 > 0) {
        listaTaxas.push({
          id: taxas.id,
          acimaDeKm: taxas.acimaDeKm11,
          valorTaxa: taxas.valorTaxa11,
        });
      }
    }

    empresaParaGravar.listaTaxas = listaTaxas;
  }

  function ajustaHorariosGravar(empresaParaGravar) {
    const listaHorarios = [];
    const listaHorariosOld = empresaParaGravar.listaHorarios || [];
    const dias = [0, 1, 2, 3, 4, 5, 6];
    dias.forEach(d => {
      const nomeFieldDia = nomeDiaSemana(d, false, true);
      for (let i = 0; i < 2; i++) {
        let horario = null;
        if (horarios[`${nomeFieldDia}Abre${i}`]) {
          const horariosDiaOld = listaHorariosOld.filter(h => h.diaSemana === d);
          if (horariosDiaOld && horariosDiaOld[i]) {
            horario = {
              id: horariosDiaOld[i].id,
            };
          } else {
            horario = {
              id: null,
            };
          }
          horario.diaSemana = d;
          horario.horaAbertura = horarios[`${nomeFieldDia}Abre${i}`];
          horario.horaFechamento = horarios[`${nomeFieldDia}Fecha${i}`];
        }
        if (horario) {
          listaHorarios.push(horario);
        }
      }
    });
    empresaParaGravar.listaHorarios = listaHorarios;
  }

  function validaTaxas() {
    for (let i = 0; i < 12; i++) {
      if (taxas[`acimaDeKm${i}`]) {
        for (let j = 0; j < 12; j++) {
          if (
            j !== i &&
            taxas[`acimaDeKm${j}`] &&
            taxas[`acimaDeKm${j}`] === taxas[`acimaDeKm${i}`]
          ) {
            toast.error('Configuração das taxas deve ser diferentes entre si.');
            return false;
          }
        }
      }
    }

    // if (
    //   (taxas.acimaDeKm0 && taxas.acimaDeKm0 === taxas.acimaDeKm1) ||
    //   (taxas.acimaDeKm0 && taxas.acimaDeKm0 === taxas.acimaDeKm2) ||
    //   (taxas.acimaDeKm0 && taxas.acimaDeKm0 === taxas.acimaDeKm3) ||
    //   (taxas.acimaDeKm1 && taxas.acimaDeKm1 === taxas.acimaDeKm2) ||
    //   (taxas.acimaDeKm1 && taxas.acimaDeKm1 === taxas.acimaDeKm3) ||
    //   (taxas.acimaDeKm2 && taxas.acimaDeKm2 === taxas.acimaDeKm3)
    // ) {
    //   toast.error('Configuração das taxas deve ser diferentes entre si.');
    //   return false;
    // }
    return true;
  }
  function validaHorarios() {
    let retorno = true;
    const entries = Object.entries(horarios);
    for (const e of entries) {
      if (e[0].includes('Abre') || e[0].includes('Fecha')) {
        if (e[1] && !isTime(e[1])) {
          retorno = false;
          toast.error(`"${e[1]}" Não é uma hora válida`, { autoClose: 2000 });
          break;
        }

        if (e[0].includes('Abre') && e[1]) {
          const nomeField = e[0].replace(/Abre/g, 'Fecha');
          if (!horarios[nomeField]) {
            retorno = false;
            toast.error('Informe o horário de fechamento.', { autoClose: 2000 });
            break;
          }

          if (horarios[nomeField] === e[1]) {
            retorno = false;
            toast.error('Horário de abertura e fechamento devem ser diferentes', {
              autoClose: 2000,
            });
            break;
          }
        }

        if (e[0].includes('Fecha') && e[1]) {
          const nomeField = e[0].replace(/Fecha/g, 'Abre');
          if (!horarios[nomeField]) {
            retorno = false;
            toast.error('Informe o horário de abertura.');
            break;
          }

          if (horarios[nomeField] === e[1]) {
            retorno = false;
            toast.error('Horário de abertura e fechamento devem ser diferentes', {
              autoClose: 2000,
            });
            break;
          }
        }
      }
    }

    return retorno;
  }

  // useEffects
  useEffect(() => {
    if (pageParams.statusTela === StatusTela.stPesquisar) {
      handleBuscar(filter, 0, 800);
    }
    // desativado para evitar que a cada vez que o usuario digitasse o sistema buscasse
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      pageParams.statusTela === StatusTela.stAlterar ||
      pageParams.statusTela === StatusTela.stVisualizar
    ) {
      carregaRegistro(pageParams.idSelecionado);
    } else if (pageParams.statusTela === StatusTela.stInserir) {
      const novo = new EmpresaModel();
      setMunicipioSelecionado(null);
      setEmpresa(novo);
      setFileSelecionado(null);
      setFileLogo(new FileImage());
      setFileBanner(new FileImage());
      setHorarios(new Horarios());
    }
  }, [
    carregaRegistro,
    filterService,
    handleBuscar,
    pageParams.idSelecionado,
    pageParams.statusTela,
  ]);

  return (
    <Container className="container-page">
      <div className="p-grid">
        <BarraTitulo title="Empresas" />
        {showConfirmeLocalizacao ? renderConfirmaLocalizacao() : null}
        {pageParams.statusTela === StatusTela.stPesquisar
          ? renderPesquisa()
          : renderCadastro()}
      </div>
    </Container>
  );

  function renderPesquisa() {
    return (
      <>
        <form
          onSubmit={e => {
            e.preventDefault();
            handleBuscar(filter);
          }}
        >
          <PainelFiltro className="p-grid p-col-12" visible="true">
            <div className="p-col-12 p-sm-6 p-lg-6 p-fluid">
              <LabelSp>Nome</LabelSp>
              <InputTextSp
                value={filter.razaoSocial}
                onChange={e => {
                  setFilter({ ...filter, razaoSocial: e.target.value });
                }}
              />
            </div>

            <div className="p-col-12 p-sm-6 p-lg-6 p-fluid">
              <LabelSp>Fantasia</LabelSp>
              <InputTextSp
                value={filter.fantasia}
                onChange={e => {
                  setFilter({ ...filter, fantasia: e.target.value });
                }}
              />
            </div>
          </PainelFiltro>
          <PainelBotesCadastro
            handleInserir={() => {
              history.push('/painel/empresas/inserir');
            }}
            buttonInserirDisabled={!AuthService.checkRoles('EMPRESA_INSERIR')}
          />
        </form>
        <div className="p-col-12 p-fluid">
          <DataTable
            value={empresas}
            style={{ marginBottom: '2px' }}
            paginator
            rows={pageLimit}
            lazy
            responsive
            totalRecords={totalRecords}
            first={first}
            onPage={onPage}
          >
            <Column
              field="id"
              body={rowData => padLeft(rowData.id, 6)}
              header="Id"
              className="grid-col-id"
            />
            <Column field="razaoSocial" className="grid-col" header="Razão Social" />
            <Column field="fantasia" className="grid-col" header="Fantasia" />
            <Column field="telefone" className="grid-col-tel" header="Fone" />
            <Column
              className="gid-col-acoes-35"
              bodyStyle={{ textAlign: 'end' }}
              body={renderButtonOp}
            />
          </DataTable>
        </div>
      </>
    );
  }

  function renderButtonOp(rowData) {
    return (
      <BotaoMenuGrid
        handles={[
          () => history.push(`/painel/empresas/${rowData.id}?visualizar`),
          () => history.push(`/painel/empresas/${rowData.id}`),
          () => confirmaExclusao(rowData.id),
        ]}
        disableds={[
          false,
          !AuthService.checkRoles('EMPRESA_ALTERAR'),
          !AuthService.checkRoles('EMPRESA_EXCLUIR'),
        ]}
      />
    );
  }

  // render confirma endereco
  function renderConfirmaLocalizacao() {
    function renderFooter() {
      return (
        <DivFooterDlg>
          <ButtonSp
            label="Voltar"
            className="p-button-secondary"
            style={{ width: 120 }}
            onClick={() => {
              setShowConfirmeLocalizacao(false);
            }}
          />
          {!modoConsulta() && (
            <ButtonSp
              label="Confirmar"
              className="p-button-success"
              style={{ width: 120 }}
              onClick={() => {
                const latitude = markerSelecionada.latitude
                  ? markerSelecionada.latitude
                  : markerDefault.latitude;

                const longitude = markerSelecionada.longitude
                  ? markerSelecionada.longitude
                  : markerDefault.longitude;

                setEmpresa({
                  ...empresa,
                  latitude,
                  longitude,
                });
                setShowConfirmeLocalizacao(false);
              }}
            />
          )}
        </DivFooterDlg>
      );
    }
    return (
      <Dialog
        closable={false}
        header="Sua localização"
        footer={renderFooter()}
        visible={showConfirmeLocalizacao}
        position="bottom"
        style={{
          minWidth: '350px',
          maxWidth: '600px',
          width: '95%',
        }}
        contentStyle={{ maxHeight: '95vh', overflowY: 'auto', overflowX: 'hidden' }}
        modal
        onHide={() => {
          setShowConfirmeLocalizacao(false);
        }}
      >
        <div style={{ margin: -15, padding: 0, height: 15 }} />
        <div style={{ height: '80vh', padding: 0, margin: -5, marginTop: 5 }}>
          <Mapa
            viewport={{
              latitude: markerSelecionada.latitude,
              longitude: markerSelecionada.longitude,
            }}
            draggable={!modoConsulta()}
            markerDefault={markerDefault}
            onChangeMarker={m => {
              setMarkerSelecionada(m);
            }}
          />
        </div>
      </Dialog>
    );
  }

  function renderCadastro() {
    const logoCliente = empresa.fileUri
      ? `${ConfigApi.baseURL}/files/${empresa.fileUri}`
      : null;

    const fileUriBanner = empresa.fileUriBanner
      ? `${ConfigApi.baseURL}/files/${empresa.fileUriBanner}`
      : null;

    const classDivLogo = `div-logo${!modoConsulta() ? ' clicavel' : ''}`;
    const localizacao = `${(empresa.latitude || 0.0).toFixed(6)}, ${(
      empresa.longitude || 0.0
    ).toFixed(6)}`;

    const colors = [
      { value: 0, label: '#1AAD94' },
      { value: 1, label: '#2DCC70' },
      { value: 2, label: '#9B58B5' },
      { value: 3, label: '#3699DD' },
      { value: 4, label: '#2F3F56' },
      { value: 5, label: '#E97C27' },
      { value: 6, label: '#E94C3D' },
      { value: 7, label: '#890000' },
      { value: 8, label: '#F2C311' },
      { value: 9, label: '#788C8D' },
      { value: 10, label: '#000000' },
    ];

    return (
      <>
        {renderUploadLogo()}
        {renderUploadBanner()}

        <div className="p-col-3 p-sm-4 p-lg-2 p-fluid">
          <LabelSp>Inativo</LabelSp>
          <DropdownSp
            value={empresa.inativo}
            options={[
              { value: true, label: 'Sim' },
              { value: false, label: 'Não' },
            ]}
            disabled={modoConsulta()}
            onChange={e => {
              setEmpresa({ ...empresa, inativo: e.target.value });
            }}
          />
        </div>

        <div className="p-col-9 p-sm-8 p-lg-4 p-fluid">
          <LabelSp>Razão Social</LabelSp>
          <InputTextSp
            value={empresa.razaoSocial}
            maxLength={100}
            required
            disabled={modoConsulta()}
            onChange={e => {
              setEmpresa({ ...empresa, razaoSocial: e.target.value });
            }}
          />
        </div>

        <div className="p-col-12 p-sm-6 p-lg-3 p-fluid">
          <LabelSp>Fantasia</LabelSp>
          <InputTextSp
            value={empresa.fantasia}
            maxLength={100}
            required
            disabled={modoConsulta()}
            onChange={e => {
              setEmpresa({ ...empresa, fantasia: e.target.value });
            }}
          />
        </div>

        <div className="p-col-4 p-sm-3 p-lg-3 p-fluid">
          <LabelSp>Tp. Pessoa</LabelSp>
          <DropdownSp
            value={empresa.tipoPessoa}
            options={[
              { value: 'J', label: 'Jurídica' },
              { value: 'F', label: 'Física' },
            ]}
            disabled={modoConsulta()}
            filterInputAutoFocus={false}
            onChange={e => {
              setEmpresa({ ...empresa, tipoPessoa: e.target.value });
            }}
          />
        </div>

        <div className="p-col-8 p-sm-3 p-lg-3 p-fluid">
          <LabelSp>CPF/CNPJ</LabelSp>
          <InputMaskSp
            // se não usasse a key o react nao renderizava a mascasca corretamente
            key={`txtCNPJ_${empresa.tipoPessoa}`}
            required
            mask={empresa.tipoPessoa === 'J' ? '99.999.999/9999-99' : '999.999.999-99'}
            value={empresa.cpfCnpj}
            disabled={modoConsulta()}
            onChange={e => {
              setEmpresa({ ...empresa, cpfCnpj: e.target.value });
            }}
          />
        </div>

        <div className="p-col-3 p-sm-3 p-lg-2 p-fluid">
          <LabelSp>Tx. Entrega</LabelSp>
          <InputCurrencySp
            disabled={modoConsulta()}
            maxLength={10}
            onlyPositive
            value={empresa.valorTaxaEntrega}
            onChange={(e, v) => {
              setEmpresa({ ...empresa, valorTaxaEntrega: v });
            }}
          />
        </div>

        <div className="p-col-9 p-sm-7 p-lg-5 p-fluid">
          <LabelSp>Logradouro</LabelSp>
          <InputTextSp
            value={empresa.logradouro}
            maxLength={100}
            disabled={modoConsulta()}
            onChange={e => {
              let { latitude, longitude } = empresa;
              if (empresa.logradouro !== e.target.value) {
                latitude = 0;
                longitude = 0;
              }
              setEmpresa({
                ...empresa,
                latitude,
                longitude,
                logradouro: e.target.value,
              });
            }}
          />
        </div>

        <div className="p-col-4 p-sm-2 p-lg-2 p-fluid">
          <LabelSp>Nr.</LabelSp>
          <InputTextSp
            value={empresa.numero}
            disabled={modoConsulta()}
            maxLength={8}
            onChange={e => setEmpresa({ ...empresa, numero: e.target.value })}
          />
        </div>

        <div className="p-col-8 p-sm-5 p-lg-5 p-fluid">
          <LabelSp>Bairro</LabelSp>
          <InputTextSp
            value={empresa.bairro}
            disabled={modoConsulta()}
            maxLength={100}
            onChange={e => setEmpresa({ ...empresa, bairro: e.target.value })}
          />
        </div>

        <div className="p-col-8 p-sm-5 p-lg-5 p-fluid">
          <LabelSp>Complemento</LabelSp>
          <InputTextSp
            value={empresa.complemento}
            maxLength={100}
            disabled={modoConsulta()}
            onChange={e =>
              setEmpresa({
                ...empresa,
                complemento: e.target.value,
              })
            }
          />
        </div>

        <div className="p-col-4 p-sm-2 p-lg-2 p-fluid">
          <LabelSp>CEP</LabelSp>
          <InputMaskSp
            id="txtCep"
            mask="99.999-999"
            value={empresa.cep}
            disabled={modoConsulta()}
            onChange={e => {
              setEmpresa({ ...empresa, cep: e.target.value });
            }}
          />
        </div>
        <div className="p-col-12 p-sm-10 p-lg-10 p-fluid">
          <div className="p-grid">
            <div className="p-col-4 p-sm-2 p-lg-2 p-fluid">
              <LabelSp>UF</LabelSp>
              <DropdownSp
                value={empresa.uf}
                options={listaUfs}
                filter
                disabled={modoConsulta()}
                filterInputAutoFocus={false}
                onChange={e => {
                  setMunicipioSelecionado(null);
                  setEmpresa({ ...empresa, codigoIbge: null, uf: e.target.value });
                }}
              />
            </div>

            <div className="p-col-8 p-sm-4 p-lg-4 p-fluid">
              <LabelSp>Município</LabelSp>
              <DropdownLazy
                required
                showClear
                disabled={modoConsulta()}
                placeholder="Selecione"
                onChange={e => {
                  setEmpresa({
                    ...empresa,
                    codigoIbge: e?.value,
                    nomeMunicipio: e?.label,
                  });
                  setMunicipioSelecionado(e);
                }}
                value={municipioSelecionado}
                onFilter={async txtFilter => {
                  const retorno = await carregaMunicipio(txtFilter, empresa.uf);
                  return retorno;
                }}
              />
            </div>

            <div className="p-col-7 p-sm-4 p-lg-4 p-fluid">
              <LabelSp>email</LabelSp>
              <InputTextSp
                value={empresa.email}
                maxLength={255}
                disabled={modoConsulta()}
                onChange={e =>
                  setEmpresa({
                    ...empresa,
                    email: e.target.value,
                  })
                }
              />
            </div>
            <div className="p-col-5 p-sm-2 p-lg-2 p-fluid">
              <LabelSp>Fone</LabelSp>
              <InputMaskSp
                id="txtTelefone"
                mask="(99) 9999-9999"
                value={empresa.telefone}
                disabled={modoConsulta()}
                onChange={e => {
                  setEmpresa({ ...empresa, telefone: e.target.value });
                }}
              />
            </div>
            <div className="p-col-5 p-sm-3 p-lg-3 p-fluid">
              <LabelSp>Celular</LabelSp>
              <InputMaskSp
                id="txtCelular"
                mask="(99) 99999-9999"
                value={empresa.celular}
                disabled={modoConsulta()}
                onChange={e => {
                  setEmpresa({ ...empresa, celular: e.target.value });
                }}
              />
            </div>
            <div className="p-col-7 p-sm-3 p-lg-3 p-fluid">
              <LabelSp>Id HTML</LabelSp>
              <InputTextSp
                required
                value={empresa.identificadorHtml}
                maxLength={60}
                disabled={modoConsulta() || !AuthService.checkRoles('EMPRESA_INSERIR')}
                onChange={e => {
                  const value = (e.target.value || '').toLowerCase();
                  if (!value.match(/[^a-z A-Z]/)) {
                    setEmpresa({
                      ...empresa,
                      identificadorHtml: value.trim(),
                    });
                  }
                }}
              />
            </div>
            <div className="p-col-6 p-sm-3 p-lg-3 p-fluid">
              <LabelSp>Contrato</LabelSp>
              <InputTextSp
                required
                name="numeroContrato"
                maxLength={6}
                disabled={modoConsulta() || pageParams.statusTela === StatusTela.stAlterar}
                value={empresa.numeroContrato}
                onChange={e => {
                  if (isNumber(e.target.value)) {
                    setEmpresa({ ...empresa, numeroContrato: e.target.value });
                  }
                }}
              />
            </div>
            <div className="p-col-6 p-sm-3 p-lg-3 p-fluid">
              <LabelSp>Abre Hoje</LabelSp>
              <DropdownSp
                value={empresa.estaFuncionando}
                options={[
                  { value: true, label: 'Sim' },
                  { value: false, label: 'Não' },
                ]}
                disabled={modoConsulta()}
                filterInputAutoFocus={false}
                onChange={e => {
                  setEmpresa({ ...empresa, estaFuncionando: e.target.value });
                }}
              />
            </div>
          </div>
        </div>
        <div className="p-col-12 p-sm-2 p-lg-2 p-fluid">
          <LabelSp>Logo</LabelSp>
          <div
            className={classDivLogo}
            onClick={() => {
              if (!modoConsulta()) {
                setDialogVisible(true);
              }
            }}
          >
            <img className="logo" src={fileSelecionado || logoCliente} alt="logo" />
          </div>
        </div>
        <div className="p-col-6 p-sm-4 p-lg-4 p-fluid">
          <LabelSp>Localização</LabelSp>
          <InputTextSp
            value={localizacao}
            maxLength={60}
            required={!modoConsulta()}
            style={{ cursor: 'pointer' }}
            // disabled={modoConsulta()}
            readOnly
            onClick={() => {
              carregaDadosMapa();
            }}
          />
        </div>
        <div className="p-col-6 p-sm-4 p-lg-3 p-fluid">
          <LabelSp>Prv. Entrega(Minutos)</LabelSp>
          <InputTextSp
            name="previsaoEntrega"
            maxLength={3}
            disabled={modoConsulta()}
            value={empresa.tempoMinutosEntrega}
            onChange={e => {
              if (isNumber(e.target.value)) {
                setEmpresa({ ...empresa, tempoMinutosEntrega: e.target.value });
              }
            }}
          />
        </div>

        <div className="p-col-12 p-sm-4 p-lg-3 p-fluid">
          <LabelSp>Permite Ped. On-line</LabelSp>
          <DropdownSp
            value={empresa.permitePedidoOnline}
            options={[
              { value: true, label: 'Sim' },
              { value: false, label: 'Não' },
            ]}
            disabled={modoConsulta()}
            filterInputAutoFocus={false}
            onChange={e => {
              setEmpresa({ ...empresa, permitePedidoOnline: e.target.value });
            }}
          />
        </div>

        <DivTema
          className="p-col-12 p-sm-4 p-lg-2 p-fluid"
          temaColor={colors[empresa.idTema]?.label}
        >
          <LabelSp>Tema</LabelSp>
          <DropdownSp
            value={empresa.idTema}
            options={colors}
            style={{ backgroundColor: colors[empresa.idTema]?.label }}
            itemTemplate={op => {
              return (
                <div className="p-clearfix">
                  <div style={{ height: 20, backgroundColor: op.label }} />
                </div>
              );
            }}
            disabled={modoConsulta()}
            filterInputAutoFocus={false}
            onChange={e => {
              setEmpresa({ ...empresa, idTema: e.target.value });
            }}
          />
        </DivTema>

        <div className="p-col-12 p-sm-8 p-lg-6 p-fluid">
          <LabelSp>Tipo de Serviço</LabelSp>
          <MultiSelectSp
            value={tiposServico}
            options={ListaTiposServicos}
            maxSelectedLabels={4}
            onChange={e => {
              const retorno = Array.isArray(e.value) ? e.value.sort() : [];
              setTiposServico(retorno);
            }}
          />
        </div>

        <div className="p-col-6 p-sm-6 p-lg-3 p-fluid">
          <LabelSp>Opções dos Grupos</LabelSp>
          <DropdownSp
            value={empresa.tipoGrupoExpandido}
            options={[
              { value: 0, label: 'Todos expandidos' },
              {
                value: 1,
                label: 'Apenas o primeiro expandido',
              },
              {
                value: 2,
                label: 'Nenhum expandido',
              },
            ]}
            disabled={modoConsulta()}
            filterInputAutoFocus={false}
            onChange={e => {
              setEmpresa({ ...empresa, tipoGrupoExpandido: e.target.value });
            }}
          />
        </div>

        <div className="p-col-6 p-sm-6 p-lg-3 p-fluid">
          <LabelSp>Cons. local apenas na mesa?</LabelSp>
          <DropdownSp
            value={empresa.consumoLocalApenasNaMesa}
            options={[
              { value: true, label: 'Sim' },
              {
                value: false,
                label: 'Não',
              },
            ]}
            disabled={modoConsulta()}
            filterInputAutoFocus={false}
            onChange={e => {
              setEmpresa({ ...empresa, consumoLocalApenasNaMesa: e.target.value });
            }}
          />
        </div>

        <div className="p-col-12 p-sm-12 p-lg-12 p-fluid">
          <LabelSp>Banner (1660 x 310px)</LabelSp>
          <ContainerImage
            className={!modoConsulta() ? 'clicavel' : ''}
            onClick={() => {
              if (!modoConsulta()) {
                setDialogBannerVisible(true);
              }
            }}
          >
            <img src={fileBannerSelecionado || fileUriBanner} alt="banner" />
          </ContainerImage>
        </div>

        <div className="p-col-12 p-fluid desktop-visible">
          <hr />
          <LabelSp>Horários de funcionamento</LabelSp>
        </div>
        <div
          className="p-grid p-col-12 p-sm-12 p-lg-12 p-fluid desktop-visible"
          style={{ margin: 0, padding: 0 }}
        >
          {renderHorarios()}
        </div>

        <div className="p-col-12 p-fluid desktop-visible">
          <hr />
          <LabelSp>Taxas de entrega conforme distância(Raio) em KM</LabelSp>
        </div>
        <div
          className="p-grid p-col-12 p-sm-12 p-lg-12 p-fluid desktop-visible"
          style={{ margin: 0, padding: 0 }}
        >
          {renderTaxas()}
        </div>

        <div className="p-col-12 p-lg-12" style={{ textAlign: 'end' }}>
          {!modoConsulta() ? (
            <ButtonSp
              className="p-button-success"
              icon="pi pi-save"
              label="Salvar"
              disabled={
                !validateFields(empresa, [
                  'razaoSocial',
                  'fantasia',
                  'codigoIbge',
                  'cpfCnpj',
                  'identificadorHtml',
                  'numeroContrato',
                ])
              }
              showConfirmation
              onClick={handleSalvar}
            />
          ) : null}
          <ButtonSp
            className="p-button-secondary"
            label="Voltar"
            icon="pi pi-chevron-circle-left"
            onClick={handleVoltar}
          />
        </div>
      </>
    );
  }

  function renderUploadLogo() {
    function renderFooter() {
      return (
        <div>
          <ButtonSp
            label="Confirmar"
            icon="pi pi-check"
            disabled={!validateFields(fileLogo, ['fileName'])}
            onClick={() => {
              setDialogVisible(false);
              const file = cloneObj(srcImg);
              setFileSelecionado(file);
              setSrcImg(null);
            }}
          />
          <ButtonSp
            label="Cancelar"
            icon="pi pi-times"
            onClick={() => {
              setDialogVisible(false);
            }}
            className="p-button-secondary"
          />
        </div>
      );
    }

    return (
      <Dialog
        closable={false}
        header="Selecione uma Imagem"
        footer={renderFooter()}
        visible={dialogVisible}
        style={{
          minWidth: '350px',
          maxWidth: '400px',
          width: '95%',
          // maxHeight: '90vh',
        }}
        contentStyle={{ maxHeight: '70vh', overflowY: 'auto', overflowX: 'hidden' }}
        modal
        onHide={() => {
          setDialogVisible(false);
        }}
      >
        <div className="p-grid">
          <div className="p-col-12 p-sm-12 p-lg-12 p-fluid">
            <span>A imagem deve ter formato de um quadrado e tamanho máximo de 1M.</span>
          </div>
          <div className="p-col-12 p-sm-12 p-lg-12 p-fluid">
            <InputTextSp
              type="file"
              accept="image/png, image/jpeg"
              value={fileLogo.fileName}
              onChange={e => {
                const { target } = e;
                if (target.files && target.files[0]) {
                  const reader = new FileReader();
                  reader.onload = () => {
                    setSrcImg(reader.result);
                  };
                  const file = target.files && target.files[0] ? target.files[0] : null;
                  setFileLogo({
                    ...fileLogo,
                    fileName: target.value,
                    file,
                  });
                  reader.readAsDataURL(target.files[0]);
                }
              }}
            />
          </div>
          {srcImg ? (
            <div className="p-col-12 p-sm-12 p-lg-12 p-fluid">
              <LabelSp>Imagem Selecionada</LabelSp>
              <div
                className="div-logo"
                onClick={() => {
                  if (!modoConsulta()) {
                    setDialogVisible(true);
                  }
                }}
              >
                <img
                  className="logo"
                  src={srcImg}
                  alt="logo"
                  style={{ width: 100, height: 100 }}
                />
              </div>
            </div>
          ) : null}
        </div>
      </Dialog>
    );
  }

  function renderUploadBanner() {
    function renderFooter() {
      return (
        <div>
          <ButtonSp
            label="Confirmar"
            icon="pi pi-check"
            disabled={!validateFields(fileBanner, ['fileName'])}
            onClick={() => {
              setDialogBannerVisible(false);
              const file = cloneObj(srcImgBaner);
              setFileBannerSelecionado(file);
              setSrcImgBanner(null);
            }}
          />
          <ButtonSp
            label="Cancelar"
            icon="pi pi-times"
            onClick={() => {
              setDialogBannerVisible(false);
            }}
            className="p-button-secondary"
          />
        </div>
      );
    }

    return (
      <Dialog
        closable={false}
        header="Selecione uma Imagem"
        footer={renderFooter()}
        visible={dialogBannerVisible}
        style={{
          minWidth: '350px',
          maxWidth: '800px',
          width: '95%',
          // maxHeight: '90vh',
        }}
        contentStyle={{ maxHeight: '70vh', overflowY: 'auto', overflowX: 'hidden' }}
        modal
        onHide={() => {
          setDialogBannerVisible(false);
        }}
      >
        <div className="p-grid">
          <div className="p-col-12 p-sm-12 p-lg-12 p-fluid">
            <span>A imagem deve ter (1660 x 310) ou proporcional e tamanho máximo de 3M.</span>
          </div>
          <div className="p-col-12 p-sm-12 p-lg-12 p-fluid">
            <InputTextSp
              type="file"
              accept="image/png, image/jpeg"
              value={fileBanner.fileName}
              onChange={e => {
                const { target } = e;
                if (target.files && target.files[0]) {
                  const reader = new FileReader();
                  reader.onload = () => {
                    setSrcImgBanner(reader.result);
                  };
                  const file = target.files && target.files[0] ? target.files[0] : null;
                  setFileBanner({
                    ...fileBanner,
                    fileName: target.value,
                    file,
                  });
                  reader.readAsDataURL(target.files[0]);
                }
              }}
            />
          </div>
          {srcImgBaner ? (
            <div className="p-col-12 p-sm-12 p-lg-12 p-fluid">
              <LabelSp>Imagem Selecionada</LabelSp>
              <ContainerImage
                onClick={() => {
                  if (!modoConsulta()) {
                    setDialogBannerVisible(true);
                  }
                }}
              >
                <img src={srcImgBaner} alt="logo" />
              </ContainerImage>
            </div>
          ) : null}
        </div>
      </Dialog>
    );
  }

  function renderHorarios() {
    return (
      <>
        <div className="p-col-4 p-sm-2 p-lg-2 p-fluid">
          <LabelSp>Dia</LabelSp>
        </div>
        <div className="p-col-4 p-sm-1 p-lg-1 p-fluid">
          <LabelSp>Abre</LabelSp>
        </div>
        <div className="p-col-4 p-sm-1 p-lg-1 p-fluid">
          <LabelSp>Fecha</LabelSp>
        </div>
        <div className="p-col-4 p-sm-1 p-lg-1 p-fluid">
          <LabelSp>Abre</LabelSp>
        </div>
        <div className="p-col-4 p-sm-1 p-lg-1 p-fluid">
          <LabelSp>Fecha</LabelSp>
        </div>
        <div className="p-col-4 p-sm-2 p-lg-2 p-fluid">
          <LabelSp>Dia</LabelSp>
        </div>
        <div className="p-col-4 p-sm-1 p-lg-1 p-fluid">
          <LabelSp>Abre</LabelSp>
        </div>
        <div className="p-col-4 p-sm-1 p-lg-1 p-fluid">
          <LabelSp>Fecha</LabelSp>
        </div>
        <div className="p-col-4 p-sm-1 p-lg-1 p-fluid">
          <LabelSp>Abre</LabelSp>
        </div>
        <div className="p-col-4 p-sm-1 p-lg-1 p-fluid">
          <LabelSp>Fecha</LabelSp>
        </div>

        {/* campos */}
        {/* domingo */}
        <div className="p-col-4 p-sm-2 p-lg-2 p-fluid">
          <InputTextSp value={horarios.domingo} maxLength={30} disabled />
        </div>
        <div className="p-col-6 p-sm-1 p-lg-1 p-fluid">
          <InputMaskSp
            mask="99:99"
            disabled={modoConsulta()}
            value={horarios.domingoAbre0}
            onChange={e => {
              horarios.domingoAbre0 = e.target.value;
              setHorarios(cloneObj(horarios));
            }}
          />
        </div>
        <div className="p-col-6 p-sm-1 p-lg-1 p-fluid">
          <InputMaskSp
            mask="99:99"
            disabled={modoConsulta()}
            value={horarios.domingoFecha0}
            onChange={e => {
              horarios.domingoFecha0 = e.target.value;
              setHorarios(cloneObj(horarios));
            }}
          />
        </div>
        <div className="p-col-6 p-sm-1 p-lg-1 p-fluid">
          <InputMaskSp
            mask="99:99"
            disabled={modoConsulta()}
            value={horarios.domingoAbre1}
            onChange={e => {
              horarios.domingoAbre1 = e.target.value;
              setHorarios(cloneObj(horarios));
            }}
          />
        </div>
        <div className="p-col-6 p-sm-1 p-lg-1 p-fluid">
          <InputMaskSp
            mask="99:99"
            disabled={modoConsulta()}
            value={horarios.domingoFecha1}
            onChange={e => {
              horarios.domingoFecha1 = e.target.value;
              setHorarios(cloneObj(horarios));
            }}
          />
        </div>
        {/* segunda */}
        <div className="p-col-4 p-sm-2 p-lg-2 p-fluid">
          <InputTextSp value={horarios.segunda} maxLength={30} disabled />
        </div>
        <div className="p-col-6 p-sm-1 p-lg-1 p-fluid">
          <InputMaskSp
            mask="99:99"
            disabled={modoConsulta()}
            value={horarios.segundaAbre0}
            onChange={e => {
              horarios.segundaAbre0 = e.target.value;
              setHorarios(cloneObj(horarios));
            }}
          />
        </div>
        <div className="p-col-6 p-sm-1 p-lg-1 p-fluid">
          <InputMaskSp
            mask="99:99"
            disabled={modoConsulta()}
            value={horarios.segundaFecha0}
            onChange={e => {
              horarios.segundaFecha0 = e.target.value;
              setHorarios(cloneObj(horarios));
            }}
          />
        </div>
        <div className="p-col-6 p-sm-1 p-lg-1 p-fluid">
          <InputMaskSp
            mask="99:99"
            disabled={modoConsulta()}
            value={horarios.segundaAbre1}
            onChange={e => {
              horarios.segundaAbre1 = e.target.value;
              setHorarios(cloneObj(horarios));
            }}
          />
        </div>
        <div className="p-col-6 p-sm-1 p-lg-1 p-fluid">
          <InputMaskSp
            mask="99:99"
            disabled={modoConsulta()}
            value={horarios.segundaFecha1}
            onChange={e => {
              horarios.segundaFecha1 = e.target.value;
              setHorarios(cloneObj(horarios));
            }}
          />
        </div>

        {/* terca */}
        <div className="p-col-4 p-sm-2 p-lg-2 p-fluid">
          <InputTextSp value={horarios.terca} maxLength={30} disabled />
        </div>
        <div className="p-col-6 p-sm-1 p-lg-1 p-fluid">
          <InputMaskSp
            mask="99:99"
            disabled={modoConsulta()}
            value={horarios.tercaAbre0}
            onChange={e => {
              horarios.tercaAbre0 = e.target.value;
              setHorarios(cloneObj(horarios));
            }}
          />
        </div>
        <div className="p-col-6 p-sm-1 p-lg-1 p-fluid">
          <InputMaskSp
            mask="99:99"
            disabled={modoConsulta()}
            value={horarios.tercaFecha0}
            onChange={e => {
              horarios.tercaFecha0 = e.target.value;
              setHorarios(cloneObj(horarios));
            }}
          />
        </div>
        <div className="p-col-6 p-sm-1 p-lg-1 p-fluid">
          <InputMaskSp
            mask="99:99"
            disabled={modoConsulta()}
            value={horarios.tercaAbre1}
            onChange={e => {
              horarios.tercaAbre1 = e.target.value;
              setHorarios(cloneObj(horarios));
            }}
          />
        </div>
        <div className="p-col-6 p-sm-1 p-lg-1 p-fluid">
          <InputMaskSp
            mask="99:99"
            disabled={modoConsulta()}
            value={horarios.tercaFecha1}
            onChange={e => {
              horarios.tercaFecha1 = e.target.value;
              setHorarios(cloneObj(horarios));
            }}
          />
        </div>

        {/* quarta */}
        <div className="p-col-4 p-sm-2 p-lg-2 p-fluid">
          <InputTextSp value={horarios.quarta} maxLength={30} disabled />
        </div>
        <div className="p-col-6 p-sm-1 p-lg-1 p-fluid">
          <InputMaskSp
            mask="99:99"
            disabled={modoConsulta()}
            value={horarios.quartaAbre0}
            onChange={e => {
              horarios.quartaAbre0 = e.target.value;
              setHorarios(cloneObj(horarios));
            }}
          />
        </div>
        <div className="p-col-6 p-sm-1 p-lg-1 p-fluid">
          <InputMaskSp
            mask="99:99"
            disabled={modoConsulta()}
            value={horarios.quartaFecha0}
            onChange={e => {
              horarios.quartaFecha0 = e.target.value;
              setHorarios(cloneObj(horarios));
            }}
          />
        </div>
        <div className="p-col-6 p-sm-1 p-lg-1 p-fluid">
          <InputMaskSp
            mask="99:99"
            disabled={modoConsulta()}
            value={horarios.quartaAbre1}
            onChange={e => {
              horarios.quartaAbre1 = e.target.value;
              setHorarios(cloneObj(horarios));
            }}
          />
        </div>
        <div className="p-col-6 p-sm-1 p-lg-1 p-fluid">
          <InputMaskSp
            mask="99:99"
            disabled={modoConsulta()}
            value={horarios.quartaFecha1}
            onChange={e => {
              horarios.quartaFecha1 = e.target.value;
              setHorarios(cloneObj(horarios));
            }}
          />
        </div>

        {/* quinta */}
        <div className="p-col-4 p-sm-2 p-lg-2 p-fluid">
          <InputTextSp value={horarios.quinta} maxLength={30} disabled />
        </div>
        <div className="p-col-6 p-sm-1 p-lg-1 p-fluid">
          <InputMaskSp
            mask="99:99"
            disabled={modoConsulta()}
            value={horarios.quintaAbre0}
            onChange={e => {
              horarios.quintaAbre0 = e.target.value;
              setHorarios(cloneObj(horarios));
            }}
          />
        </div>
        <div className="p-col-6 p-sm-1 p-lg-1 p-fluid">
          <InputMaskSp
            mask="99:99"
            disabled={modoConsulta()}
            value={horarios.quintaFecha0}
            onChange={e => {
              horarios.quintaFecha0 = e.target.value;
              setHorarios(cloneObj(horarios));
            }}
          />
        </div>
        <div className="p-col-6 p-sm-1 p-lg-1 p-fluid">
          <InputMaskSp
            mask="99:99"
            disabled={modoConsulta()}
            value={horarios.quintaAbre1}
            onChange={e => {
              horarios.quintaAbre1 = e.target.value;
              setHorarios(cloneObj(horarios));
            }}
          />
        </div>
        <div className="p-col-6 p-sm-1 p-lg-1 p-fluid">
          <InputMaskSp
            mask="99:99"
            disabled={modoConsulta()}
            value={horarios.quintaFecha1}
            onChange={e => {
              horarios.quintaFecha1 = e.target.value;
              setHorarios(cloneObj(horarios));
            }}
          />
        </div>

        {/* sexta */}
        <div className="p-col-4 p-sm-2 p-lg-2 p-fluid">
          <InputTextSp value={horarios.sexta} maxLength={30} disabled />
        </div>
        <div className="p-col-6 p-sm-1 p-lg-1 p-fluid">
          <InputMaskSp
            mask="99:99"
            disabled={modoConsulta()}
            value={horarios.sextaAbre0}
            onChange={e => {
              horarios.sextaAbre0 = e.target.value;
              setHorarios(cloneObj(horarios));
            }}
          />
        </div>
        <div className="p-col-6 p-sm-1 p-lg-1 p-fluid">
          <InputMaskSp
            mask="99:99"
            disabled={modoConsulta()}
            value={horarios.sextaFecha0}
            onChange={e => {
              horarios.sextaFecha0 = e.target.value;
              setHorarios(cloneObj(horarios));
            }}
          />
        </div>
        <div className="p-col-6 p-sm-1 p-lg-1 p-fluid">
          <InputMaskSp
            mask="99:99"
            disabled={modoConsulta()}
            value={horarios.sextaAbre1}
            onChange={e => {
              horarios.sextaAbre1 = e.target.value;
              setHorarios(cloneObj(horarios));
            }}
          />
        </div>
        <div className="p-col-6 p-sm-1 p-lg-1 p-fluid">
          <InputMaskSp
            mask="99:99"
            disabled={modoConsulta()}
            value={horarios.sextaFecha1}
            onChange={e => {
              horarios.sextaFecha1 = e.target.value;
              setHorarios(cloneObj(horarios));
            }}
          />
        </div>

        {/* sabado */}
        <div className="p-col-4 p-sm-2 p-lg-2 p-fluid">
          <InputTextSp value={horarios.sabado} maxLength={30} disabled />
        </div>
        <div className="p-col-6 p-sm-1 p-lg-1 p-fluid">
          <InputMaskSp
            mask="99:99"
            disabled={modoConsulta()}
            value={horarios.sabadoAbre0}
            onChange={e => {
              horarios.sabadoAbre0 = e.target.value;
              setHorarios(cloneObj(horarios));
            }}
          />
        </div>
        <div className="p-col-6 p-sm-1 p-lg-1 p-fluid">
          <InputMaskSp
            mask="99:99"
            disabled={modoConsulta()}
            value={horarios.sabadoFecha0}
            onChange={e => {
              horarios.sabadoFecha0 = e.target.value;
              setHorarios(cloneObj(horarios));
            }}
          />
        </div>
        <div className="p-col-6 p-sm-1 p-lg-1 p-fluid">
          <InputMaskSp
            mask="99:99"
            disabled={modoConsulta()}
            value={horarios.sabadoAbre1}
            onChange={e => {
              horarios.sabadoAbre1 = e.target.value;
              setHorarios(cloneObj(horarios));
            }}
          />
        </div>
        <div className="p-col-6 p-sm-1 p-lg-1 p-fluid">
          <InputMaskSp
            mask="99:99"
            disabled={modoConsulta()}
            value={horarios.sabadoFecha1}
            onChange={e => {
              horarios.sabadoFecha1 = e.target.value;
              setHorarios(cloneObj(horarios));
            }}
          />
        </div>
      </>
    );
  }

  function renderTaxas() {
    return (
      <>
        <div className="p-col-4 p-sm-2 p-lg-2 p-fluid">
          <LabelSp>Acima de(KM)</LabelSp>
        </div>
        <div className="p-col-4 p-sm-2 p-lg-2 p-fluid">
          <LabelSp>Vr. Taxa</LabelSp>
        </div>

        <div className="p-col-4 p-sm-2 p-lg-2 p-fluid">
          <LabelSp>Acima de(KM)</LabelSp>
        </div>
        <div className="p-col-4 p-sm-2 p-lg-2 p-fluid">
          <LabelSp>Vr. Taxa</LabelSp>
        </div>

        <div className="p-col-4 p-sm-2 p-lg-2 p-fluid">
          <LabelSp>Acima de(KM)</LabelSp>
        </div>
        <div className="p-col-4 p-sm-2 p-lg-2 p-fluid">
          <LabelSp>Vr. Taxa</LabelSp>
        </div>

        {/* campos */}
        {/* Taxa0 */}
        <div className="p-col-4 p-sm-2 p-lg-2 p-fluid">
          <InputCurrencySp
            digits={0}
            value={taxas.acimaDeKm0}
            maxLength={3}
            disabled={modoConsulta()}
            onChange={(e, v) => {
              taxas.acimaDeKm0 = v;
              setTaxas(cloneObj(taxas));
            }}
          />
        </div>
        <div className="p-col-6 p-sm-2 p-lg-2 p-fluid">
          <InputCurrencySp
            onlyPositive
            value={taxas.valorTaxa0}
            disabled={modoConsulta()}
            onChange={(e, v) => {
              taxas.valorTaxa0 = v;
              setTaxas(cloneObj(taxas));
            }}
          />
        </div>

        {/* Taxa1 */}
        <div className="p-col-4 p-sm-2 p-lg-2 p-fluid">
          <InputCurrencySp
            digits={0}
            value={taxas.acimaDeKm1}
            maxLength={3}
            disabled={modoConsulta()}
            onChange={(e, v) => {
              taxas.acimaDeKm1 = v;
              setTaxas(cloneObj(taxas));
            }}
          />
        </div>
        <div className="p-col-6 p-sm-2 p-lg-2 p-fluid">
          <InputCurrencySp
            onlyPositive
            value={taxas.valorTaxa1}
            disabled={modoConsulta()}
            onChange={(e, v) => {
              taxas.valorTaxa1 = v;
              setTaxas(cloneObj(taxas));
            }}
          />
        </div>

        {/* Taxa2 */}
        <div className="p-col-4 p-sm-2 p-lg-2 p-fluid">
          <InputCurrencySp
            digits={0}
            value={taxas.acimaDeKm2}
            maxLength={3}
            disabled={modoConsulta()}
            onChange={(e, v) => {
              taxas.acimaDeKm2 = v;
              setTaxas(cloneObj(taxas));
            }}
          />
        </div>

        <div className="p-col-6 p-sm-2 p-lg-2 p-fluid">
          <InputCurrencySp
            onlyPositive
            value={taxas.valorTaxa2}
            disabled={modoConsulta()}
            onChange={(e, v) => {
              taxas.valorTaxa2 = v;
              setTaxas(cloneObj(taxas));
            }}
          />
        </div>

        {/* Taxa3 */}
        <div className="p-col-4 p-sm-2 p-lg-2 p-fluid">
          <InputCurrencySp
            digits={0}
            value={taxas.acimaDeKm3}
            maxLength={3}
            disabled={modoConsulta()}
            onChange={(e, v) => {
              taxas.acimaDeKm3 = v;
              setTaxas(cloneObj(taxas));
            }}
          />
        </div>
        <div className="p-col-6 p-sm-2 p-lg-2 p-fluid">
          <InputCurrencySp
            onlyPositive
            value={taxas.valorTaxa3}
            disabled={modoConsulta()}
            onChange={(e, v) => {
              taxas.valorTaxa3 = v;
              setTaxas(cloneObj(taxas));
            }}
          />
        </div>

        {/* Taxa4 */}
        <div className="p-col-4 p-sm-2 p-lg-2 p-fluid">
          <InputCurrencySp
            digits={0}
            value={taxas.acimaDeKm4}
            maxLength={3}
            disabled={modoConsulta()}
            onChange={(e, v) => {
              taxas.acimaDeKm4 = v;
              setTaxas(cloneObj(taxas));
            }}
          />
        </div>
        <div className="p-col-6 p-sm-2 p-lg-2 p-fluid">
          <InputCurrencySp
            onlyPositive
            value={taxas.valorTaxa4}
            disabled={modoConsulta()}
            onChange={(e, v) => {
              taxas.valorTaxa4 = v;
              setTaxas(cloneObj(taxas));
            }}
          />
        </div>

        {/* Taxa5 */}
        <div className="p-col-4 p-sm-2 p-lg-2 p-fluid">
          <InputCurrencySp
            digits={0}
            value={taxas.acimaDeKm5}
            maxLength={3}
            disabled={modoConsulta()}
            onChange={(e, v) => {
              taxas.acimaDeKm5 = v;
              setTaxas(cloneObj(taxas));
            }}
          />
        </div>

        <div className="p-col-6 p-sm-2 p-lg-2 p-fluid">
          <InputCurrencySp
            onlyPositive
            value={taxas.valorTaxa5}
            disabled={modoConsulta()}
            onChange={(e, v) => {
              taxas.valorTaxa5 = v;
              setTaxas(cloneObj(taxas));
            }}
          />
        </div>

        {/* Taxa6 */}
        <div className="p-col-4 p-sm-2 p-lg-2 p-fluid">
          <InputCurrencySp
            digits={0}
            value={taxas.acimaDeKm6}
            maxLength={3}
            disabled={modoConsulta()}
            onChange={(e, v) => {
              taxas.acimaDeKm6 = v;
              setTaxas(cloneObj(taxas));
            }}
          />
        </div>
        <div className="p-col-6 p-sm-2 p-lg-2 p-fluid">
          <InputCurrencySp
            onlyPositive
            value={taxas.valorTaxa6}
            disabled={modoConsulta()}
            onChange={(e, v) => {
              taxas.valorTaxa6 = v;
              setTaxas(cloneObj(taxas));
            }}
          />
        </div>

        {/* Taxa7 */}
        <div className="p-col-4 p-sm-2 p-lg-2 p-fluid">
          <InputCurrencySp
            digits={0}
            value={taxas.acimaDeKm7}
            maxLength={3}
            disabled={modoConsulta()}
            onChange={(e, v) => {
              taxas.acimaDeKm7 = v;
              setTaxas(cloneObj(taxas));
            }}
          />
        </div>
        <div className="p-col-6 p-sm-2 p-lg-2 p-fluid">
          <InputCurrencySp
            onlyPositive
            value={taxas.valorTaxa7}
            disabled={modoConsulta()}
            onChange={(e, v) => {
              taxas.valorTaxa7 = v;
              setTaxas(cloneObj(taxas));
            }}
          />
        </div>

        {/* Taxa8 */}
        <div className="p-col-4 p-sm-2 p-lg-2 p-fluid">
          <InputCurrencySp
            digits={0}
            value={taxas.acimaDeKm8}
            maxLength={3}
            disabled={modoConsulta()}
            onChange={(e, v) => {
              taxas.acimaDeKm8 = v;
              setTaxas(cloneObj(taxas));
            }}
          />
        </div>

        <div className="p-col-6 p-sm-2 p-lg-2 p-fluid">
          <InputCurrencySp
            onlyPositive
            value={taxas.valorTaxa8}
            disabled={modoConsulta()}
            onChange={(e, v) => {
              taxas.valorTaxa8 = v;
              setTaxas(cloneObj(taxas));
            }}
          />
        </div>

        {/* Taxa9 */}
        <div className="p-col-4 p-sm-2 p-lg-2 p-fluid">
          <InputCurrencySp
            digits={0}
            value={taxas.acimaDeKm9}
            maxLength={3}
            disabled={modoConsulta()}
            onChange={(e, v) => {
              taxas.acimaDeKm9 = v;
              setTaxas(cloneObj(taxas));
            }}
          />
        </div>

        <div className="p-col-6 p-sm-2 p-lg-2 p-fluid">
          <InputCurrencySp
            onlyPositive
            value={taxas.valorTaxa9}
            disabled={modoConsulta()}
            onChange={(e, v) => {
              taxas.valorTaxa9 = v;
              setTaxas(cloneObj(taxas));
            }}
          />
        </div>

        {/* Taxa10 */}
        <div className="p-col-4 p-sm-2 p-lg-2 p-fluid">
          <InputCurrencySp
            digits={0}
            value={taxas.acimaDeKm10}
            maxLength={3}
            disabled={modoConsulta()}
            onChange={(e, v) => {
              taxas.acimaDeKm10 = v;
              setTaxas(cloneObj(taxas));
            }}
          />
        </div>
        <div className="p-col-6 p-sm-2 p-lg-2 p-fluid">
          <InputCurrencySp
            onlyPositive
            value={taxas.valorTaxa10}
            disabled={modoConsulta()}
            onChange={(e, v) => {
              taxas.valorTaxa10 = v;
              setTaxas(cloneObj(taxas));
            }}
          />
        </div>

        {/* Taxa11 */}
        <div className="p-col-4 p-sm-2 p-lg-2 p-fluid">
          <InputCurrencySp
            digits={0}
            value={taxas.acimaDeKm11}
            maxLength={3}
            disabled={modoConsulta()}
            onChange={(e, v) => {
              taxas.acimaDeKm11 = v;
              setTaxas(cloneObj(taxas));
            }}
          />
        </div>
        <div className="p-col-6 p-sm-2 p-lg-2 p-fluid">
          <InputCurrencySp
            onlyPositive
            value={taxas.valorTaxa11}
            disabled={modoConsulta()}
            onChange={(e, v) => {
              taxas.valorTaxa11 = v;
              setTaxas(cloneObj(taxas));
            }}
          />
        </div>
      </>
    );
  }
}
